/*eslint-disable*/
import React from "react";


const vhostV1=function(s){
  const isDevelop = process.env.REACT_APP_ISDEVELOP;
   if (isDevelop==='true') {
      return `http://${process.env.REACT_APP_SERVERHOST}/api/v1/${s}`
    } else {
      return `https://${process.env.REACT_APP_SERVERHOST}/api/v1/${s}`
    }    
}

const vhostV2=function(s){
    const isDevelop = process.env.REACT_APP_ISDEVELOP;
   
      if (isDevelop==='true') {
        return `http://${process.env.REACT_APP_SERVERHOST}/api/v2/${s}`
      } else {
        return `https://${process.env.REACT_APP_SERVERHOST}/api/v2/${s}`
      }    
  }



  

export {vhostV1, vhostV2};