import React, { useState, useEffect } from 'react';
import { Link,Navigate } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import './produto.css'
import { api } from '../../services/api';
import ProdutoPdf from '../reports/produtos/listaprodutos';
import { vhostV1 } from '../funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import BuscaVazaia from '../components/buscavazia/buscaVazia';
import { CSVLink } from 'react-csv';
import Loading from '../components/loading/Loading';
import Footer from '../components/footer/Footer';
import etiqueta from './etiqueta';
import SRI_pagination from '../components/pagination/pagination';
import ProdutoTable from './produtoTable';
import EtiquetaPDF from './EtiquetaPDF';
import EtiquetaTriPDF from './EtiquetaTri';
import customizeScrollbar from "customsb";





function Produto() {


     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [produtos,setProdutos] = useState([]);
     const [sResult,setSresult] = useState(0);
     const [confirmacao,setConfirmacao] = useState(false);
     const [confirmacaoId,setConfirmacaoID] = useState('');

     const [removeLoading,setRemoveLoading] = useState(false);

     const [dCadastro,setDcadastro] = useState(new Date);
     const [dAlteracao,setDalteracao] = useState(new Date);
     const [dultCompra,setDultcompra] = useState(new Date);
     const [dultVenda,setDultvenda] = useState(new Date);

     const [usaDcadastro,setUsaDcadastro] = useState(false);
     const [usaDAlteracao,setusaDalteracao] = useState(false);
     const [usaDultCompra,setusaDultcompra] = useState(false);
     const [usaDultVenda,setusaDultvenda] = useState(false);


     const [cGrupo,setCgrupo] = useState(0);
     const [cSubGrupo,setCsubGrupo] = useState(0);
     const [cLinha,setClinha] = useState(0);


     const [diferenteZero,setDiferenteZero] = useState(false);
     const [estoqueNegativo,setEstoqueNegativo] = useState(false);
     const [vendaZerada,setVendaZerada] = useState(false);
     const [prodInativo,setProdInativo] = useState(false);

     const [Itens,setItens] = useState([]);
     const [resultItens,setResultItens] = useState([]);
     const [grupos,setGrupos] = useState([]);
     const [load,setLoad] = useState(false);
     const [nPag,setnPag] = useState(1);
     const [totalPaginas,setTotalPaginas] = useState(0); 
     const [tpRelatorio,setTprelatorio] = useState(0);
     const [tpBusca,setTpBusca] = useState(0)

     function handlePageChange(value){
      if(value === "&laquo;" || value === '... '){
        setnPag(1);
      }else if (value === "&lsaquo;" ){
        if (nPag !== 1){
          setnPag(-1);
        }
      }else if(value === "&rsaquo;"){
        setnPag(+1);        
      }else if(value === "&raquo;" || value === ' ...' ){
        setnPag(totalPaginas);        
      }else{
        setnPag(value);
      }    
     
     }

     // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

      


      function deleteUser(id){
          api.post(vhostV1('webcash/produto/excluir/'+id), {
            loja: localStorage.getItem('loja'),
            empresa: 0        
          },config)
          .then(function (response) {
            setConfirmacao(false);
            window.location.reload();              
                    
          })
          .catch(function (error) {
            console.log(error);
            setConfirmacao(false);
            
          });
      
      }

  //buscar Grupos
  useEffect(function(){      
    api.post(vhostV1('webcash/grupo/listar'), {
        loja: 11,
        empresa: 0,
        busca: ''
      },config)
      .then(function (response) {
        setGrupos(response.data.dados); 
        
      })
      .catch(function (error) {
        console.log(error+'erro ao listar Grupos');
                
      });

      
  },[])


  function filtraProdutos(){
    setLoad(true)
    const sJson = {}
    sJson.loja=localStorage.getItem('loja'); 
    sJson.empresa=0;
    sJson.grupo=cGrupo;
    sJson.subgrupo=cSubGrupo;
    sJson.linha=cLinha;
    if(usaDcadastro === true)
    { sJson.data_cadastro=dCadastro}
    if(usaDAlteracao === true)
    { sJson.data_alteracao=dAlteracao}
    if(usaDultCompra === true)
    { sJson.data_compra=dultCompra}
    if(usaDultVenda === true)
    { sJson.data_venda=dultVenda}
    sJson.estoque_diferente=diferenteZero
    sJson.estoque_negativo=estoqueNegativo;
    sJson.preco_zerado=vendaZerada;
    sJson.inativo=false;
    sJson.pagina= nPag;
    sJson.regPag=10;
    sJson.busca= busca; 
    sJson.tipoBusca=tpBusca;     
    
      api.post(vhostV1('webcash/produto/listar'),sJson,config)
        .then(function (response) {
          console.log(response.data.dados);           
          setProdutos(response.data.dados); 
          setSresult(response.data.result)
          setLoad(false);
                                      
                   
        })
        .catch(function (error) {
          setLoad(false);
          console.log(error);

                  
        });
         
  }

  

  

const getDadosRelatorio = async()=>{
  
  const sJson = {}
  sJson.loja=localStorage.getItem('loja'); 
  sJson.empresa=0;
  sJson.grupo=cGrupo;
  sJson.subgrupo=cSubGrupo;
  sJson.linha=cLinha;
  if(usaDcadastro === true)
  { sJson.data_cadastro=dCadastro}
  if(usaDAlteracao === true)
  { sJson.data_alteracao=dAlteracao}
  if(usaDultCompra === true)
  { sJson.data_compra=dultCompra}
  if(usaDultVenda === true)
  { sJson.data_venda=dultVenda}
  sJson.estoque_diferente=diferenteZero
  sJson.estoque_negativo=estoqueNegativo;
  sJson.preco_zerado=vendaZerada;
  sJson.inativo=false;
  sJson.pagina= nPag;
  sJson.regPag=50;
  sJson.busca= busca; 
  sJson.tipoBusca=tpBusca;    
  let response = {};
  try {
    response = await api.post(vhostV1('webcash/produto/listar'), sJson, config)
    
  } catch (error) {
    console.log(error);          
  }

  console.log(response);
  
   
  return response.data.dados;

  //setItens(response.data.dados); 
  //setResultItens(response.data.result)
  
}



async function gerarRelatorio () { 

  const tItens = await getDadosRelatorio();

  ProdutoPdf(tItens);

  {/*if (condição1)
   instrução1
   else if (condição2)
   instrução2
  else if (condição3)
   instrução3
  
 
 ValorEstoque(tItens)*/
} 
}

function confirmadeleteUser(id){
   setConfirmacaoID(id);
   setConfirmacao(true);
   setBusca('');
}

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  //buscar 
  useEffect(function(){
    setLoad(true)
    //console.log('numero de Paniga '+nPag);
    const sJson = {}
    sJson.loja=localStorage.getItem('loja'); 
    sJson.empresa=0;
    sJson.grupo=cGrupo;
    sJson.subgrupo=cSubGrupo;
    sJson.linha=cLinha;
    if(usaDcadastro === true)
    { sJson.data_cadastro=dCadastro}
    if(usaDAlteracao === true)
    { sJson.data_alteracao=dAlteracao}
    if(usaDultCompra === true)
    { sJson.data_compra=dultCompra}
    if(usaDultVenda === true)
    { sJson.data_venda=dultVenda}
    sJson.estoque_diferente=diferenteZero
    sJson.estoque_negativo=estoqueNegativo;
    sJson.preco_zerado=vendaZerada;
    sJson.inativo=false;
    sJson.pagina= nPag;
    sJson.regPag=10;
    sJson.busca= busca; 
    sJson.tipoBusca=tpBusca;                   
    
      api.post(vhostV1('webcash/produto/listar'),sJson,config)
        .then(function (response) {
          console.log(response.data.dados);           
          setProdutos(response.data.dados); 
          setSresult(response.data.result)
          setTotalPaginas(response.data.qtdpaginas);
          setLoad(false);
          
                                      
                   
        })
        .catch(function (error) {
          setLoad(false);
          console.log(error);

                  
        });
  
        
      },[busca,nPag])

      


    return <div>
               
        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <div className='row'>
        <h1>Cadastro de Produtos</h1>
        </div>
        <hr />
        <div className="row">
          <div  className="col-4">
            
            <Link id='botao-produto' to='/app/novoProduto/-1/1' className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Produto</Link>
            <button id='botao-produto' className="btn btn-secondary  btn-cli" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-funnel-fill"></i>Filtros</button>          
            
          </div>
         
            
         
          

          <div className="col-3">
            
            <select class="form-select" onChange={(e)=> setTpBusca(e.target.value)} aria-label="Default select example">
              <option value={0} selected>Qualquer parte</option>
              <option value={1}>Começando com...</option>
              <option value={2}>Finalizando com...</option>
            </select>
          </div>
                        
          <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h1 className="modal-title fs-5" id="staticBackdropLabel">Relátorio de Produtos</h1>
                  <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                </div>
                <div className="modal-body">
                  <div className="contnt-modal">
                   <h5>Tipo do relatório </h5> 
                  <div className="form-check">
                    <input className="form-check-input" value={0} onChange={((e) => setTprelatorio(e.target.value))} type="radio" name="flexRadioDefault" id="flexRadioDefault1"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault1">
                      Lista de Produtos
                    </label>
                  </div>
                 

                  <div className="form-check">
                    <input className="form-check-input" value={2} onChange={((e) => setTprelatorio(e.target.value))} type="radio" name="flexRadioDefault" id="flexRadioDefault2"/>
                    <label className="form-check-label" htmlFor="flexRadioDefault2">
                      Valor do Estoque
                    </label>
                  </div>

                    
                    <hr />

                   <div className="row">
                     <h5>Filtros  {tpRelatorio}</h5>   
                     <div className="datas col-4">
                        <h6>Datas</h6>
                          <div className="form-check form-switch" >
                            <input className="form-check-input" onChange={(e) => setUsaDcadastro(e.target.checked)} value={usaDcadastro} type="checkbox" role="switch"  id="flexSwitchCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Cadastro</label>
                            <input type="date" value={dCadastro} onChange={(e) => setDcadastro(e.target.value)} className='form-control' />
                          </div>

                          <div className="form-check form-switch" >
                            <input className="form-check-input" type="checkbox" onChange={(e) => setusaDalteracao(e.target.checked)} value={usaDAlteracao} role="switch" id="flexSwitchCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Alteração</label>
                            <input type="date" value={dAlteracao} onChange={(e) => setDalteracao(e.target.value)} className='form-control'/>
                          </div>

                          <div className="form-check form-switch" >
                            <input className="form-check-input" type="checkbox" value={usaDultCompra} onChange={(e) => setusaDultcompra(e.target.checked)} role="switch" id="flexSwitchCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Última Compra</label>
                            <input type="date" value={dultCompra} onChange={(e) => setDultcompra(e.target.value)} className='form-control'/>
                          </div>

                          <div className="form-check form-switch" >
                            <input className="form-check-input" type="checkbox" value={usaDultVenda} onChange={(e) => setusaDultvenda(e.target.value)} role="switch" id="flexSwitchCheckDefault"/>
                            <label className="form-check-label" htmlFor="flexSwitchCheckDefault">Última Venda</label>
                            <input type="date" value={dultVenda} onChange={(e) => setDultvenda(e.target.value)} className='form-control'/>
                          </div>
                     </div>

                     <div className="classificacao col-4">
                      <h6>Classificação</h6>
                        <div >
                          <label className="form-select-label" htmlFor="flexCheckDefault">Grupo</label>
                          <select className="form-select" onChange={(e) => setCgrupo(e.target.value)} aria-label="Default select example">
                          <option value={0} >selecione o grupo</option>
                          {grupos.map((grupo,i) => {
                            return <option key={i} value={grupo.cod_grupo}>{grupo.descricao}</option>  
                          })}
                          
                        </select>
                        </div>

                        <div >
                          <label className="form-select-label" htmlFor="flexCheckDefault">Sub Grupo</label>
                          <select className="form-select" onChange={(e) => setCsubGrupo(e.target.value)} aria-label="Default select example">
                          <option >selecione o grupo</option>
                          <option value="1">cereais</option>
                          <option value="2">limpeza</option>
                          <option value="3">acougue</option>
                        </select>
                        </div>

                        <div >
                          <label className="form-select-label" htmlFor="flexCheckDefault">Linha</label>
                          <select className="form-select" onChange={(e) => setClinha(e.target.value)} aria-label="Default select example">
                          <option >selecione o grupo</option>
                          <option value="1">cereais</option>
                          <option value="2">limpeza</option>
                          <option value="3">acougue</option>
                        </select>
                        </div>

                      

                     </div>



                     <div className="condicoes col-4">
                        <h6>Condições e Filtros</h6>
                        <br />
                        <div className="form-check">
                          <input className="form-check-input" onChange={(e) => setDiferenteZero(e.target.checked)} type="checkbox" value="" id="flexCheckDefault"/>
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Estoque dif. de zero
                          </label>
                        </div>

                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" onChange={(e) => setEstoqueNegativo(e.target.checked)} id="flexCheckDefault"/>
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Estoque negativa
                          </label>
                        </div>

                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" onChange={(e) => setVendaZerada(e.target.checked)} id="flexCheckDefault"/>
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Pr. Venda Zerado
                          </label>
                        </div>

                        <div className="form-check">
                          <input className="form-check-input" type="checkbox" onChange={(e) => setProdInativo(e.target.checked)} id="flexCheckDefault"/>
                          <label className="form-check-label" htmlFor="flexCheckDefault">
                            Inativos
                          </label>
                        </div>


                     </div>

                   </div>

                  </div>
                </div>
                <div className="modal-footer">
                  <button type="button" onClick={filtraProdutos} className="btn btn-secondary" data-bs-dismiss="modal">Ativar</button>
                  { sResult === 1?
                  <div>                  
                  <button type="button" onClick={ async()=> await gerarRelatorio()} className="btn btn-primary btn-cli" data-bs-dismiss="modal">Imprimir</button>
                  <CSVLink data={produtos} className="btn btn-success" >Excel</CSVLink>
                  </div>
                  :null} 
                  

                </div>
              </div>
            </div>
          </div>

          <div className="col-5 ">
            <div className="input-group mb-3">
              <input  onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button  onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2">Pesquisar</button>
            </div>
          </div>
        </div>  
        
        <div className="row produtos">
          { sResult === 1?
          <>
          
          <ProdutoTable produtos={produtos}
                        
                        confirmDeleteUser={confirmadeleteUser}
                        /> 
                
              </>
            :<BuscaVazaia/>} 


        </div>

        

            <div className="row mt-5">
            <SRI_pagination totalPage={totalPaginas} 
                            page={nPag} 
                            limit={10} 
                            siblings={1}
                            onPageChange={handlePageChange}/>
            </div>
           

          
          {
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteUser(confirmacaoId)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir este Item!
            </SweetAlert>:null
           
          }
          
     
      </div> 
        
     
     


    </div>
    
}


export default Produto;