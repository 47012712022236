import React,{useState} from "react";
import Navbar from "../navbar/navbar";
import { api } from "../../services/api";
import { Link,Navigate,useParams } from 'react-router-dom'
import { useEffect } from "react";
import './produto.css'
import { vhostV1 } from "../funcoes";
import BuscaVazaia from "../components/buscavazia/buscaVazia";
import { NumericFormat } from 'react-number-format';






function NovoServico(){



    const inputs = document.querySelectorAll("input, select");

    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });
 



  const {cod} = useParams();
  const {tag} = useParams();

   const [cod_produto,setCod_produto]     = useState('');
   const [descricao,setDescricao]         = useState('');
   const [venda,setVenda]                 = useState(0);
   const [vendaDefault,setvendaDefault]   = useState(0);
   const [sucesso,setSucesso]             = useState('N')
   const [mensagem, setMensagem]          = useState('');
   const [grupos,setGrupos]               = useState([]);
   const [cod_grupo,setCod_grupo]         = useState(0);
    
    
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token"),
      'Content-Type': 'application/json'
    },
    'timeout': 10000
  }
  
  

  //buscar Produto
  useEffect(function(){  
      
    api.post(vhostV1('webcash/produto/Pesquisa/'+cod), {
        loja: localStorage.getItem('loja'),
        empresa: 0        
      },config)
      .then(function (response) {
        if (response.data.result === 1){
          console.log(response)
         setCod_produto(response.data.dados.COD_PRODUTO);  
         setDescricao(response.data.dados.DESCRICAO);  
         setVenda(response.data.dados.VENDA); 
         setvendaDefault(response.data.dados.VENDA);  
         setCod_grupo(response.data.dados.COD_GRUPO);
         }
                               
      });

      
  },[])

  //buscar Grupos
  useEffect(function(){
      
    api.post(vhostV1('webcash/grupo/listar'), {
        loja: 11,
        empresa: 0,
        busca: ''
      },config)
      .then(function (response) {
        setGrupos(response.data.dados); 
        
      })
      .catch(function (error) {
        console.log(error+'erro ao listar Grupos');
                
      });

      
  },[])

  
  function salvaProduto(e){
    e.preventDefault();
        api.post(vhostV1('webcash/produto/cadastro'),{
            loja:localStorage.getItem('loja'),
            EMPRESA:0,
            COD_INTERNO:cod,
            COD_PRODUTO:cod_produto,
            DESCRICAO:descricao,
            COD_GRUPO:cod_grupo,
            UNIDADE:'UN',
            MARKUP:0,
            VENDA_MARKUP:0,
            VENDA:Number(venda),
            ESTOQUE:0,
            COMPRA:0,
            COLABORADOR:localStorage.getItem('colaborador'),
            NCM:'00000000',   
            ST:'0000',
            ICMS_IN:0,
            ICMS_OUT:0,
            CFOP_IN:'5933',
            CFOP_OUT:'6933',
            CEST:'0000000',
            CSTPC:4,
            CSTPC_ENTRADA:70,
            ALI_COFINS_CREDITO:0,
            ALI_COFINS_DEBITO:0,
            ALI_PIS_CREDITO:0,
            ALI_PIS_DEBITO:0,
            SABORES:0,
            ADICIONAIS:0,
            LOCALIZACAO:''
        },config)
        .then(function(response){
            if(response.data.result === 1)
            { setMensagem('');
              setSucesso('S');
            }else{
              console.log(response.data.msg);
              setSucesso('N');
            }            
        }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
            console.log(erro)
        });

     }   
    


     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div className="offset-lg-1 col-lg-10">
               <h2>Cadastro de Serviço</h2>                   
               <hr />
                <div className="conteiner-fluid">
                <form id="table-servico" className="row tela" >
                   
                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                       <input type="text" value={cod_produto} onChange={(e)=> setCod_produto(e.target.value)} className="form-control" id="inputCodigo" tabIndex="1" required autoComplete="off"/>
                   </div>
                   <div className="col-5">
                       <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>
                       <input type="text" value={descricao} onChange={(e)=> setDescricao(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                   </div>
                   <div className="col-md-3">
                       <label htmlFor="inputState" className="form-label">GRUPO</label>
                       <select id="inputGrupo" value={cod_grupo} onChange={(e)=> setCod_grupo(e.target.value)} className="form-select" tabIndex="3">
                       { grupos.map((grupo)=> {
                        return <option key={grupo.cod_grupo} value={grupo.cod_grupo}>{grupo.cod_grupo} - { grupo.descricao}</option>          
                        } )}
                        
                       </select>
                   </div>                   
                          
                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">VALOR</label>
                       <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={vendaDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      onValueChange={(values,suorceInfo)=>{
                                        setVenda(values.value);
                                       
                                        
                                      }} 
                                 />  
                   </div>  

         
                   <div className="col-12">
                   <hr />
                       <Link to="/app/produtoServico" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                       {tag != '0'?(
                                  <button type="submit" onClick={salvaProduto} className="btn btn-primary butao" tabIndex="13">Salvar</button>
                                  ):null}

                   </div>
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? <Navigate to='/app/produtoServico' /> : null}
       
                   </form> 
            </div>
        </div>  
   </div>

}



export default NovoServico;