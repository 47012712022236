import { useEffect, useState } from 'react';
import React from 'react'
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';
import { Chart } from "react-google-charts";
import { log } from 'react-modal/lib/helpers/ariaAppHider';
import BuscaVazaia from '../buscavazia/buscaVazia';
import "./widget.css";
import ColumnChart from './chart';

function Widget(){

    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }



     const [result, setResult] = useState(0);   
     const [totalMes,setTotalMes] = useState(0);   
     const [totalDia,setTotalDia] = useState(0);   
     const [qtdVenda,setQtdVenda] = useState(0); 
     const [vendaPix, setVendaPix] = useState(0);
     const [vendaDinheiro, setVendaDinheiro] = useState(0);
     const [vendaCartao, setVendaCartao] = useState(0);
     const [vendaPixEstatico, setVendaPixEstatico] = useState(0);
     const [vendaSmartTef, setVendaSmartTef] = useState(0);
     const [dataAtual, setDataAtual] = useState(new Date());  
     const [tiket,setTiket] = useState(0);
     const [rankingProduto, setRankingProduto] = useState([]);
     const [rankVendedor, setRankVendedor] = useState([]);
     const [finalizadoraVenda, setFinalizadoraVendas] = useState([]);

     function validarData(dataString) {
        // Separa a string em dia, mês e ano
        var partes = dataString.split('/');
        var dia = parseInt(partes[0]);
        var mes = parseInt(partes[1]) - 1; // Mês começa do zero (janeiro é 0)
        var ano = parseInt(partes[2]);
    
        // Cria um objeto de data
        var data = new Date(ano, mes, dia);
    
        // Verifica se a data é válida
        if (data.getDate() == dia && data.getMonth() == mes && data.getFullYear() == ano) {
            // Formata a data no formato "yyyy-mm-dd"
            var dataFormatada = data.getFullYear() + '-' + ('0' + (data.getMonth() + 1)).slice(-2) + '-' + ('0' + data.getDate()).slice(-2);
            return dataFormatada;
        } else {
            return null; // Retorna null se a data for inválida
        }
    }


    


    useEffect(function(){
        api.post(vhostV1('webcash/dashboard/vendafinalizadoras'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataAtual
             
        },config)
        .then(function(response){
            console.log(response.data.dados);
            const dados = response.data.dados;
            setFinalizadoraVendas(dados);
        

               
            
        })
        .catch(function (error){
            console.log(error);
        });
    },[])  

    useEffect(function(){
        api.post(vhostV1('webcash/dashboard/produtoMaisVendido'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataAtual
        }, config)
        .then(function (response){
            console.log(response);
            setRankingProduto(response.data.dados);
            if(response && response.data && response.data.dados){
                setResult(1);
            }
        })
        .catch(function (error){
            console.log(error);
        })
    },[])

    if(rankingProduto !== undefined){
    rankingProduto.sort((a, b) => b.qtd - a.qtd);}

    useEffect(function(){
        api.post(vhostV1('webcash/dashboard/rankVendedor'),{
            loja: localStorage.getItem('loja'),
            empresa: 0,
            data: dataAtual
        }, config)
        .then(function (response){
            console.log(response);
            setRankVendedor(response.data.dados);
            if(response && response.data && response.data.dados){
                setResult(1);
            }
            
            
        })
        .catch(function (error){
            console.log(error);
        })
    },[])
    





    useEffect(function(){
        api.post(vhostV1('webcash/dashboard/widget'), {
          loja: localStorage.getItem('loja'),
          empresa: 0
          },config)
        .then(function (response) {        
          console.log(response.data);
          setTotalMes(response.data.dados.total);           
          setTotalDia(response.data.dados.venda);           
          setQtdVenda(response.data.dados.qtd);           
          setTiket(response.data.dados.ticket);           
      
        })
        .catch(function (error) {
          console.log(error);
                  
        });
                
    },[])




  return<div className="container-fluid">
  
  <div className="row widgets" id='bloco-widget'>
      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-primary shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                              Faturamento do Mês
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(totalMes).toFixed(2)}</div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-calendar fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-success shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                              Total de Venda (Dia)
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(totalDia).toFixed(2)}</div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-info shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-info text-uppercase mb-1">
                              Ticket Médio
                          </div>
                          <div className="row no-gutters align-items-center">
                              <div className="col-auto">
                                  <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">R$ {Number(tiket).toFixed(2)}</div>
                              </div>
                          </div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      <div className="col-xl-3 col-md-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Cupons Emitidos
                          </div>
                          <div className="h5 mb-0 font-weight-bold text-gray-800">{qtdVenda}</div>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-comments fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

 
  <div className="row mb-4">
      <div className="col-12">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="grid-container">
                      
                      <div className="grid-item">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Gráfico de Vendas (R$)
                          </div>
                          <ColumnChart/>
                      </div>

                  
                      <div className="grid-item">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Gráficos de Venda (Descrição)
                          </div>
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th scope='col'>Finalizadora</th>
                                      <th scope='col'>Venda</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {finalizadoraVenda !== undefined ?
                                      finalizadoraVenda.map((item) => (
                                          <tr key={item.finalizadora}>
                                              <td>{item.finalizadora}</td>
                                              <td>R$ {Number(item.valor_final).toFixed(2)}</td>
                                          </tr>
                                      ))
                                      : <BuscaVazaia />}
                              </tbody>
                          </table>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>

 
  <div className="row mb-4">
      <div className="col-xl-6 col-lg-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Produtos mais vendidos
                          </div>
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th scope='col'>Descrição</th>
                                      <th scope='col'>Quantidade</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {result === 1 ?
                                      rankingProduto.map((item) => (
                                          <tr key={item.descricao}>
                                              <td>{item.descricao}</td>
                                              <td>{item.qtd}</td>
                                          </tr>
                                      ))
                                      : <BuscaVazaia />}
                              </tbody>
                          </table>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>

      
      <div className="col-xl-6 col-lg-6 mb-4">
          <div className="card border-left-warning shadow h-100 py-2">
              <div className="card-body">
                  <div className="row no-gutters align-items-center">
                      <div className="col mr-2">
                          <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                              Ranking Vendedores
                          </div>
                          <table className="table table-hover table-striped">
                              <thead>
                                  <tr>
                                      <th scope='col'>Código</th>
                                      <th scope='col'>Nome</th>
                                      <th scope='col'>Total</th>
                                      <th scope='col'>Vendas</th>
                                  </tr>
                              </thead>
                              <tbody className='table-group-divider'>
                                  {result === 1 ?
                                      rankVendedor.map((vendedor) => (
                                          <tr key={vendedor.cod_colaborador}>
                                              <td>{vendedor.cod_colaborador}</td>
                                              <td>{vendedor.nome}</td>
                                              <td>R$ {Number(vendedor.total).toFixed(2)}</td>
                                              <td>{vendedor.qtd}</td>
                                          </tr>
                                      ))
                                      : <BuscaVazaia />}
                              </tbody>
                          </table>
                      </div>
                      <div className="col-auto">
                          <i className="fas fa-chart-line fa-2x text-gray-300"></i>
                      </div>
                  </div>
              </div>
          </div>
      </div>
  </div>
</div>
}

export default Widget;