import React from 'react';

function Featuretes(){
    return <section id='featuretes'>
        <div className='container'>
           <div className='row'>
               <div className='col-lg-4 featuretes-box'>
                  <i className="icon fas fa-heart fa-5x"></i> 
                  <h3>Facil de usar</h3>
                  <p>O sistema possui um interface muito simples e fácil de utilizar</p>
               </div>

               <div className='col-lg-4 featuretes-box'>
               <i className="icon fas fa-globe-americas fa-5x"></i>
                  <h3>Em Qualquer lugar</h3>
                  <p>Gerencie seu fluxo de forma eficiente, onde você esteja</p>
               </div>

               <div className='col-lg-4 featuretes-box'>
                  <i className="icon fas fa-columns fa-5x"></i>
                  <h3>Organização é tudo</h3>
                  <p>Tenha sua empresa sempre muito bem organizada</p>
               </div>
   

               
            </div>




        </div>
    </section>
}



export default Featuretes;