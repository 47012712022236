import React,{useEffect,useState} from 'react';
import Navbar from '../navbar/navbar';
import './home.css'
import { FloatingWhatsApp } from 'react-floating-whatsapp'
import whats from "../../images/whats.jpg";
import Banners from '../components/banners/Banners';
import Featuretes from '../components/featuretes';
import XmlReader from '../components/XmlReader';
import customizeScrollbar from "customsb";




//import Testemunho from '../components/testemunho';






function Home() {

    var data = new Date();

    const dia = String(data.getDate()).padStart(2,0);  
    const mes = String(data.getMonth()+1).padStart(2,0);
    const ano = data.getFullYear();

    const strDate = `${dia}/${mes}/${ano}`;


        

    const xmlString = '';
   

    function xmlToJson(xml) {
       // Remove os caracteres "<" e ">" da string
        const cleanedString = xml.replace(/<\/?[^>]+(>|$)/g, '');

        // Divide a string em um array usando a quebra de linha como separador
        const lines = cleanedString.split('\n');

        // Cria um array de objetos contendo as informações de cada item
        const items = [];
        let currentItem = {};

        for (let i = 0; i < lines.length; i++) {
        const line = lines[i].trim();

        if (line.startsWith('<det nItem=')) {
            // Inicia um novo item
            currentItem = {};
            items.push(currentItem);

            // Obtém o número do item
            const nItemMatch = line.match(/<det nItem="(\d+)">/);
            if (nItemMatch) {
            currentItem.nItem = parseInt(nItemMatch[1]);
            }
        } else if (line.startsWith('<')) {
            // Obtém o nome do campo e o valor
            const fieldMatch = line.match(/<([^>]+)>(.*?)<\/\1>/);
            if (fieldMatch) {
            const fieldName = fieldMatch[1];
            const fieldValue = fieldMatch[2];
            currentItem[fieldName] = fieldValue;
            }
        }
        }

        console.log(items);
      
        return items;
      }


      function handleXml(){
        const jsonArray = xmlToJson(xmlString);
      
            console.log(jsonArray);
      
      }
       // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);
      
  

    return <div>
        <Navbar/>
        <Banners/>
        <Featuretes/>     
        

        
        
        <div className="container-fluid titulo">
            <FloatingWhatsApp
                phoneNumber="81996042501"
                accountName="SRI automação"
                statusMessage="respondemos em até 15 minutos"
                allowEsc="true"
                allowClickAway="false"
                notification
                notificationSound
                chatMessage="olá em que posso ajudar ?"
                placeholder="digite sua mensagem"
                avatar={whats}
            />
         </div> 

         
          
    </div>

  
}


export default Home;