import React, { useState } from 'react'
import './compra.css'
import Modal from 'react-modal/lib/components/Modal';
import { vhostV1 } from '../funcoes';
import api from '../../services/api';
import ModalProduto from '../components/modalProduto/ModalProduto';


function ModalAssociacao({isOpen,onRequestClose,prodAssociar
                         ,openModalProduto,descProduto,qtd, produtos}){
    const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });

    let config = {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
      }

  
    const [descricao, setDescricao] = useState('');
    const [codProduto, setCodProduto] = useState('');
    const [qtdPedido, setQtdPedido] = useState(0);
    const [qtdEmbalagem, setQtdEmbalagem] = useState(0);
    const [qtdUnitizada, setQtdUnitizada] = useState(0);
    const [isProdutoOpen, setIsProdutoOpen] = useState(false);
    const [vendaDefault, setVendaDefault] = useState(0);
    
    function closeModalProduto(){
      setIsProdutoOpen(false);
      
 }

 function openModalProduto(){
  setIsProdutoOpen(true);
  
}
  
function obterQtd(codigoDesejado){
  let objetoEncontrado = produtos.find(produto => produto.cProd === codigoDesejado)

  if(objetoEncontrado){
    return objetoEncontrado.qCom;
  } else{
    console.log("objeto não encontrado");
  }
}

let qty = obterQtd(prodAssociar);


    

    function buscaIten(){
      api.post(vhostV1('webcash/produto/retornaproduto/'+codProduto), {
        loja: localStorage.getItem('loja'),
        empresa: 0         
      },config)
      .then(function (response) { 
        
       if(response && response.data && response.data.dados){
        setDescricao(response.data.dados.DESCRICAO);
        setCodProduto(response.data.dados.COD_PRODUTO);
        setVendaDefault(response.data.dados.VENDA);
        console.log(response);

        
        
         }
       else{
         console.log("não busquei")
       }  
        
       })
      .catch(function (error) {
       
        console.log(error);
                
      });
       };

    return   <Modal isOpen={isOpen}
                  onRequestClose={onRequestClose}
                  setCodProduto={setCodProduto}
                  setDescricao={setDescricao}
                  qtd={qtd}                  
                  overlayClassName="react-modal-overlay"
                  ariaHideApp={false}
                  className="react-modal-content">

               <ModalProduto isOpen={isProdutoOpen}
                 onRequestClose={closeModalProduto}
                 setCodProduto={setCodProduto}
                 setDescricao={setDescricao}
                 setVendaDefault={setVendaDefault}
                 titulo="pesquisa de Produto"
                 />     

                    
<div className="container-fluid h-100 pt-4">
<div className="row">
  <h4>Associação de Item</h4>  
  <hr />
  Codigo do Produto: {prodAssociar}
</div> 
<div className="row col-12 ">
        <div className="col-5">
            <label htmlFor="inputAddress" className="form-label">CODIGO</label>
            <div className="input-group mb-3">
            <input name='inputCodProduto' value={codProduto} type="text" onChange={(e) => setCodProduto(e.target.value)} onBlur={(e)=> buscaIten(e)} className="form-control"  aria-describedby="button-addon2" />
            <button  className="btn btn-primary" onClick={(e) => openModalProduto()} type="button" id="button-addon2"><i style={{fontSize: "15px"}} className="bi bi-search "></i></button>
        </div>
        </div>

        <div className="col-7">
        <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>   
        <input  type="text"  className="form-control" onChange={(e) =>setDescricao(e.target.value)} value={descricao} id="inputDescricao" tabIndex="2" required autoComplete="off" readOnly/>
        </div>

        <div className="col-4">
        <label htmlFor="inputAddress"  className="form-label">QTD EMBALAGEM</label>   
        <input  type="text" value={qtdEmbalagem} onChange={(e) => setQtdEmbalagem(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
        </div>

        <div className="col-4">
        <label htmlFor="inputAddress"  className="form-label">QTD PEDIDA</label>   
        <input  type="text" value={qty} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
        </div>

        <div className="col-4">
        <label htmlFor="inputAddress"  className="form-label">QTD UNITIZADA</label>   
        <input  type="text" value={qtdUnitizada} onChange={(e) => setQtdUnitizada(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
        </div>
</div>
<hr />


<div className="col-12">

    <button type="submit" onClick={onRequestClose} className="btn btn-outline-primary butao"  tabIndex="14">Desistir</button>
    <button type="submit"  className="btn btn-primary butao" tabIndex="13">Salvar</button>
    
</div>



</div>



</Modal>
}



export default ModalAssociacao
