import React, { useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalAdicaoItem.css'
import { NumericFormat } from 'react-number-format';
import { useEffect } from 'react';
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';




function ModalAdicaoItem(props){
  const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    }); 


  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  const [descricao,setDescricao] = useState('')
  const [venda,setVenda] = useState(0);
  const [vendaDefault,setVendaDefault] = useState(0);
  const [qtd,setQtd] = useState();
  const [desconto,setDesconto] = useState(0);
  const [descontoDefault,setDescontoDefault] = useState(0);
  const [total,setTotal] = useState(0);

  


  function calculaPreco(){

    setTotal(qtd * vendaDefault - desconto);
 }

 async function gravaItem(){
  
  await adicionaItem();    
  setDescricao('');
  setQtd('');
  setTotal('');
  setVenda('');  
  props.onRequestClose();
 }

  async function adicionaItem(){ 
    const sjson ={
        COD_PRODUTO:props.codProduto,
        UNIDADE:"UN",
        QTD:qtd,
        VENDA:venda,
        COMPRA:1,
        DESCONTO:0,
        TOTAL:total,
        NCM:"00000000",
        CFOP:"5102",
        ALIQUOTA:"18",
        DOCUMENTO:props.idVenda,
        COD_COLABORADOR:localStorage.getItem("colaborador"),
        RESPONSAVEL:10,
        ALI_ICMS:18,
        ST:"0000",
        CEST:"0000000",
        IPI:53,
        ALI_IPI:0,
        SEGURO:0,
        FRETE:0,
        ST_PISCOFINS:1,
        ALI_PIS:1.65,
        ALI_COFINS:7.6,
        VLRPIS:10,
        VLRCOFINS:10,
        BC_RED_ST:0,
        ST_IPI:53,
        OUTRAS_DESP_AC:0,
        ICMSDESONERADO:0,
        ALIQ_ICMS:0,
        BC_ICMS:0,
        ESTADUAL:1,
        MVA:0,
        VLR_BCSUBST:0,
        VLR_ICMSSUBST:0,
        BC_STRET:0,
        DESC_FIN:0,
        REDBC:0,
        VLR_IPI:0,
        ALIQUOTA_IPI:0,
        VLR_COFINS:0,
        VLR_PIS:0,
        ICMS_DESONERADO:0 };
        let res = {};       
        try {
          res = await api.post(vhostV1('webcash/nfe/incluiritem'),sjson,config)
          console.log('retorno api'+res.data) 
          console.log('retorno api'+res.response)    
          if(res && res.data && res.data.dados ){ 
            console.log(res)            
          }else{
            console.log('erro no response de produtos '+res)
          }
        } catch (error) {

          console.log(error);
          }
          
          

          }
        
 // funcao para buscar produto
 useEffect(function(){
  api.post(vhostV1('webcash/produto/retornaproduto/'+props.codProduto), {
    loja: localStorage.getItem('loja'),
    empresa: 0         
  },config)
  .then(function (response) {    
    setDescricao(response.data.dados.DESCRICAO);
    setVendaDefault(response.data.dados.VENDA);
    setVenda(response.data.dados.VENDA);                     
   })
  .catch(function (error) {
   
    console.log(error);
            
  });
   },[props.codProduto]);

  return <Modal isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                overlayClassName="react-modal-overlay"
                ariaHideApp={false}
                className="react-modal-content">
          <div className="container-fluid pesquisa-modal">
            <div className="row">
                <h4>{props.titulo}</h4> 
                <hr />  
                  <div className="modal-body">
                  <div className="row">
                    
                    <div className="col-4">
                    <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                        <input name='inputCodProduto' value={props.codProduto}  type="text" className="form-control"   />
                    </div>
                          
                  
                  <div className="col-8">
                  <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>   
                  <input  type="text" value={descricao} onChange={(e) => setDescricao(e.target.value)} className="form-control" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                  </div>
                  </div>
         

                  
                  <div className="row mt-3">

                  <div className="col-4">
                  <label htmlFor="inputAddress" className="form-label">VENDA</label>   
                       <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={vendaDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="3"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      
                                                              
                       />
                       </div>
                  
                  <div className="col-4">
                  <label htmlFor="inputAddress" className="form-label">QTD</label>   
                  <input type="text" value={qtd} onBlur={calculaPreco} onChange={(e) => setQtd(e.target.value)}className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off"/>
                  </div>


                  <div className="col-4">
                  <label htmlFor="inputAddress" className="form-label">TOTAL</label>   
                        <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      defaultValue={total.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="3"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      value={total.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      
                                                             
                       />
                  </div>

                  </div>
                  
                </div>             
                
            </div>           
            
            <div className="footer" >
              <button className='btn btn-danger botao-fechar'onClick={props.onRequestClose}>Cancelar</button>
              <button className='btn btn-primary' onClick={gravaItem}>Adicionar</button>
            </div>
          </div>
    

    
  </Modal>
}

export default ModalAdicaoItem;