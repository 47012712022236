import React, { useState, useEffect } from 'react';
import {Link,useParams,Navigate}  from 'react-router-dom';
import Navbar from '../../navbar/navbar';
import './colaborador.css';
import { api } from '../../../services/api';
import { vhostV1 } from '../../funcoes';
import { FaEye, FaEyeSlash } from 'react-icons/fa';


function EditarColaborador(props){
  const {cod} = useParams();
      
    
    const [nome, setNome] = useState('');
    const [login, setLogin] = useState('');
    const [senha, setSenha] = useState('');
    const [mensagem, setMensagem] = useState('');
    const [sucesso, setSucesso] = useState('N');
    const [id,setId] = useState();
    const [showPassword, setShowPassword] = useState(false);

    let config = {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    }

    const togglePasswordVisibility = () => {
      setShowPassword(!showPassword);
  };
    

    useEffect(function(){
      


       
      api.post(vhostV1('webcash//usuario/retornaUsuario/'+cod), {
          loja: 11,
          empresa: 0         
        },config)
        .then(function (response) {
        
          
            console.log(response.data.dados); 
            setNome(response.data.dados.NOME);
            setLogin(response.data.dados.LOGIN);
            setSenha(response.data.dados.SENHA);
            setId(cod);
                   
        })
        .catch(function (error) {
          console.log(error);
                  
        });
  
        
    }, [])

  
    
    
    

    function AlterarColaborador(){
      
      if (nome.length === 0){
        setMensagem('Informe o nome');
      }
      else if (senha.length === 0){
        setMensagem('Informe o e-mail');
      }
      else{
        api.post(vhostV1('webcash/usuario/editar'),{
          loja: localStorage.getItem('loja'),
          EMPRESA:0,
          ID:id,
          NOME:nome.toUpperCase(),
          LOGIN:login.toUpperCase(),
          SENHA:senha,
          },config)
      .then(function(response){
          setMensagem('');
          setSucesso('S');
          console.log(response);
          

      }).catch((erro) =>{
          setMensagem(erro);
          setSucesso('N');
          console.log(erro);
          
      });
         
    }
  }

    return <div>
        <Navbar/>
        <div className="container-fluid titulo">

          <div className="offset-lg-3 col-lg-6">
            <h1>Editar Cliente</h1>
            <form>
              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">ID</label>
                <input type="text" value={cod} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">NOME</label>
                <input type="text" onChange={(e) => setNome(e.target.value)} value={nome} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp"  />
              </div>

              <div className="mb-3">
                <label htmlFor="exampleInputEmail1" className="form-label">LOGIN</label>
                <input onChange={(e) => setLogin(e.target.value)} value={login} type="text" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />              
              </div>

              <div className="mb-3">
            <label htmlFor="inputPassword" className="form-label">SENHA</label>
            <div className="input-group">
                <input
                    onChange={(e) => setSenha(e.target.value)}
                    value={senha}
                    type={showPassword ? 'text' : 'password'}
                    className="form-control"
                    id="inputPassword"
                    aria-describedby="passwordHelp"
                />
                <button
                    className="btn btn-outline-secondary"
                    type="button"
                    onClick={togglePasswordVisibility}
                >
                    {showPassword ? <FaEyeSlash /> : <FaEye />}
                </button>
            </div>
        </div>

              

              <div className="text-center">
                <Link to="/app/Colaborador" className="btn btn-outline-primary btn-acao">Cancelar</Link>
                <button onClick={AlterarColaborador} type="button" className="btn btn-primary btn-acao">Salvar</button>
              </div>

              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? <Navigate to='/app/Colaborador' /> : null}

            </form>
          </div>
        </div>
    </div>;  
  }

export default EditarColaborador;