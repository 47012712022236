import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar/navbar'; 
import { api } from '../../../services/api';
import './banco.css';
import {vhostV1} from '../../funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import BuscaVazaia from '../../components/buscavazia/buscaVazia';
import Loading from '../../components/loading/Loading';
import bancosPDF from '../../reports/bancos/listabancos';
import customizeScrollbar from "customsb";

function Banco() {

  const [descricao,setDescricao] = useState('');
  const [cedente_caixaEcon,setcedente_caixaEcon] = useState(0);
  const [conta_corrente,setConta_Corrente] = useState('0');
  const [cadastrado_por,setCadastrado_Por] = useState(99);
  const [alterado_por,setAlterado_Por] = useState(99)
  const [conc,setConc] = useState('S');
  const [num_carteira,setNum_carteira] = useState('0');
  const [desc_carteira,setDesc_Carteira] = useState('');
  const [num_conta,setNumConta] = useState(0);
  const [digito_conta,setDigito_Conta] = useState(0);
  const [num_agencia,setNum_Agencia] = useState(0);
  const [digito_agencia,setDigito_Agencia] = useState(0);
  const [tpJuros,setJuros] = useState(0);
  const [tpMulta,setMulta] = useState(0);
  const [inscob01,setINSCOB01] = useState('');
  const [insccob02,setINSCOB02] = useState('');
  const [multaCod,setMULTACOB] = useState(0);
  const [jurosCod,setJUROSCOB] = useState(0);
  const [localPagamento,setLOCALPAGAMENTO] = useState('');
  const [instrucao_cobranca,setINSTRUCAO_COBRANCA] = useState('');
  const [cedente_dv,setCEDENTE_DV] = useState('');
  const [contaReduzida,setCONTAREDUZIDA] = useState('');
  const [usaBoleto,setUsaBoleto] = useState(false);
     const [load,setLoad] = useState(false);
     const [confirmaBanco, setConfirmaBanco] = useState('');
     const [confirmacao,setConfirmacao] = useState(false);
     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [banco,setbanco] = useState([]);
     const [sResult,setSresult] = useState(0);

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  //Apagar banco

  function deleteBanco(id) {
    api.post(vhostV1('webcash/banco/excluir'),{
      loja: localStorage.getItem('loja'),
      empresa: 0 ,
      seq_banco: id
    },config)
    .then(function(response) {
      setConfirmacao(false);
      window.location.reload();

      
    }).catch(function(error){
      console.log(error);
      setConfirmacao(false);
      
    })
    
  }
  // Confirmar banco

  function confirmBanco(id) {
    setConfirmaBanco(id);
    setConfirmacao(true);
    setBusca('');
    
  }

  //buscar banco
  useEffect(function(){
    
      api.post(vhostV1('webcash/banco'), {
          loja: 11,
          empresa: 0,
          busca: busca
        },config)
        .then(function (response) {
            if (response && response.data && response.data.dados){
            setbanco(response.data.dados); 
            console.log(response.data.dados)
            setSresult(1);
          }else{
            setSresult(0);
          }       
        })
        .catch(function (error) {
          console.log(error);
                  
        });
  
        
    },[busca])

    // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

    


    return <div>
        <Navbar/> 
            <div className="container-fluid titulo offset-lg-1 col-lg-10">
            <h1>Cadastro de Banco</h1>

            
         
        <div className="row">
          <div className="col-4 d-flex">
            <Link to='/app/novoBanco/-1/1' className="btn btn-primary btn-cli me-2 custom-btn" type="button"><i className="fas fa-plus"></i> Banco</Link>
            <button onClick={(e) => bancosPDF(banco)} className="btn btn-danger btn-cli" type="button" id="button-addon2"><i className="bi bi-printer-fill"></i> Imprimir </button> 
          </div>

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
            </div>
          </div>
        </div>   

         { load === true?<Loading/>:null}
        { sResult === 1? 
         <table id='table-banco' className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">Seq.</th>
                <th scope="col">Cod. Banco</th>
                <th scope="col">Descricao</th>
                <th scope="col">Ações</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              { banco.map((banco)=> {
                return <tr key={banco.SEQ_BANCO}>
                    <td>{banco.SEQ_BANCO}</td>
                    <td>{banco.BANCO}</td>             
                    <td>{banco.DESCRICAO}</td>
                    <td>
                    <div id="table-prod" className="dropdown">
                    <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Opções
                    </button>
                      <ul className='dropdown-menu'>
                        <li>
                        <Link to ={'/app/novoBanco/' + banco.SEQ_BANCO +'/0'} className="dropdown-item"> <i class="bi bi-eye-fill"></i>Vizualizar</Link>  
                        </li>
                        <li>
                        <Link to={'/app/novoBanco/' + banco.SEQ_BANCO +'/2'} className="dropdown-item"><i className="fas fa-edit icone-acao"></i>Editar</Link>
                        </li>
                        <li>
                        <Link  value={banco.SEQ_BANCO} onClick={()=>(confirmBanco(banco.SEQ_BANCO))} className="dropdown-item" ><i className="far fa-trash-alt icone-acao red"></i>Excluir</Link>
                        </li>
                      </ul>
                    </div>
                    
                    
                    </td>
                </tr>
                } )}
            </tbody>
          </table>
          :<BuscaVazaia/>}
           
             
           
 
        {
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteBanco(confirmaBanco)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir este Item!
            </SweetAlert>:null

        }

      </div> 
      
          
    </div>
}


export default Banco;