import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function ListaCadastroPdf(cadastros){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Relatorio de Cadastros',
            fillColor: '#eeeeee',
            fontSize: 15,
            alignment:'center',
            bold: true,
            margin: [15, 20, 15, 45] // left, top, right, bottom
        }
    ];
    
    const dados = cadastros.map((cadastro) => {
        return [
            {text: cadastro.COD_CADASTRO, fontSize: 7, margin: [0, 2, 0, 2]},
            {text: cadastro.RAZAO, fontSize: 7, margin: [0, 2, 0, 2]},
            {text: cadastro.CNPJ, fontSize: 7, margin: [0, 2, 0, 2],alignment:'left'},
            
           
        ] 
    });

    const details = [
        {
            table:{
                headerRows: 1,
                widths: [45,'*','*'],
                body: [
                    [
                        {text: 'Código', style: 'tableHeader', fontSize: 10},
                        {text: 'Nome', style: 'tableHeader', fontSize: 10},
                        {text: 'cpf/cnpj', style: 'tableHeader', fontSize: 10},
            
                       
                    ],
                    ...dados
                ]
            },
            layout: 'headerLineOnly'
        }
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitios).open();
}

export default ListaCadastroPdf;