import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

class EtiquetaRoupaPDF extends React.Component {
  generatePDF = () => {
    const { produto } = this.props;
    const barcodeOptions = {
      format: 'CODE128',
      displayValue: true,
      fontSize: 10,
      lineColor: '#000',
      width: 1,
      height: 20
    };

    const canvas = document.createElement('canvas');
    JsBarcode(canvas, produto.COD_PRODUTO, barcodeOptions);
    const barcodeDataURL = canvas.toDataURL('image/png');

    const documentDefinition = {
      content: [
        {
          columns: [
            // { text: ` ${produto.COD_PRODUTO}`, style: 'item',  },
            {
                image: barcodeDataURL,
                width: 90,
                height: 50,
                margin: [-35, 0, 30, 0]
              },
       ] },
       {
        columns: [
            { text: 'Data: __/__/____', margin: [-30, 0, 30, 0], fontSize: 10}
        ]
       },
       {
        columns: [
            { text: ` ${produto.DESCRICAO}`, style: 'item', width: 170, fontSize: 10, margin: [-30, 10, 30, 0] }
        ]
       },
       {
        columns: [
            { text: `${produto.VENDA.toLocaleString('pt-br', { style: 'currency', currency: 'BRL'})}`, style: 'item',fontSize: 12, margin: [-30, 0, 30, 0]  }
        ]
       }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        item: {
          margin: [0, 5],
          bold: true,
          alignment: 'justify between'
        },
        text: {
          bold: true
        },
       
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  render() {
    return (
      <div>
        <button onClick={this.generatePDF}  className='dropdown-item ' ><i className="fas fa-barcode etiquetaIcon"></i>  Roupa</button>
      </div>
    );
  }
}

export default EtiquetaRoupaPDF;