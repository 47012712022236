import React from 'react'
import Navbar from '../navbar/navbar';
import Grafico from '../components/grafico/grafico';
import ItensVendidos from '../components/itensVendidos/itensVendidos';
import './dashboard.css';
import './sb-admin-2.min.css';
import './sb-admin-2.css';
import Widget from '../components/widget/widget';


function Dashboard(){

    const dados_clientes = [
        ["Cliente", "Vendas"],
        ["Microsoft", 5200],
        ["IBM", 4900],
        ["Apple", 4200],
        ["Facebook", 3210],
        ["Google", 2140]
      ];

      const dados_vendas = [
        ["Mês", "Vendas"],
        ["1", 5200],
        ["2", 4900],
        ["3", 7200],
        ["4", 3210],
        ["5", 5362],
        ["6", 6200],
        ["7", 6852],
        ["8", 4210],
        ["9", 5630],
        ["10", 8452],
        ["11", 4250],
        ["12", 6325]        
      ];

      const dados_produtos = [
        ["Produto", "Vendas"],
        ["dinherio", 5200],
        ["catao credito", 4900],
        ["cartao debito", 4200],
        ["pix", 3210],
        
      ];

      const dados_cidades = [
        ["Cidade", "Vendas"],
        ["São Paulo", 5200],
        ["Rio de Janeiro", 4900],
        ["Campinas", 4200],
        ["Curitiba", 3210],
        ["Bauru", 2140]
      ];

  return <>
    <Navbar/>
      <div className="container-fluid titulo">
        
        <div className="ms-4 d-flex justify-content-between">
            <h2>Dashboard</h2>
            <button className="btn btn-primary me-4"><i className="bi bi-arrow-clockwise"></i>Atualizar</button>
        </div> 
           
        <div className="row d-flex justify-content">
            <Widget/>

            <div className="row col-12 ">
                

                
               {
                //<div className="col-md-6 p-5">
                //    <Grafico titulo="Formade pagamento" 
                //             chartType="Bar"
                //             dados={dados_clientes}
                //             legenda={false} />
                //</div>               

                //<div className="col-md-6 p-5">
                //    <Grafico titulo="Venda por cidade" 
                //             chartType="BarChart"
                //             dados={dados_cidades}
                //             legenda={false} />
                //</div>

                } 

             </div>
                {
             //   <div className="row col-4 mt-5">
                   
             //   <h3>Lista de Produtos</h3>
             //     <div className="row itensVendidos ">
             //      <ItensVendidos/>
             //      </div>
             //   </div>
                   }
            </div>

            <div class="row">

                        
                        <div class="col-7">
                            <div class="card shadow mb-4">
                               
                                <div
                                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 font-weight-bold text-primary">Vendas por Hora</h6>                                    
                                </div>
                                
                                <div class="card-body">
                                    <div class="chart-area">
                                    <div className="">
                                        <Grafico titulo="" 
                                                chartType="Line"
                                                dados={dados_vendas}
                                                legenda={false} />
                                    </div> 
                                    </div>
                                </div>
                            </div>
                        </div>

                        
                        <div class="col-5">
                            <div class="card shadow mb-4">
                                
                                <div
                                    class="card-header py-3 d-flex flex-row align-items-center justify-content-between">
                                    <h6 class="m-0 font-weight-bold text-primary">Formas de pagamento</h6>
                                    
                                </div>
                                
                                <div class="card-body">
                                <div className="">
                                    <Grafico titulo="" 
                                            chartType="PieChart"
                                            dados={dados_produtos}
                                            legenda={true} />
                                </div>
                                </div>
                            </div>
                        </div>
                    </div>

        </div>

    </>
  
}

export default Dashboard;