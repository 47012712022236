import React, { useState } from 'react';

const Carousel = ({ buttons }) => {
  const [currentButton, setCurrentButton] = useState(null);

  const handleButtonClick = (button) => {
    setCurrentButton(button);
    window.alert(`Você clicou no botão: ${button}`);
  };

  return (
    <div>
       <div>
        {buttons.map((button, index) => (
          <button className='content' key={index} onClick={() => handleButtonClick(button)}>
            {button}
          </button>
        ))}
      </div>
      
    </div>
  );
};

export default Carousel;