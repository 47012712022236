import React,{useState} from "react";
import Navbar from "../navbar/navbar";
import { api } from "../../services/api";
import { Link,useParams } from 'react-router-dom'
import { useEffect } from "react";
import './produto.css'
import { vhostV1 } from "../funcoes";





function EditarProduto(props){
   const {cod} = useParams();


   const [cod_produto,setCod_produto] = useState('');
   const [descricao,setDescricao] = useState('');
   const [cod_grupo,setCod_grupo] = useState(0);
   const [unidade,setUnidade] = useState('UN');
   const [venda,setVenda] = useState(0);
   const [colaborador,setColaborador] = useState(99);
   const [ncm,setNcm] = useState('');
   const [sucesso,setSucesso] = useState('N')
   const [mensagem, setMensagem] = useState('');
   const [grupos,setGrupos] = useState([]);
   const [compra,setCompra]= useState(0)
   
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  //buscar Grupos
  useEffect(function(){

   
      
    api.post(vhostV1('webcash/produto/Pesquisa/'+cod), {
        loja: localStorage.getItem('loja'),
        empresa: 0        
      },config)
      .then(function (response) {
         buscaGrupos();
         setCod_produto(response.data.dados.COD_PRODUTO);  
         setDescricao(response.data.dados.DESCRICAO);  
         setNcm(response.data.dados.COD_NCM);  
         setVenda(Number(response.data.dados.VENDA).toFixed(2));  
         setUnidade(response.data.dados.UNIDADE);  
         setCod_grupo(response.data.dados.COD_GRUPO);  
         
        console.log(cod_grupo);   

                
      });

      
  },[])

 


  function buscaGrupos(){
        api.post(vhostV1('webcash/grupo/listar'), {
                loja: localStorage.getItem('loja'),
                empresa: 0,
                busca: ''
            },config)
            .then(function (response) {
                setGrupos(response.data.dados); 
                console.log(response.data.dados);         

            })
            .catch(function (error) {
                console.log(error+'erro ao listar Grupos');

        })
        .catch(function (error) {
            console.log(error+' erro ao buscar produto');
                    
        });

  }



  function salvaProduto(){
        
        api.post(vhostV1('webcash/produto/updatecadastro'),{
            loja: localStorage.getItem('loja'),
            EMPRESA:0,
            ID:cod,
            DESCRICAO:descricao,
            COD_GRUPO:cod_grupo,
            UNIDADE:unidade,
            VENDA:venda,
            COLABORADOR:99,
            NCM:ncm        
        },config)
        .then(function(response){
            setMensagem('');
            setSucesso('S');
            document.location ='/app/produto';
            
        }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
        });
     }


     function openModalProduto(){
        alert('opendmodal')
  
    }
  
  
    function closeModalProduto(){
      alert('closeModal')
    }





     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div className="offset-lg-1 col-lg-10">
               <h2>Cadastro de Produto</h2>      
                
                <div className="conteiner-fluid">
                <form className="row tela">
                   
                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                       <input type="text" value={cod_produto} onChange={(e)=> setCod_produto(e.target.value)} className="form-control" id="inputAddress" disabled />
                   </div>
                   <div className="col-8">
                       <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>
                       <input type="text" value={descricao} onChange={(e)=> setDescricao(e.target.value)} className="form-control" id="inputAddress" />
                   </div>
                   <div className="col-md-2">
                       <label htmlFor="inputState" className="form-label">UNIDADE</label>
                       <select id="inputState" onChange={(e)=> setUnidade(e.target.value)} className="form-select" value={unidade}>
                           <option>UN</option>
                           <option>KG</option>
                           <option>CX</option>                    
                           <option>LT</option>                    
                       </select>
                   </div>
                   <div className="col-md-4">
                       <label htmlFor="inputState" className="form-label">GRUPO</label>
                       <select id="inputState" onChange={(e)=> setCod_grupo(e.target.value.substring(1,3))} className="form-select" value={cod_grupo}>
                       { grupos.map((grupo)=> {
                        return <option value={grupo.cod_grupo}> { ("000" + grupo.cod_grupo).slice(-3) +' - ' +grupo.descricao}</option>          
                        } )}
                        
                       </select>
                   </div>
                   
                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">COMPRA</label>
                       <input type="text" onChange={(e)=> setCompra(e.target.value)} className="form-control" id="inputCompra"/>
                   </div> 
       
                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">VALOR</label>
                       <input type="text" value={venda} onChange={(e)=> setVenda(e.target.value)} className="form-control" id="inputAddress"/>
                   </div>
                   <div className="col-md-2">
                       <label htmlFor="inputCity" className="form-label">NCM</label>
                       <input type="text"  value={ncm} onChange={(e)=> setNcm(e.target.value)} className="form-control" id="inputCity"/>
                   </div>
                   
                   
                   <div className="col-12">
                       <Link to="/app/produto" type="submit" className="btn btn-outline-primary butao">Desistir</Link>
                       <button type="submit" onClick={salvaProduto} className="btn btn-primary butao">Salvar</button>
                       
                   </div>
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? document.location ='/app/produto' : null}
                   
       
                   </form> 
            </div>
        </div>  
   </div>

}



export default EditarProduto;