import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


function VendasPorCupom(salesData) {
  // Carregue as fontes do pdfmake
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  // Verifique se salesData é um array válido
  salesData = Array.isArray(salesData) ? salesData : [];

  // Agrupe os dados por número de venda usando reduce
  const groupedSalesData = salesData.reduce((acc, sale) => {
    if (!sale || !sale.DOCUMENTO) return acc; // Ignore dados inválidos
    if (!acc[sale.DOCUMENTO]) {
      acc[sale.DOCUMENTO] = {
        itens: [],
        data: sale.DATA ?? 'Data não disponível',
        numccf: sale.DOCUMENTO ?? 'Documento não disponível',
        total: 0,
        caixa: sale.CAIXA ?? 'Caixa não disponível',
      };
    }
    acc[sale.DOCUMENTO].itens.push(sale);
    acc[sale.DOCUMENTO].total = sale.VALOR ?? 0 ;
    return acc;
  }, {});

  // Calcule o total geral usando reduce
  const total = Object.values(groupedSalesData).reduce((sum, venda) => sum + venda.total, 0);
  const totalDesconto = salesData.reduce((acc, item) => acc + (parseFloat(item.DESCONTO_CUPOM) || 0), 0);

  // Defina as configurações do documento
  const docDefinition = {
    content: [
      { text: 'Razão Social', margin: [0, 20], style: 'title' },
      { text: 'CNPJ', margin: [0, 0, 0, 20], style: 'title' },
      { text: 'Relatório de Venda', margin: [0, 0, 0, 20], style: 'header' },
    ],
    styles: {
      title: {
        fontSize: 14,
        bold: true,
        alignment: 'center',
      },
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
        alignment: 'center',
      },
      blockHeader: {
        fontSize: 12,
        bold: true,
        margin: [0, 10, 0, 5],
        background: '#f0f0f0',
        padding: [5, 5, 5, 5],
      },
      tableHeader: {
        fontSize: 10,
        bold: true,
        fillColor: '#f0f0f0',
      },
      tableBody: {
        fontSize: 8,
      },
      summary: {
        fontSize: 10,
        bold: true,
        margin: [0, 10, 0, 10],
        alignment: 'right',
      },
    },
  };
  

  // Adicione os dados de venda ao documento
  Object.keys(groupedSalesData).forEach((venda) => {
    const vendaData = groupedSalesData[venda];
    console.log(vendaData);
    

    const vendaContent = [
      {
        columns: [
          {
            width: '25%',
            text: `Caixa: ${vendaData.caixa ?? 'N/A'}`,
            style: 'blockHeader',
          },
          {
            width: '25%',
            text: `Venda: ${venda ?? 'N/A'}`,
            style: 'blockHeader',
          },
          {
            width: '25%',
            text: `Numccf: ${vendaData.numccf ?? 'N/A'}`,
            style: 'blockHeader',
          },
          {
            width: '25%',
            text: `Data: ${vendaData.data ?? 'N/A'}`,
            style: 'blockHeader',
          },
        ],
        margin: [0, 0, 0, 10],
        border: [false, false, false, true],
        borderColor: '#d0d0d0',
        borderWidth: [0, 0, 0, 1],
      },
      {
        table: {
          headerRows: 1,
          widths: [100, 120, 35, 35, 35, 45, 50],
          body: [
            [
              { text: 'Código', style: 'tableHeader' },
              { text: 'Descrição', style: 'tableHeader' },
              { text: 'Item', style: 'tableHeader' },
              { text: 'Qtd', style: 'tableHeader' },
              { text: 'Venda', style: 'tableHeader' },
              { text: 'Desconto', style: 'tableHeader' },
              { text: 'Total', style: 'tableHeader' },
            ],
            ...vendaData.itens.map((sale) => [
              sale.COD_PRODUTO ?? 'Código não disponível',
              sale.DESCRICAO ?? 'Descrição não disponível',
              sale.ITEM ?? 'Item não disponível',
              (Number(sale.QTD ?? 0)).toFixed(3),
              { alignment: 'right', text: (Number(sale.VENDA ?? 0)).toFixed(2) },
              { alignment: 'right', text: (Number(sale.DESCONTO_CUPOM ?? 0)).toFixed(2) },
              { alignment: 'right', text: (Number(sale.VENDA * sale.QTD ?? 0) - (sale.DESCONTO_CUPOM ?? 0)).toFixed(2) },
            ]),
          ],
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
        style: 'tableBody',
      },
      {
        text: `Total R$ ${Number(vendaData.total).toFixed(2)}`,
        style: 'summary',
      },
    ];

    docDefinition.content.push(...vendaContent);
  });

  // Adicione o rodapé com o total geral
  const rodape = [{
    text: `Total Geral R$ ${Number(total).toFixed(2)}`,
    style: 'summary',
  }];

  docDefinition.content.push(...rodape);

  // Gere o documento PDF
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.open();
}

export default VendasPorCupom;



