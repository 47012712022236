import { Chart } from "react-google-charts";

function Grafico(props){

    const options = {
        pieHole: 0.3,
        legend: props.legenda ? { } : { position: "none" }
    }

    return <>
    <h3 className="text-secondary">{props.titulo}</h3>

        <Chart chartType={props.chartType}
               data={props.dados}
               width="100%"
               height="350px"
               options={options}
               legendToggle />    
    </>    
}

export default Grafico;