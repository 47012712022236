import React from 'react'
import './compra.css'
import { useState } from 'react';
import Modal from 'react-modal/lib/components/Modal';


function ModalItens(props){

  

   

    return   <Modal isOpen={props.isOpen}
                  onRequestClose={props.onRequestClose}
                  overlayClassName="react-modal-overlay"
                  ariaHideApp={false}
                  className="react-modal-content">
<div className="container-fluid h-100 pt-4">
<div className="row">
  <h4>Itens da Nota</h4>  
  <hr />
</div> 

<div className="row produtos">

{ props.itens.length > 0?
                  <table className="table table-hover table-striped">
                  <thead>
                    <tr>
                        <th scope="col">CODIGO</th>
                        <th scope="col">DESCRICÃO</th>
                        <th scope="col">QTD</th>
                        <th scope="col">COMPRA</th>
                        <th scope="col">AÇÃO</th>
                    </tr>
                  </thead>
                  <tbody className="table-group-divider">
                  { props.itens.map((p)=> {
                return <tr key={p.cProd}>
                    <td>{p.cProd}</td>          
                    <td>{p.xProd}</td>
                    <td>{Number(p.qCom).toFixed(4)}</td>
                    <td>{Number(p.vUnCom).toFixed(4)}</td>
                    <td>
                    {p.proxforn.COD_BARRA ?null:(
                        <a  onClick={(e) => props.abreAssociacao(p.cProd)}  >
                        <i class="bi bi-pen-fill"></i>
                        </a>)}                                        
                    </td>
                </tr>
            } )}    
                  </tbody>
              </table>:null}

</div>
<div className="row col-12 footer d-flex justify-content-between ">
    <button className='btn btn-danger botao-fechar'onClick={props.onRequestClose}>Cancelar</button>
    <button className='btn btn-primary'>Confirmar</button>
</div>



</div>



</Modal>
}



export default ModalItens
