import React, { useState, useEffect } from 'react';
import { Link,Navigate } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import './controle-mesa.css'
import { api } from '../../services/api';
import Loading from '../components/loading/Loading';
import Footer from '../components/footer/Footer';
import Card from './card';
import mesas from './mesas';





function ControleMesa() {


     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [produtos,setProdutos] = useState([]);
     const [sResult,setSresult] = useState(0);
     const [load,setLoad] = useState(false);
 
 

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

    function CreateCard(mesas){
        return <Card 
                 id={mesas.id}
                 nome={mesas.nome}
                 valor={mesas.valor}
                 
                />
    }

 

    return <div>
        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <h1>Controle de Mesas</h1>
        <hr />
        <div className="row">
          <div className="col-4">
            
            <Link to='/app/pedido/resumopedido' className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Mesa</Link>
            <button className="btn btn-secondary  btn-cli" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-arrow-clockwise"></i>Atualizar</button>
            
          </div>
              
          

          <div className="col-8">
            <div className="input-group mb-3">
              <input onChange={(e) => setTexto(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por mesa ou nome cliente" aria-describedby="button-addon2" />
              <button onClick={(e) => setBusca(texto)} className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i></button>
            </div>
          </div>
        </div>  
            <div className="container" style={{backgroundColor:"red"}}>
                <div >
                 <Card 
                   id={1}
                   nome={'MARCOS'}
                   valor={'20.20'}                 
                 />
               </div> 

               <div >
                 <Card 
                   id={2}
                   nome={'MARCOS'}
                   valor={'20.20'}                 
                 />
               </div> 


               </div>   

               
        
               

        
 

          
     
      </div> 

        
      <Footer/>
    </div>
}


export default ControleMesa;