import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar/navbar'; 
import { api } from '../../../services/api';
import {vhostV1} from '../../funcoes';
import BuscaVazaia from "../../components/buscavazia/buscaVazia";
import './tesouraria.css'
import customizeScrollbar from "customsb";
import SweetAlert from 'react-bootstrap-sweetalert';


function Tesouraria() {

  const [dataInicial,setDataInicial] = useState('');
  const [dataFinal,setDataFinal] = useState('');
  const [texto,setTexto] = useState('');
  const [busca,setBusca] = useState('');
  const [resultConta,setresultConta] = useState(0);
  const [tesouraria,setTesouraria] = useState([]);
  const [seq_banco, setSeqBanco] = useState(0);
  const [bancos, setBancos] = useState([]);
  const [contasOriginais, setContasOriginais] = useState([]);
  const [msgErro, setmsgErro] = useState('');
  const [erroValidacao, setErroValidacao] = useState(false);
  
  const [pessoas,setPessoas] = useState([])
  const [buscaPessoa,setBuscaPessoa] = useState('');
  const [resultPessoa,setResultPessoa] = useState(0);
  const [razao,setRazao] = useState('');
  const [responsavel,setResponsavel] = useState(0);
  
  const [vlrHistorico,setvlrHistorico] = useState(0);
  const [vlrSaldo,setvlrSaldo] = useState(0);
  const [tipoMovimento, setTipoMovimento] = useState([]);
  const [tesourariaFiltrada, setTesourariaFiltrada] = useState([]);
  const [sacado,setSacado] = useState(0);
  const [usaData,setUsaData] = useState(true);
  const [marcado,setMarcado] = useState(false);
  const [checkboxChecked, setCheckboxChecked] = useState(false);
  const [checkboxNome, setCheckboxNome] = useState(false);
  let contasQuitar = [];
  let valorQuitar = 0;



 let config = {
   headers: {
     'Authorization': localStorage.getItem("token")
   }
 }


 // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);

const parseDateFromDMY = (dateStr) => {
  if (!dateStr || dateStr.trim() === '') return null;
  const [day, month, year] = dateStr.split('/').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
};

const parseDateFromISO = (dateStr) => {
  if (!dateStr || dateStr.trim() === '') return null;
  const [year, month, day] = dateStr.split('-').map(Number);
  return new Date(Date.UTC(year, month - 1, day));
};

console.log(checkboxChecked);


const handleFilter = () => {
  
const startDateVencimento = parseDateFromISO(dataInicial);
const endDateVencimento = parseDateFromISO(dataFinal);


  
  console.log('Venc:' + endDateVencimento);
  

  const result = tesouraria.filter(conta => {
   
    const dueDate = parseDateFromDMY(conta.DATA_VENC);

    
    console.log('Item Dates: ', {
      dueDate  
    });

    if(checkboxChecked === false && checkboxNome === false){
      setTesouraria(contasOriginais);
      setmsgErro('selecione algum dos marcadores para filtrar por ele')
      setErroValidacao(true)
      return tesouraria
    } else if(checkboxChecked === true && checkboxNome === false){
      const dateFilter = (!startDateVencimento || (dueDate && dueDate >= startDateVencimento)) &&
      (!endDateVencimento || (dueDate && dueDate <= endDateVencimento));
      return dateFilter
    } else if(checkboxChecked === false && checkboxNome === true){
      const nameFilter = !responsavel || (conta.RESPONSAVEL && conta.RESPONSAVEL === Number(responsavel));
      return nameFilter
    } else {
      const dateFilter = (!startDateVencimento || (dueDate && dueDate >= startDateVencimento)) &&
      (!endDateVencimento || (dueDate && dueDate <= endDateVencimento));
      const nameFilter = !responsavel || (conta.RESPONSAVEL && conta.RESPONSAVEL === Number(responsavel));
      return dateFilter && nameFilter;
    }




    
   
   
      
      
      
    
  });
  console.log('Filtered Results:', result);
  setTesouraria(result);
  
};


  function limpaPesquisa() {
    setDataInicial('');
    setDataFinal('');
    setRazao('');
    setTesouraria(contasOriginais);
    setCheckboxChecked(false);
    setCheckboxNome(false);
  }


  // function listaContas(e) {
  //   e.preventDefault();

  //   api.post(vhostV1('webcash/tesouraria/listacontas'), {
  //     empresa: 0,
  //     tipo: "R",
  //     sacado: responsavel,
  //     datainicial: dataInicial,
  //     datafinal: dataFinal,
  //     loja: localStorage.getItem('loja')
  //   }, config)
  //   .then((response) => {
  //     const contasFiltradas = filtrarTesouraria(response.data.dados, dataInicial, dataFinal, razao);
  //     setTesouraria(contasFiltradas);
  //     setresultConta(response.data.result);
  //   })
  //   .catch((error) => {
  //     console.log(error);
  //   });
  // }



  useEffect(function(){
    api.post(vhostV1('webcash/contas'), {
        loja: 11,
        empresa: 0,
        busca: '',
        tipo: 'P',
        colaborador:0
      },config)
      .then(function (response) {
         setresultConta(response.data.result); 
         setTesouraria(response.data.dados); 
         setContasOriginais(response.data.dados)
         console.log(response.data.dados);
                   
        }                               
      )
      .catch(function (error) {
        console.log(error);
                
      });
  
      
  
  
      
  },[])

function listaPessoa(e){
  e.preventDefault();
  api.post(vhostV1('webcash/cadastro'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      tipo: "%",
      busca: buscaPessoa
    },config)
    .then(function (response) {
      setPessoas(response.data.dados);  
      setResultPessoa(response.data.result);
      console.log(response.data.dados);    
      
               
    })
    .catch(function (error) {
     
      console.log(error);

              
    });
  };



  useEffect(function(){
    api.post(vhostV1('webcash/banco'), {
        loja: 11,
        empresa: 0,
        busca: ''  
      },config)
      .then(function (response) {
        if(response && response.data && response.data.dados){
         setBancos(response.data.dados);
                 
        }}                               
      )
      .catch(function (error) {
        console.log(error);
                
      });
  },[])

  useEffect(function(){
    api.post(vhostV1('webcash/TipoMovimentoBancario'), {
        empresa: 0          
      },config)
      .then(function (response) {
        console.log(response);
                                      
  })
      .catch(function (error) {
        console.log(error);
                
      });
  },[])



  function pesquisaPessoa(e){
    console.log(e.substring(1,6));
    setResponsavel(e.substring(1,6));
    setRazao(e.substring(9,50));
    
  };


  function marcaLinha(e){
     console.log(e.target.checked); 
  };
 


   
   return <div>
   <Navbar />
   <div className="container-fluid">
       <div className="row mb-4">
           <div className="col-lg-10 offset-lg-1">
               <h1 className="mb-4">Tesouraria</h1>

               <div className="form-check mb-3">
          <input
            className="form-check-input"
            type="checkbox"
            checked={checkboxChecked}
            onChange={(e) => setCheckboxChecked(e.target.checked)}
            id="flexCheckDefault"
          />
          <label
            className="form-check-label"
            htmlFor="flexCheckDefault"
          >
            Data Vencimento
          </label>
        </div>

        <div className="row mb-3 align-items-end">
          <div className="col-md-3 mb-2 mb-md-0">
            <input
              type="date"
              className="form-control"
              value={dataInicial}
              onChange={(e) => setDataInicial(e.target.value)}
            />
          </div>
          <div className="col-md-3 mb-2 mb-md-0">
            <input
              type="date"
              className="form-control"
              value={dataFinal}
              onChange={(e) => setDataFinal(e.target.value)}
            />
          </div>
          <div className="col-md-6 d-flex justify-content-end">
            <button
              onClick={handleFilter}
              className="btn btn-primary me-2"
              type="button"
            >
              <i className="fas fa-search"></i> Pesquisar
            </button>
            <button
              onClick={limpaPesquisa}
              className="btn btn-danger"
              type="button"
            >
              <i className="fas fa-trash"></i> Limpar
            </button>
          </div>
        </div>

               <div className="row mb-4">
                   <div className="col-lg-6">
                       <div className="input-group">
                       <input
                          className="form-check-input"
                          type="checkbox"
                          checked={checkboxNome}
                          onChange={(e) => setCheckboxNome(e.target.checked)}
                          id="flexCheckDefault"
                               />
                           <input
                               type="text"
                               placeholder="Pesquisar por nome"
                               value={razao}
                               onChange={(e) => setRazao(e.target.value)}
                               className="form-control"
                               disabled
                           />
                           <button
                               type="button"
                               className="btn btn-secondary"
                               data-bs-toggle="modal"
                               data-bs-target="#staticBackdrop"
                           >
                               Pesquisar
                           </button>
                       </div>

                       <div
                           className="modal fade"
                           id="staticBackdrop"
                           data-bs-backdrop="static"
                           data-bs-keyboard="false"
                           aria-labelledby="staticBackdropLabel"
                           aria-hidden="true"
                       >
                           <div className="modal-dialog modal-lg">
                               <div className="modal-content">
                                   <div className="modal-header">
                                       <h1 className="modal-title fs-5" id="staticBackdropLabel">
                                           Pesquisa Cadastro
                                       </h1>
                                       <button
                                           type="button"
                                           className="btn-close"
                                           data-bs-dismiss="modal"
                                           aria-label="Close"
                                       ></button>
                                   </div>
                                   <div className="modal-body">
                                       <div className="input-group mb-3">
                                           <input
                                               type="text"
                                               className="form-control"
                                               placeholder="Digite sua pesquisa"
                                               value={buscaPessoa}
                                               onChange={(e) => setBuscaPessoa(e.target.value)}
                                               aria-describedby="button-addon2"
                                           />
                                           <button
                                               onClick={listaPessoa}
                                               className="btn btn-primary"
                                               type="button"
                                               id="button-addon2"
                                           >
                                               <i className="fas fa-search"></i> Pesquisar
                                           </button>
                                       </div>

                                       {resultPessoa === 1 ? (
                                           <table className="table table-hover table-striped">
                                               <thead>
                                                   <tr>
                                                       <th scope="col">Código</th>
                                                       <th scope="col">Razão</th>
                                                       <th scope="col">Ação</th>
                                                   </tr>
                                               </thead>
                                               <tbody className="table-group-divider">
                                                   {pessoas.map((pessoa) => (
                                                       <tr key={pessoa.COD_CADASTRO}>
                                                           <td>{pessoa.COD_CADASTRO}</td>
                                                           <td>{pessoa.RAZAO}</td>
                                                           <td>
                                                               <a
                                                                   href="#"
                                                                   onClick={() =>
                                                                       pesquisaPessoa(
                                                                           ('000000' + pessoa.COD_CADASTRO).slice(-6) +
                                                                               ' - ' +
                                                                               pessoa.RAZAO
                                                                       )
                                                                   }
                                                                   className="text-decoration-none"
                                                               >
                                                                   <i className="fas fa-edit icone-acao red" data-bs-dismiss="modal"></i>
                                                               </a>
                                                           </td>
                                                       </tr>
                                                   ))}
                                               </tbody>
                                           </table>
                                       ) : (
                                           <BuscaVazaia />
                                       )}
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>

                   <div className="col-lg-6 d-flex justify-content-end">
                       <button
                           type="button"
                           className="btn btn-success"
                           data-bs-toggle="modal"
                           data-bs-target="#exampleModal"
                       >
                           <i className="bi bi-coin"></i> Quitar Contas
                       </button>

                       <div
                           className="modal fade"
                           id="exampleModal"
                           tabIndex="-1"
                           aria-labelledby="exampleModalLabel"
                           aria-hidden="true"
                       >
                           <div className="modal-dialog">
                               <div className="modal-content">
                                   <div className="modal-header">
                                       <h1 className="modal-title fs-5" id="exampleModalLabel">
                                           Modal Title
                                       </h1>
                                       <button
                                           type="button"
                                           className="btn-close"
                                           data-bs-dismiss="modal"
                                           aria-label="Close"
                                       ></button>
                                   </div>
                                   <div className="modal-body">
                                       <div className="mb-3">
                                           <label htmlFor="inputBanco" className="form-label">Banco</label>
                                          <select id="inputBanco" value={seq_banco}   onChange={(e)=> setSeqBanco(e.target.value)} className="form-select" tabIndex="4">
                                              { bancos.map((banco)=> {
                                     return <option key={banco.SEQ_BANCO} value={banco.SEQ_BANCO}> {banco.DESCRICAO}</option>      
                               
                                           } )}                        
                                            </select> 
                                       </div>
                                       <div className="mb-3">
                                       <label htmlFor="">Tipo Movimento</label>
                                       <select name="tipoMovimento" id="tpMov" className='form-control'></select>
                                       </div>
                                       <div className="mb-3">
                                           <label htmlFor="inputDate" className="form-label">Data</label>
                                           <input type="date" className="form-control" id="inputDate" />
                                       </div>
                                   </div>
                                   <div className="modal-footer">
                                       <button
                                           type="button"
                                           className="btn btn-secondary"
                                           data-bs-dismiss="modal"
                                       >
                                           Close
                                       </button>
                                       <button type="button" className="btn btn-primary">
                                           Save changes
                                       </button>
                                   </div>
                               </div>
                           </div>
                       </div>
                   </div>
               </div>

               {resultConta === 1 ? (
                   <table id="table-tes" className="table table-hover table-striped">
                       <thead>
                           <tr>
                               <th scope="col" width="5%">
                                   <input
                                       className="form-check-input"
                                       type="checkbox"
                                       onChange={(e) => setMarcado(e.target.checked)}
                                   />
                               </th>
                               <th scope="col">Seq.</th>
                               <th scope="col">Empresa</th>
                               <th scope="col">Responsável</th>
                               <th scope="col">Razão</th>
                               <th scope="col">Data Emissão</th>
                               <th scope="col">Data Vencimento</th>
                               <th scope="col">Valor</th>
                               <th scope="col">Juros</th>
                               <th scope="col">Multa</th>
                               <th scope="col">Valor Pago</th>
                               <th scope="col">Desconto</th>
                               <th scope="col">Total Pagar</th>
                           </tr>
                       </thead>
                       <tbody className="table-group-divider">
                           {tesouraria.map((item) => (
                               <tr key={item.SEQ}>
                                   <th scope="row">
                                       <input
                                           className="form-check-input"
                                           type="checkbox"
                                           value={item.TOTAL_PAGAR}
                                           id={item.SEQ}
                                           onChange={(e) => marcaLinha(e)}
                                       />
                                   </th>
                                   <td>{item.SEQ}</td>
                                   <td>{item.EMPRESA}</td>
                                   <td>{item.RESPONSAVEL}</td>
                                   <td>{item.RAZAO}</td>
                                   <td>{item.DATA_EMISSAO}</td>
                                   <td>{item.DATA_VENC}</td>
                                   <td>{Number(item.VALOR).toFixed(2)}</td>
                                   <td>{Number(item.JUROS).toFixed(2)}</td>
                                   <td>{Number(item.MULTA).toFixed(2)}</td>
                                   <td>{Number(item.VALOR_PAGO).toFixed(2)}</td>
                                   <td>{Number(item.DESCONTO).toFixed(2)}</td>
                                   <td>{Number(item.TOTAL_PAGAR).toFixed(2)}</td>
                               </tr>
                           ))}
                       </tbody>
                   </table>
               ) : (
                   <table id="table-tesouraria" className="table table-hover table-striped">
                       <thead>
                           <tr>
                               <th scope="col">Seq.</th>
                               <th scope="col">Empresa</th>
                               <th scope="col">Responsável</th>
                               <th scope="col">Razão</th>
                               <th scope="col">Data Emissão</th>
                               <th scope="col">Data Vencimento</th>
                               <th scope="col">Valor</th>
                               <th scope="col">Juros</th>
                               <th scope="col">Multa</th>
                               <th scope="col">Valor Pago</th>
                               <th scope="col">Desconto</th>
                               <th scope="col">Total Pagar</th>
                           </tr>
                       </thead>
                       <tbody>
                           <tr>
                               <td colSpan="12" className="text-center">
                                   Nenhum dado disponível
                               </td>
                           </tr>
                       </tbody>
                   </table>
               )}

               <div className="row mt-4">
                   <div className="col-md-6">
                       <div className="mb-3">
                           <label htmlFor="inputHistorico" className="form-label">Total Contas</label>
                           <input
                               type="text"
                               value={Number(vlrHistorico).toFixed(2)}
                               onChange={(e) => setvlrHistorico(e.target.value)}
                               className="form-control"
                               id="inputHistorico"
                               readOnly
                           />
                       </div>
                   </div>
                   <div className="col-md-6">
                       <div className="mb-3">
                           <label htmlFor="inputSaldoPagar" className="form-label">Saldo Pagar</label>
                           <input
                               type="text"
                               value={Number(vlrSaldo).toFixed(2)}
                               onChange={(e) => setvlrSaldo(e.target.value)}
                               className="form-control"
                               id="inputSaldoPagar"
                               
                           />
                       </div>
                   </div>
               </div>
           </div>
       </div>
       
       {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErroValidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
   </div>
</div>
    
}


export default Tesouraria;