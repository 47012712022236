import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import Barcode from './Barcode';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

function etiqueta () {

    const codigoBarras = '1234567890';

    const docDefinition = {
      pageSize: {
        width: 230,
        height: 100 // Valor aproximado em pontos (1mm = 2.83465 pontos)
      },
      content: [        
            { text: <Barcode value={codigoBarras} />}, { text: <Barcode value={codigoBarras} />}, { text: <Barcode value={codigoBarras} />}],
               
            
      
    };

    pdfMake.createPdf(docDefinition).open();
  }

  

export default etiqueta;