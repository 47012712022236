import _ from 'lodash';

export const returnPaginationRange = (totalPage,page,limit,siblings) => {
    let totalPagenationArray = 7 + siblings;
      if(totalPagenationArray >= totalPage){
         return _.range(1,totalPage + 1);
        }

      let leftSiblingsIndex = Math.max(page - siblings,1)
      let rightSiblingsIndex = Math.min(page + siblings,totalPage);

        let showLeftDots = leftSiblingsIndex > 2; // 
        let ShowRightDots =  rightSiblingsIndex < totalPage - 2;

        if(!showLeftDots && ShowRightDots){
            let leftItItemsCount = 3 + 2 * siblings;
            let leftRange = _.range(1,leftItItemsCount +1);
            return [...leftRange," ...", totalPage];
        }else if (showLeftDots && !ShowRightDots){
            let rightItemsCount = 3 + 2 * siblings;
            let rightRange = _.range(totalPage - rightItemsCount +1,totalPage+1)
            return[1,"... ",...rightRange];
        } else {
            let middleRange = _.range(leftSiblingsIndex,rightSiblingsIndex+1);
            return [1,"... ",...middleRange," ...",totalPage];
        }

}  



     




