import React,{useState} from "react";
import Navbar from "../navbar/navbar";
import { api } from "../../services/api";
import { Link,Navigate,useParams } from 'react-router-dom'
import './cadastro.css'
import { vhostV1 } from "../funcoes";
import estados from "../../services/estados";
import pais from "../../services/pais";
import { useEffect } from "react";
import SweetAlert from "react-bootstrap-sweetalert";
import { FaEye, FaEyeSlash } from 'react-icons/fa';







function NovoCadastro(props){

  document.addEventListener('keydown', function(event) {
    if (event.key === 'Enter') {
      event.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável
      var inputs = document.getElementsByTagName('input');
       for (var i = 0; i < inputs.length; i++) {
        if (inputs[i] === document.activeElement && i < inputs.length - 1) {
          inputs[i+1].focus();
          break;
        }
      }
    }
  });


   const {tpc} = useParams();
   const {id} = useParams();
   const {tag} = useParams();

    const [empresa,setEmpresa] = useState(0);
    const [razao,setRazao] = useState('');
    const [fantasia,setFantasia] = useState('');
    const [cnpj,setCnpj] = useState('');
    const [estadual,setEstadual] = useState('');
    const [inscMunicipal,setinscMunicipal] = useState('');
    const [cae,setCae] = useState('');
    const [endereco,setEndereco] = useState('');
    const [bairro,setBairro] = useState('');
    const [cep,setCep] = useState('');
    const [cidade,setCidade] = useState('');
    const [estado,setEstado] = useState('PE');
    const [telefone,setTelefone] = useState('');
    const [fax,setFax] = useState('');
    const [celular,setCelular] = useState('');
    const [contato,setContato] = useState('');
    const [endcob,setEndcob] = useState('');
    const [bairrocob,setBairrocob] = useState('');
    const [cepcob,setCepcob] = useState('');
    const [cidadecob,setCidadecob] = useState('');
    const [estadocob,setEstadocob] = useState('');
    const [obs,setObs] = useState('');
    const [atividade,setAtividade] = useState(0);
    const [correspondencia,setCorrespondencia] = useState('');
    const [tributacao,setTributacao] = useState('');
    const [comissao,setComissao] = useState(0);
    const [vendedor,setVendedor] = useState(0);
    const [registro,setRegistro] = useState('');
    const [deslocamento,setDeslocamento] = useState(0);
    const [ativo,setAtivo] = useState('T');
    const [multdistancia,setMultdistancia] = useState(0);
    const [multatividade,setMultatividade] = useState(0);
    const [cadastrado_por,setCadastrado_Por] = useState(99);
    const [cadastrado_em,setCadastrado_em] = useState('');
    const [alterado_por,setAlterado_Por] = useState(99);
    const [alterado_em,setAlterado_em] = useState('');
    const [area,setArea] = useState(0);
    const [limite,setLimite] = useState(0);
    const [ultimo_venc,setUltimo_venc] = useState('');
    const [atual_venc,setAtual_venc] = useState('');
    const [prazo,setPrazo] = useState(0);
    const [tipo_fatura,setTipo_fatura] = useState('');
    const [datanasc,setDatanasc] = useState('');
    const [dia_fatuta,setDia_fatura] = useState('');
    const [venc_cartao,setVenc_cartao] = useState('');
    const [cartao_proprio,setCartao_proprio] = useState('');
    const [senhacred,setSenhaCred] = useState('0000');
    const [nrend1,setNrend1] = useState('');
    const [nrend2,setNrende2] = useState('');
    const [e_mail,setE_mail] = useState('');
    const [cod_reg_trib,setCod_reg_tributatio] = useState('');
    const [tipocad,setTipocad] = useState(0);
    const [st_liminar,setSt_limiar] = useState('');
    const [complementar,setComplementar] = useState('');
    const [tabela_preco,setTabela_preco] = useState(0);
    const [id_convenio,setId_convenio] = useState(0);
    const [nr_convenio,setNr_convennio] = useState(0);
    const [cod_ctardz,setCod_ctardz] = useState(0);
    const [dia_corte,setDiacorte] = useState(0);
    const [dia_vencimento,setDiaVencimento] = useState(0);
    const [protestar,setProtestar] = useState('0');
    const [dias_protestar,setDias_protestar] = useState(0);
    const [cpais,setCpais] = useState('1058');
    const [trilha1,setTrilha1] = useState('');
    const [trilha2,setTrilha2] = useState('');
    const [trilha3,setTrilha3] = useState('');
    const [credUtilizado, setCredUtilizado] = useState(0);
    const [credDisponivel, setCredDisponivel] = useState(0);
    const [msgErro, setmsgErro] = useState('');
    const [erroValidacao, setErrovalidacao] = useState(false);
    const [isEditing, setIsEditing] = useState(false);
    const [cadastro, setCadastro] = useState([]);


    const [buscaCidade,setBuscaCidade] = useState('');
    const [Cidades,setCidades] = useState([]);
    const [sucesso,setSucesso] = useState('');
    const [mensagem,setMensagem]= useState('');
    const [showPassword, setShowPassword] = useState(false);

;  

  
    
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }
  
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
};
 

  async function buscarEnderecoPorCep(e) {
      e.preventDefault();
      if(cep === undefined )
        { setmsgErro("CEP inválido");
         setErrovalidacao(true)}
      else if(cep.length !== 8 )
        { setmsgErro("CEP deve conter 8 digitos");
         setErrovalidacao(true)} else{
    try {
      const url = `https://viacep.com.br/ws/${cep}/json/`;
      const response = await fetch(url);
      const data = await response.json();
      console.log(data);

      if(data.erro === 'true' )
        { setmsgErro("CEP não encontrado");
         setErrovalidacao(true)}
      

      if(data.erro === 'true'){
        setmsgErro('CEP informado não é válido');
        setErrovalidacao(true);
      } 
      
      
     
      
  
      if (response.status === 200) {
          
          setCidade(data.localidade)
          setEndereco(data.logradouro);
          setBairro(data.bairro);
          setEstado(data.uf);
          setCidade(data.localidade);
        }
       else {
        throw new Error(data.message);
      }
    } catch (error) {
      console.error(error);
    }
  }}

  useEffect(function(){

    api.post(vhostV1('webcash/cadastro'), {
       loja: localStorage.getItem('loja'),
       empresa: 0,
       pagina: 1,
       regPag: 50,
       tipo:'%',
       busca: ''
     },config)
     .then(function (response) {
       
       setCadastro(response.data.dados);
       console.log(response.data.dados);
       
       
                                           
                
     })
     .catch(function (error) {
      
       console.log(error);
       

               
     });

     
 },[])

 



  function listaCidades(e){
    e.preventDefault();
       
    api.post(vhostV1('webcash/cidade'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        sigla: estado,
        busca:buscaCidade
      },config)
      .then(function (response) {
        if(response.data.dados === undefined){
             setmsgErro('Cidade não encontrada')
             setErrovalidacao(true)

        }else{
          setCidades(response.data.dados);  
          console.log(response.data.dados);
        }
                                          
                 
      })
      .catch(function (error) {
       
        console.log(error);

                
      });
    }


    function escolheCidade(e){
      //console(e.substring(0,7));
      setCidade(e.substring(8,60));          
                     
    }

    //busca cadastro
    
    useEffect(function(){
       
      api.post(vhostV1('webcash/cadastro/'+id), {
          loja: localStorage.getItem('loja'),
          empresa: 0        
        },config)
        .then(function (response) {
            console.log(response);
            setTipocad(response.data.dados.TIPOCAD)
            setRazao(response.data.dados.RAZAO);
            setFantasia(response.data.dados.FANTASIA);
            setCnpj(response.data.dados.CNPJ);
            setEstadual(response.data.dados.ESTADUAL);
            setCae(response.data.dados.CAE);
            setinscMunicipal(response.data.dados.MUNICIPAL);
            setTelefone(response.data.dados.TELEFONE);
            setCelular(response.data.dados.CELULAR);
            setE_mail(response.data.dados.E_MAIL);
            setObs(response.data.dados.OBS);
            setCpais(response.data.dados.PAIS);
            setCep(response.data.dados.CEP);
            setEndereco(response.data.dados.ENDERECO);
            setBairro(response.data.dados.BAIRRO);
            setEstado(response.data.dados.ESTADO);
            setCidade(response.data.dados.CIDADE);
            setLimite(response.data.dados.LIMITE);
            setTipo_fatura(response.data.dados.TIPO_FATURA);
            setVenc_cartao(response.data.dados.DIA_VENCIMENTO);
            setDiacorte(response.data.dados.DIA_CORTE);
            setSenhaCred(response.data.dados.SENHACRED);   
            setCpais(response.data.dados.CPAIS);   
            setNrend1(response.data.dados.NREND1);   
            setComplementar(response.data.dados.COMPLEMENTAR);   
            if(response.data.result === 1){
              setIsEditing(true);
            }

            
                   
  
          }                               
        )
        .catch(function (error) {
          console.log(error);
                  
        });
             
    },[])
  

 


  function salvaPessoa(e){
    e.preventDefault();

   
    
    
   
      
       const jsonPessoa = {};
      
       
        jsonPessoa.loja=Number(localStorage.getItem('loja'));
        jsonPessoa.COD_CADASTRO=id;        
        jsonPessoa.EMPRESA=0;
        jsonPessoa.RAZAO=razao;
        jsonPessoa.FANTASIA=fantasia;
        jsonPessoa.CNPJ=cnpj;
        jsonPessoa.TIPO=tpc;
        jsonPessoa.ESTADUAL=estadual;        
        jsonPessoa.MUNICIPAL=inscMunicipal;
        jsonPessoa.CAE=cae;
        jsonPessoa.ENDERECO=endereco;
        jsonPessoa.BAIRRO=bairro;
        jsonPessoa.CEP=cep;
        jsonPessoa.CIDADE=cidade;
        jsonPessoa.ESTADO=estado;
        jsonPessoa.TELEFONE=telefone;
        jsonPessoa.FAX=fax;
        jsonPessoa.CELULAR=celular;
        jsonPessoa.CONTATO=contato;
        jsonPessoa.ENDCOB=endcob;
        jsonPessoa.BAIRROCOB=bairrocob;
        jsonPessoa.CEPCOB=cepcob;
        jsonPessoa.CIDADECOB=cidadecob;
        jsonPessoa.ESTADOCOB=estadocob;
        jsonPessoa.OBS=obs;
        jsonPessoa.ATIVIDADE=atividade;
        jsonPessoa.CORRESPONDENCIA=correspondencia;
        jsonPessoa.TRIBUTACAO=tributacao;
        jsonPessoa.COMISSAO=comissao;
        jsonPessoa.VENDEDOR=vendedor;
        jsonPessoa.REGISTRO=registro;
        jsonPessoa.DESLOCAMENTO=deslocamento;
        jsonPessoa.ATIVO=ativo;
        jsonPessoa.MULTDISTANCIA=multdistancia;
        jsonPessoa.MULTATIVIDADE=multdistancia;
        jsonPessoa.CADASTRADO_POR=cadastrado_por;
        jsonPessoa.CADASTRADO_EM=cadastrado_em;
        jsonPessoa.ALTERADO_POR=alterado_por;
        jsonPessoa.ALTERADO_EM=alterado_em;
        jsonPessoa.AREA=area;
        jsonPessoa.LIMITE=limite;
        jsonPessoa.ULTIMO_VENC=ultimo_venc;
        jsonPessoa.ATUAL_VENC=atual_venc;
        jsonPessoa.PRAZO=prazo;
        jsonPessoa.TIPO_FATURA=tipo_fatura;
        jsonPessoa.DATANASC=datanasc;
        jsonPessoa.DIA_FATUTA=dia_fatuta;
        jsonPessoa.VENC_CARTAO=venc_cartao;
        jsonPessoa.CARTAO_PROPRIO=cartao_proprio;
        jsonPessoa.SENHACRED=senhacred;
        jsonPessoa.NREND1=nrend1;
        jsonPessoa.NREND2=nrend2;
        jsonPessoa.E_MAIL=e_mail;
        jsonPessoa.COD_REG_TRIB=cod_reg_trib;
        jsonPessoa.TIPOCAD=tipocad;
        jsonPessoa.ST_LIMINAR=st_liminar;
        jsonPessoa.COMPLEMENTAR=complementar;
        jsonPessoa.TABELA_PRECO=tabela_preco;
        jsonPessoa.ID_CONVENIO=id_convenio;
        jsonPessoa.NR_CONVENIO=nr_convenio;
        jsonPessoa.COD_CTARDZ=cod_ctardz;
        jsonPessoa.DIA_CORTE=dia_corte;
        jsonPessoa.DIA_VENCIMENTO=dia_vencimento;
        jsonPessoa.PROTESTAR=protestar;
        jsonPessoa.DIAS_PROTESTAR=dias_protestar;
        jsonPessoa.CPAIS=cpais;
        jsonPessoa.TRILHA1=trilha1;
        jsonPessoa.TRILHA2=trilha2;
        jsonPessoa.TRILHA3=trilha3;

        console.log(jsonPessoa);

        if(razao.length === 0){
          setmsgErro('Nome nao pode estar vazio')
          setErrovalidacao(true)
       }else if(cnpj.length === 0 ){
        
           setmsgErro('CPF/CNPJ não pode estar vazio')
           setErrovalidacao(true)
         
         
       }else if(tipocad === '0' && cnpj.length !== 11) {
   
         setmsgErro('CPF deve conter 11 Numeros')
         setErrovalidacao(true)
       }else if(tipocad === '1' && cnpj.length < 14 || cnpj.length >14) {
   
         setmsgErro('CNPJ deve conter 14 Numeros')
         setErrovalidacao(true)
       }else if(cnpj === cadastro.CNPJ){
         setmsgErro('CPF/CNPJ já cadastrado')
         setErrovalidacao(true)
       } else{

        api.post(vhostV1('webcash/cadastro/incluir'),
            jsonPessoa
        ,config)
        .then(function(response){
            setMensagem('');
            setSucesso('S');
            console.log(response)
        }).catch((erro) =>{
            setMensagem(erro);
            console.log(erro);
            setmsgErro(erro);
            setErrovalidacao(true);
            setSucesso('N');
        });

       }
     }

  
     
     


     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div id="table-cliente" className="offset-lg-3 col-lg-6">
               <h2>Cadastro de Pessoa</h2>      
               <div className="row">
                    <h6>dados Basicos</h6>
                    
                    <div className="col-3">
                       <label htmlFor="inputState" className="form-label">Tipo</label>
                       <select id="inputEstado" value={tipocad} onChange={(e)=> setTipocad(e.target.value)} className="form-select" tabIndex="16" disabled={isEditing}>
                       <option value={0}>Física</option>       
                       <option value={1}>Jurídica</option>       
                       </select>
                   </div>
                   </div>
               <hr />
                <div className="conteiner-fluid">
                <form className="row tela">

                   
                   
                   {tipocad!=0? <div className="pessoa-juridica">
                       <div className="col-12">
                         <label htmlFor="inputAddress" className="form-label">RAZÃO</label>
                         <input type="text"  value={razao} onChange={(e)=> setRazao(e.target.value)} className="form-control letra-maiuscula" id="inputDescricao" tabIndex="1" required autoComplete="off"/>
                       </div>

                       <div className="col-12">
                         <label htmlFor="inputAddress" className="form-label">FANTASIA</label>
                         <input type="text" value={fantasia} onChange={(e)=> setFantasia(e.target.value)} className="form-control letra-maiuscula" id="inputDescricao" tabIndex="2" required autoComplete="off"/>
                       </div>
                       <div className="row">

                       <div className="col-3">
                         <label htmlFor="inputAddress" className="form-label">CNPJ</label>
                         <input type="text" value={cnpj} onChange={(e)=> setCnpj(e.target.value)} className="form-control" id="inputCodigo" tabIndex="3" placeholder="Digite apenas números" required autoComplete="off"/>
                       </div>
                        <div className="col-3">
                          <label htmlFor="inputAddress" className="form-label">INSC EST</label>
                          <input type="text" value={estadual} onChange={(e)=> setEstadual(e.target.value)} className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off"/>
                        </div>                        
                                    
                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">C.N.A.E</label>
                            <input type="text"  value={cae} onChange={(e)=> setCae(e.target.value)} className="form-control" id="inputCodigo" tabIndex="5" required autoComplete="off"/>
                        </div>

                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">INSC MUNICIPAL</label>
                            <input type="text" value={inscMunicipal} onChange={(e)=> setinscMunicipal(e.target.value)} className="form-control" id="inputCodigo" tabIndex="6" required autoComplete="off"/>
                        </div>              

                        </div>    
                        <div className="row">
                         <div className="col-3">
                           <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                           <input type="text" value={telefone} onChange={(e)=> setTelefone(e.target.value)} className="form-control" id="inputCodigo" tabIndex="7" required autoComplete="off"/>
                         </div>

                         <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">Celular/Whatsapp</label>
                            <input type="text" value={celular} onChange={(e)=> setCelular(e.target.value)} className="form-control" id="inputCodigo" tabIndex="8" required autoComplete="off"/>
                         </div>

                         <div className="col-6">
                            <label htmlFor="inputAddress" className="form-label">EMAIL</label>
                            <input type="text" value={e_mail} onChange={(e)=> setE_mail(e.target.value)} className="form-control letra-maiuscula" id="inputCodigo" tabIndex="9" required autoComplete="off"/>
                         </div>

                         
                            
                        </div>  

                       </div>:<div className="pessoa-fisica">
                         <div className="col-12">
                           <label htmlFor="inputAddress" className="form-label">NOME</label>
                           <input type="text" value={razao} onChange={(e)=> setRazao(e.target.value)} className="form-control letra-maiuscula" id="inputDescricao" tabIndex="1" required autoComplete="off"/>
                         </div>                      
                         <div className="row">
                         <div className="col-3">
                           <label htmlFor="inputAddress" className="form-label">CPF</label>
                           <input type="text" value={cnpj} onChange={(e)=> setCnpj(e.target.value)} className="form-control" id="inputCodigo" tabIndex="2" placeholder="Digite apenas números" required autoComplete="off"/>
                         </div>
                         <div className="col-3">
                           <label htmlFor="inputAddress" className="form-label">RG</label>
                           <input type="text" value={estadual} onChange={(e)=> setEstadual(e.target.value)} className="form-control" id="inputCodigo" tabIndex="3" required autoComplete="off"/>
                         </div>
                         <div className="col-3">
                          <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                          <input type="text" value={telefone} onChange={(e)=> setTelefone(e.target.value)} className="form-control" id="inputCodigo" tabIndex="4" required autoComplete="off"/>
                         </div>
                         <div className="col-3">
                           <label htmlFor="inputAddress" className="form-label">Celular/Whatsapp</label>
                           <input type="text" value={celular} onChange={(e)=> setCelular(e.target.value)} className="form-control" id="inputCodigo" tabIndex="5"  autoComplete="off"/>
                         </div>

                         <div className="col-12">
                            <label htmlFor="inputAddress" className="form-label">EMAIL</label>
                            <input type="text" value={e_mail} onChange={(e)=> setE_mail(e.target.value)} className="form-control letra-maiuscula" id="inputCodigo" tabIndex="6" autoComplete="off"/>
                         </div>

                        </div>

                   </div> }   

                    <div className="col-12">
                      <label htmlFor="inputAddress" className="form-label">OBS</label>
                      <input type="text" value={obs} onChange={(e)=> setObs(e.target.value)} className="form-control letra-maiuscula" id="inputCodigo" tabIndex="7" required autoComplete="off"/>
                    </div>                 


                   <h6>endereço</h6>
                   <hr />
                   <div className="col-md-3">
                       <label htmlFor="inputState" className="form-label">PAÍS</label>
                       <select id="inputUnidade" value={cpais} onChange={(e)=> setCpais(e.target.value)} className="form-select" tabIndex="8" >
                       {pais.map((p) => {
                          return <option key={p.codigo} value={p.codigo}>{p.nome}</option>})}
            
                       </select>
                   </div>
                   
                   <div className="col-md-3">
                   <label htmlFor="inputAddress" className="form-label">CEP</label> 
                   <div className="input-group mb-3">
                        <input type="text" value={cep}  onChange={(e)=> setCep(e.target.value)} className="form-control" id="inputNcm" tabIndex="9" />
                        <button onClick={buscarEnderecoPorCep} className="btn btn-primary" type="button"><i className="fas fa-search"></i></button>
                    </div>                
                    </div>
       

                   <div className="col-6">
                       <label htmlFor="inputAddress" className="form-label">ENDEREÇO</label>
                       <input type="text" value={endereco} onChange={(e)=> setEndereco(e.target.value)} className="form-control letra-maiuscula" id="inputValor" tabIndex="10" autoComplete="off"/>
                   </div>
                   <div className="col-2">
                       <label htmlFor="inputAddress" className="form-label">NUMERO</label>
                       <input type="text" value={nrend1} onChange={(e)=> setNrend1(e.target.value)} className="form-control" id="inputValor" tabIndex="11" autoComplete="off"/>
                   </div>

                   <div className="col-10">
                       <label htmlFor="inputAddress" className="form-label">COMPLEMENTO</label>
                       <input type="text" value={complementar} onChange={(e)=> setComplementar(e.target.value)} className="form-control letra-maiuscula" id="inputValor" tabIndex="12" autoComplete="off"/>
                   </div>
                   <div className="col-4">
                       <label htmlFor="inputAddress" className="form-label">BAIRRO</label>
                       <input type="text" value={bairro} onChange={(e)=> setBairro(e.target.value)} className="form-control" id="inputValor" tabIndex="13" autoComplete="off"/>
                   </div>
                   <div className="col-md-2">
                       <label htmlFor="inputState" className="form-label">ESTADO</label>
                       <select id="inputEstado"  value={estado} onChange={(e)=> setEstado(e.target.value)} className="form-select" tabIndex="14">
                       {estados.map((uf,i) => {
                          return <option key={i} value={uf.sigla}>{uf.sigla}</option>   })}
                       </select>
                   </div>
                       <div className="col-md-6">
                       <label htmlFor="inputCity" className="form-label">CIDADE</label>
                        <div className="input-group mb-3">
                           <input type="text" value={cidade}   onChange={(e)=> setCidade(e.target.value)} className="form-control letra-maiuscula" id="inputNcm" tabIndex="15" disabled/>
                            <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                            pesquisar
                            </button>
                        </div>

                        <div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false"  aria-labelledby="staticBackdropLabel" aria-hidden="true">
                         <div className="modal-dialog modal-lg">
                            <div className="modal-content">
                            <div className="modal-header">
                                <h1 className="modal-title fs-5" id="staticBackdropLabel">Pesquisa Cidade</h1>
                                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                            </div>
                            <div className="modal-body">
                                <div >
                                <div className="input-group mb-3">
                                  <input onChange={(e) => setBuscaCidade(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
                                  <button onClick={listaCidades} className="btn btn-primary" type="button" id="button-addon2"><i className="fas fa-search"></i> Pesquisar</button>
                                </div>

                                <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Cidade</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                { Cidades.map((city)=> {
                                    return <tr key={city.COD_MUN}>
                                        <td>{city.COD_MUN}</td>          
                                        <td>{city.NOME_MUNICIPIO}</td>
                                        <td>
                                            <a href="" onClick={()=> escolheCidade(city.COD_MUN +'-'+city.NOME_MUNICIPIO)} >   
                                            <i className="fas fa-edit icone-acao red" data-bs-dismiss="modal"></i>
                                          </a>                                        
                                        </td>
                                    </tr>
                                } )}  
                                </tbody>
                            </table>


                                </div>
                            </div>
                            <div className="modal-footer">
                                
                            </div>
                            </div>
                        </div>
                        </div>
                        
                      </div>
                    
                   <h6>Financeiro</h6>
                   <hr />
                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">LIMITE DE CREDITO</label>
                       <input type="text" value={limite} onChange={(e)=> setLimite(e.target.value)} className="form-control" id="inputCompra" tabIndex="16" required autoComplete="off"/>
                   </div>  

                   <div className="col-md-3">
                       <label htmlFor="inputState" className="form-label">TIPO FATURA</label>
                       <select id="inputEstado" value={tipo_fatura} onChange={(e)=> setTipo_fatura(e.target.value)} className="form-select" tabIndex="17">
                       <option>FATURA</option>       
                       <option>VENCIMENTO</option>                                     
                       </select>
                   </div>
                   <div className="col-3">
                       <label htmlFor="inputAddress"  className="form-label">DIA VENCIMENTO</label>
                       <input type="text" value={dia_vencimento} onChange={(e)=> setDiaVencimento(e.target.value)} className="form-control" id="inputCompra" tabIndex="18" required autoComplete="off"/>
                   </div>  
                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">DIA CORTE</label>
                       <input type="text" value={dia_corte} onChange={(e)=> setDiacorte(e.target.value)} className="form-control" id="inputCompra" tabIndex="19" required autoComplete="off"/>
                   </div>  
                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">SENHA</label>
                       <input  type='password' value={senhacred} onChange={(e)=> setSenhaCred(e.target.value)} className="form-control" id="inputCompra" tabIndex="20"  autoComplete="off"/>
                       
                   </div>  
                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">CRED UTILIZADO</label>
                       <input type="text" value={credUtilizado} onChange={(e) => setCredUtilizado(e.target.value)}  className="form-control" id="inputCompra"  required autoComplete="off" readOnly/>
                   </div>  

                   <div className="col-3">
                       <label htmlFor="inputAddress" className="form-label">CRED DISPONIVEL</label>
                       <input type="text" value={credDisponivel} onChange={(e) => setCredDisponivel(e.target.value)} className="form-control" id="inputCompra"  required autoComplete="off" readOnly/>
                   </div>  





                   
                   
                   <div className="col-12 botao">
                   <hr />
                      <Link to={"/app/cadastro/"+tpc} onClick={() => setTipocad(0)} type="submit" className="btn btn-outline-primary butao"  >Desistir</Link>
                      {tag != 0?<button type="submit" onClick={salvaPessoa} className="btn btn-primary butao" >Salvar</button>:null}                     
                     
                   </div>
                   
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? <Navigate to={"/app/cadastro/"+tpc} /> : null}

                   
       
                   </form> 
            </div>
            {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
        </div>  
   </div>

}



export default NovoCadastro;