import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function ContasPdf(contas){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Relatorio de Contas',
            fontSize: 15,
            alignment:'center',
            bold: true,
            margin: [15, 20, 0, 45] // left, top, right, bottom
        }
    ];
    
    const dados = contas.map((conta) => {
        return [
            {text: conta.SEQ, fontSize: 7, margin: [0, 2, 0, 2]},
            {text: conta.RAZAO, fontSize: 7, margin: [0, 2, 0, 2]},
            {text: conta.DATA_VENC, fontSize: 7, margin: [0, 2, 0, 2],alignment:'left'},
            {text: 'R$ '+Number(conta.VALOR).toFixed(2), fontSize: 7, margin: [0, 2, 2, 2],alignment:'left'},
            {text: 'R$ '+Number(conta.VALOR_PAGO).toFixed(2), fontSize: 7, margin: [0, 2, 3, 2],alignment:'left'}
           
        ] 
    });

    const details = [
        {
            table:{
                headerRows: 1,
                widths: [55, 230, 60, 40,40,35],
                body: [
                    [
                        {text: 'Código', style: 'tableHeader', fontSize: 10},
                        {text: 'Nome', style: 'tableHeader', fontSize: 10},
                        {text: 'vencimento', style: 'tableHeader', fontSize: 10},
                        {text: 'valor', style: 'tableHeader', fontSize: 10},
                        {text: 'saldo', style: 'tableHeader', fontSize: 10}
                       
                    ],
                    ...dados
                ]
            },
            layout: 'lightHorizontalLines' // headerLineOnly
        }
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitios).open();
}

export default ContasPdf;