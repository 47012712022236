import React, { useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalProduto.css'
import BuscaVazaia from '../buscavazia/buscaVazia';
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';


function ModalProduto({isOpen,onRequestClose,setCodProduto,setDescricao,setVendaDefault}){

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  const [produtos,setProdutos] = useState([]);
  const [busca,setBusca] = useState('');

  const diferenteZero = false;
  const estoqueNegativo = false;
  const vendaZerada = false;
  const prodInativo =false;

  function filtraProdutos(){
  const sJson = {}
  sJson.loja=localStorage.getItem('loja'); 
  sJson.empresa=0;
  sJson.grupo=0;
  sJson.subgrupo=0;
  sJson.linha=0;
  sJson.estoque_diferente=diferenteZero
  sJson.estoque_negativo=estoqueNegativo;
  sJson.preco_zerado=vendaZerada;
  sJson.inativo=prodInativo;
  sJson.pagina= 1;
  sJson.regPag=10;
  sJson.busca= busca; 
  sJson.tipoBusca=0;      
  
    api.post(vhostV1('webcash/produto/listar'),sJson,config)
      .then(function (response) {
          if(response && response.data && response.data.dados ){
            console.log(response.data.dados)
            setProdutos(response.data.dados);  
          }else{ 
          setProdutos([]);  
      }
                                    
      })
      .catch(function (error) {
        console.log(error);                  
      });
        
}              



  function handelProduto(prod){
     setCodProduto(prod.COD_PRODUTO);
     setDescricao(prod.DESCRICAO);
     setVendaDefault(Number(prod.VENDA));
     onRequestClose();

  }



  return <Modal isOpen={isOpen}
                onRequestClose={onRequestClose}
                overlayClassName="react-modal-overlay"
                ariaHideApp={false}
                className="react-modal-content">
          <div className="container-fluid pesquisa-modal">
            <div className="row">
                <h4>Pesquisa Produtos</h4>              
                <hr />
                
                <div className="col-12">
                <div className="input-group mb-3">
                <input onChange={(e) => setBusca(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
                <button onClick={(e) => filtraProdutos(e.target.value)} value={busca}  className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
                
               
                </div>
                </div>       
            </div> 

           <div className="row produtos">
           
           { produtos.length > 0?
                                <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Estoque</th>
                                    <th scope="col">Ação</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                { produtos.map((prod)=> {
                                    return <tr key={prod.COD_PRODUTO}>
                                        <td>{prod.COD_PRODUTO}</td>          
                                        <td>{prod.DESCRICAO}</td>
                                        <td>{ Number(prod.ESTOQUE).toFixed(3)}</td>
                                        <td>
                                          <a  onClick={(e) => handelProduto(prod)}>
                                          <i class="bi bi-file-arrow-down-fill"></i>
                                          </a>                                        
                                        </td>
                                    </tr>
                                } )}  
                                </tbody>
                            </table>:<BuscaVazaia/>}

           </div>
            
           <div className="row align-items-end footer">
                <button className='btn btn-danger botao-fechar'onClick={onRequestClose}>Cancelar</button>
            </div>
            
          </div>
    

    
  </Modal>
}

export default ModalProduto;