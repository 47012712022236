import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import React from 'react';

// Configura as fontes
pdfMake.vfs = pdfFonts.pdfMake.vfs;

const VendaDashboardPdf = ({ finalizadoraVenda, tiket, rankVendedor, rankingProduto, totalDia, qtdVenda }) => {
    
    const reportTitle = {
        text: 'Relatório de Vendas - Dashboard',
        fontSize: 15,
        alignment: 'center',
        bold: true,
        margin: [20, 20, 20, 20] // left, top, right, bottom
    };

    // Verifica se finalizadoraVenda, rankingProduto e rankVendedor são arrays e não são nulos
    const finalizadoraVendaBody = finalizadoraVenda.length ? 
        finalizadoraVenda.map(item => [item.finalizadora || 'N/A', item.valor_final || 'N/A']) : 
        [['Nenhuma venda encontrada', '']];

    const rankingProdutoBody = rankingProduto.length ? 
        rankingProduto.map(item => [item.descricao || 'N/A', item.qtd || 'N/A']) : 
        [['Nenhum produto encontrado', '']];

    const totalQuantidade = rankingProduto.reduce((acc, item) => acc + (parseInt(item.qtd, 10) || 0), 0);
    const rankingProdutoBodyWithTotal = rankingProduto.length ? 
        [...rankingProduto.map(item => [item.descricao || 'N/A', item.qtd || 'N/A']), 
        ['Total de Produtos', totalQuantidade.toString()]] : 
        [['Nenhum produto encontrado', '']];    

    const rankVendedorBody = rankVendedor.length ? 
        rankVendedor.map(item => [item.cod_colaborador || 'N/A', item.nome || 'N/A', item.total || 'N/A', item.qtd || 'N/A']) : 
        [['Nenhum vendedor encontrado', '']];

    const details = [
        { text: 'Vendas por Finalizadora:', style: 'subheader', margin: [0, 0, 0, 10] },
        {
            table: {
                headerRows: 1,
                body: [
                    ['Finalizadora', 'Vendas(R$)'],
                    ...finalizadoraVendaBody
                ]
            },
           
            margin: [0, 0, 0, 20]
        },
        { text: 'Vendas por Produto:', style: 'subheader', margin: [0, 0, 0, 10]  },
        {
            table: {
                headerRows: 1,
                body: [
                    ['Produto', 'Quantidade'],
                    ...rankingProdutoBodyWithTotal
                ]
            },
            margin: [0, 0, 0, 20] 
        },
        { text: 'Ranking de Vendedores:', style: 'subheader', margin: [0, 0, 0, 10]  },
        {
            table: {
                headerRows: 1,
                body: [
                    ['Código', 'Vendedor', 'Total(R$)', 'Vendas'],
                    ...rankVendedorBody
                ]
            },
            margin: [0, 0, 0, 20] 
        },
        {text: 'Informações adicionais:', style: 'subheader', margin: [0, 0, 0, 10]},
        {
            table: {
                headerRows: 0,
                body: [
                    [
                        { text: `Total do dia: R$ ${totalDia || 'N/A'}`, border: [true, true, true, true] }, 
                        { text: `Ticket Médio: R$ ${tiket.toFixed(2) || 'N/A'}`, border: [true, true, true, true] },
                        { text: `Cupons Emitidos: ${qtdVenda || 'N/A'}`, border: [true, true, true, true]}
                    ]
                ],
                widths: ['33%', '34%', '33%']
            },
            
            margin: [0, 0, 0, 20] // Adiciona margem inferior
        }
    ];

    

    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],
        header: [reportTitle],
        content: details,
        
    };

    pdfMake.createPdf(docDefinitions).open();
};

export default VendaDashboardPdf;

