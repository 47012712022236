const pais=[
    {codigo:"0132",nome:"Afeganistao"},
    {codigo:"0175",nome:"Albania"},
    {codigo:"0230",nome:"Alemanha"},
    {codigo:"0310",nome:"BurkinaFaso"},
    {codigo:"0370",nome:"Andorra"},
    {codigo:"0400",nome:"Angola"},
    {codigo:"0418",nome:"Anguilla"},
    {codigo:"0434",nome:"AntiguaeBarbuda"},
    {codigo:"0477",nome:"AntilhasHolandesas"},
    {codigo:"0531",nome:"ArabiaSaudita"},
    {codigo:"0590",nome:"Argelia"},
    {codigo:"0639",nome:"Argentina"},
    {codigo:"0647",nome:"Armenia"},
    {codigo:"0655",nome:"Aruba"},
    {codigo:"0698",nome:"Australia"},
    {codigo:"0728",nome:"austria"},
    {codigo:"0736",nome:"Azerbaijao"},
    {codigo:"0779",nome:"Bahamas,Ilhas"},
    {codigo:"0809",nome:"Bahrein,Ilhas"},
    {codigo:"0817",nome:"Bangladesh"},
    {codigo:"0833",nome:"Barbados"},
    {codigo:"0850",nome:"Belarus"},
    {codigo:"0876",nome:"Belgica"},
    {codigo:"0884",nome:"Belize"},
    {codigo:"0906",nome:"Bermudas"},
    {codigo:"0930",nome:"Mianmar(Birmania)"},
    {codigo:"0973",nome:"Bolivia"},
    {codigo:"0981",nome:"Bosnia,"},
    {codigo:"1015",nome:"Botsuana"},
    {codigo:"1058",nome:"Brasil"},
    {codigo:"1082",nome:"Brunei"},
    {codigo:"1112",nome:"Bulgaria"},
    {codigo:"1155",nome:"Burundi"},
    {codigo:"1198",nome:"Butao"},
    {codigo:"1279",nome:"CaboVerde,Republicade"},
    {codigo:"1376",nome:"Cayman,Ilhas"},
    {codigo:"1414",nome:"Camboja"},
    {codigo:"1457",nome:"Camaroes"},
    {codigo:"1490",nome:"Canada"},
    {codigo:"1504",nome:"Guernsey,IlhaDoCanal(IncluiAlderney)"},
    {codigo:"1508",nome:"Jersey,IlhadoCanal"},
    {codigo:"1511",nome:"Canarias,Ilhas"},
    {codigo:"1538",nome:"Cazaquistao"},
    {codigo:"1546",nome:"Catar"},
    {codigo:"1589",nome:"Chile"},
    {codigo:"1600",nome:"China"},
    {codigo:"1619",nome:"Formosa(Taiwan)"},
    {codigo:"1635",nome:"Chipre"},
    {codigo:"1651",nome:"Cocos(Keeling),Ilhas"},
    {codigo:"1694",nome:"Colombia"},
    {codigo:"1732",nome:"Comores,Ilhas"},
    {codigo:"1775",nome:"Congo"},
    {codigo:"1830",nome:"Cook,Ilhas"},
    {codigo:"1872",nome:"CoreiadoNorte"},
    {codigo:"1902",nome:"CoreiadoSul"},
    {codigo:"1937",nome:"CostadoMarfim"},
    {codigo:"1953",nome:"Croacia"},
    {codigo:"1961",nome:"CostaRica"},
    {codigo:"1988",nome:"Coveite(Kuwait)"},
    {codigo:"2291",nome:"Benin"},
    {codigo:"2321",nome:"Dinamarca"},
    {codigo:"2356",nome:"Dominica,Ilha"},
    {codigo:"2399",nome:"Equador"},
    {codigo:"2402",nome:"Egito"},
    {codigo:"2437",nome:"Eritreia"},
    {codigo:"2445",nome:"EmiradosarabesUnidos"},
    {codigo:"2453",nome:"Espanha"},
    {codigo:"2461",nome:"Eslovenia"},
    {codigo:"2470",nome:"Eslovaca,Republica"},
    {codigo:"2496",nome:"EstadosUnidos"},
    {codigo:"2518",nome:"Estonia"},
    {codigo:"2534",nome:"Etiopia"},
    {codigo:"2550",nome:"Falkland(IlhasMalvinas)"},
    {codigo:"2674",nome:"Filipinas"},
    {codigo:"2712",nome:"Finlandia"},
    {codigo:"2755",nome:"França"},
    {codigo:"2810",nome:"Gabao"},
    {codigo:"2852",nome:"Gambia"},
    {codigo:"2895",nome:"Gana"},
    {codigo:"2917",nome:"Georgia"},
    {codigo:"2933",nome:"Gibraltar"},
    {codigo:"2976",nome:"Granada"},
    {codigo:"3018",nome:"Grecia"},
    {codigo:"3050",nome:"Groenlandia"},
    {codigo:"3093",nome:"Guadalupe"},
    {codigo:"3131",nome:"Guam"},
    {codigo:"3255",nome:"GuianaFrancesa"},
    {codigo:"3298",nome:"Guine"},
    {codigo:"3310",nome:"Guine"},
    {codigo:"3344",nome:"Guine"},
    {codigo:"3379",nome:"Guiana"},
    {codigo:"3417",nome:"Haiti"},
    {codigo:"3450",nome:"Honduras"},
    {codigo:"3514",nome:"HongKong"},
    {codigo:"3557",nome:"Hungria"},
    {codigo:"3573",nome:"Iemen"},
    {codigo:"3595",nome:"Man,Ilhade"},
    {codigo:"3611",nome:"india"},
    {codigo:"3654",nome:"Indonesia"},
    {codigo:"3697",nome:"Iraque"},
    {codigo:"3727",nome:"Ira"},
    {codigo:"3794",nome:"Islandia"},
    {codigo:"3832",nome:"Israel"},
    {codigo:"3867",nome:"Italia"},
    {codigo:"3913",nome:"Jamaica"},
    {codigo:"3964",nome:"Johnston,Ilhas"},
    {codigo:"3999",nome:"Japao"},
    {codigo:"4030",nome:"Jordania"},
    {codigo:"4111",nome:"Kiribati"},
    {codigo:"4200",nome:"Laos"},
    {codigo:"4235",nome:"Lebuan,Ilhas"},
    {codigo:"4260",nome:"Lesoto"},
    {codigo:"4278",nome:"Letonia"},
    {codigo:"4316",nome:"Libano"},
    {codigo:"4340",nome:"Liberia"},
    {codigo:"4405",nome:"Liechtenstein"},
    {codigo:"4421",nome:"Lituania"},
    {codigo:"4456",nome:"Luxemburgo"},
    {codigo:"4472",nome:"Macau"},
    {codigo:"4499",nome:"Macedonia(ant.Rep.Iugoslava)"},
    {codigo:"4502",nome:"Madagascar"},
    {codigo:"4525",nome:"Madeira,Ilhada"},
    {codigo:"4553",nome:"Malasia"},
    {codigo:"4588",nome:"Malavi"},
    {codigo:"4618",nome:"Maldivas"},
    {codigo:"4642",nome:"Mali"},
    {codigo:"4677",nome:"Malta"},
    {codigo:"4723",nome:"MarianasdoNorte"},
    {codigo:"4740",nome:"Marrocos"},
    {codigo:"4774",nome:"Martinica"},
    {codigo:"4855",nome:"Mauricio"},
    {codigo:"4880",nome:"Mauritania"},
    {codigo:"4901",nome:"Midway,Ilhas"},
    {codigo:"4936",nome:"Mexico"},
    {codigo:"4944",nome:"Moldavia"},
    {codigo:"4952",nome:"Monaco"},
    {codigo:"4979",nome:"Mongolia"},
    {codigo:"4985",nome:"Montenegro"},
    {codigo:"4995",nome:"Micronesia"},
    {codigo:"5010",nome:"Montserrat,Ilhas"},
    {codigo:"5053",nome:"Moçambique"},
    {codigo:"5070",nome:"Namibia"},
    {codigo:"5088",nome:"Nauru"},
    {codigo:"5118",nome:"Christmas,Ilha(Navidad)"},
    {codigo:"5177",nome:"Nepal"},
    {codigo:"5258",nome:"Niger"},
    {codigo:"5282",nome:"Nigeria"},
    {codigo:"5312",nome:"Niue,Ilha"},
    {codigo:"5355",nome:"Norfolk,Ilha"},
    {codigo:"5380",nome:"Noruega"},
    {codigo:"5428",nome:"NovaCaledonia"},
    {codigo:"5452",nome:"PapuaNovaGuine"},
    {codigo:"5487",nome:"NovaZelandia"},
    {codigo:"5517",nome:"Vanuatu"},
    {codigo:"5568",nome:"Oma"},
    {codigo:"5665",nome:"Pacifico,Ilhasdo(possessaodosEUA)"},
    {codigo:"5738",nome:"PaisesBaixos(Holanda)"},
    {codigo:"5754",nome:"Palau"},
    {codigo:"5762",nome:"Paquistao"},
    {codigo:"5800",nome:"Panama"},
    {codigo:"5894",nome:"Peru"},
    {codigo:"5932",nome:"Pitcairn,Ilha"},
    {codigo:"5991",nome:"PolinesiaFrancesa"},
    {codigo:"6033",nome:"Polonia"},
    {codigo:"6076",nome:"Portugal"},
    {codigo:"6114",nome:"PortoRico"},
    {codigo:"6238",nome:"Quenia"},
    {codigo:"6254",nome:"Quirguiz,Republica"},
    {codigo:"6289",nome:"ReinoUnido"},
    {codigo:"6408",nome:"RepublicaCentro"},
    {codigo:"6475",nome:"RepublicaDominicana"},
    {codigo:"6602",nome:"Reuniao,Ilha"},
    {codigo:"6653",nome:"Zimbabue"},
    {codigo:"6700",nome:"Romenia"},
    {codigo:"6750",nome:"Ruanda"},
    {codigo:"6769",nome:"Russia"},
    {codigo:"6777",nome:"Salomao,Ilhas"},
    {codigo:"6781",nome:"SaintKittsENevis"},
    {codigo:"6858",nome:"SaaraOcidental"},
    {codigo:"6874",nome:"ElSalvador"},
    {codigo:"6904",nome:"Samoa"},
    {codigo:"6912",nome:"SamoaAmericana"},
    {codigo:"6955",nome:"SaoCristovaoeNeves,Ilhas"},
    {codigo:"6971",nome:"SanMarino"},
    {codigo:"7005",nome:"SaoPedroeMiquelon"},
    {codigo:"7056",nome:"SaoVicenteeGranadinas"},
    {codigo:"7102",nome:"SantaHelena"},
    {codigo:"7153",nome:"SantaLucia"},
    {codigo:"7200",nome:"SaoTomeePrincipe,Ilhas"},
    {codigo:"7285",nome:"Senegal"},
    {codigo:"7358",nome:"SerraLeoa"},
    {codigo:"7370",nome:"Servia"},
    {codigo:"7412",nome:"Cingapura"},
    {codigo:"7447",nome:"Siria"},
    {codigo:"7480",nome:"Somalia"},
    {codigo:"7501",nome:"SriLanka"},
    {codigo:"7544",nome:"Suazilandia"},
    {codigo:"7560",nome:"africadoSul"},
    {codigo:"7595",nome:"Sudao"},
    {codigo:"7641",nome:"Suecia"},
    {codigo:"7676",nome:"Suiça"},
    {codigo:"7706",nome:"Suriname"},
    {codigo:"7722",nome:"Tadjiquistao"},
    {codigo:"7765",nome:"Tailandia"},
    {codigo:"7803",nome:"Tanzania"},
    {codigo:"7820",nome:"TerritorioBrit.Oc.Indico"},
    {codigo:"7838",nome:"Djibuti"},
    {codigo:"7889",nome:"Chade"},
    {codigo:"7919",nome:"Tcheca,Republica"},
    {codigo:"7951",nome:"TimorLeste"},
    {codigo:"8001",nome:"Togo"},
    {codigo:"8052",nome:"Toquelau,Ilhas"},
    {codigo:"8150",nome:"TrinidadeTobago"},
    {codigo:"8206",nome:"Tunisia"},
    {codigo:"8230",nome:"TurcaseCaicos,Ilhas"},
    {codigo:"8249",nome:"Turcomenistao"},
    {codigo:"8273",nome:"Turquia"},
    {codigo:"8281",nome:"Tuvalu"},
    {codigo:"8311",nome:"Ucrania"},
    {codigo:"8338",nome:"Uganda"},
    {codigo:"8451",nome:"Uruguai"},
    {codigo:"8478",nome:"Uzbequistao"},
    {codigo:"8486",nome:"Vaticano,EstadoCidade"},
    {codigo:"8508",nome:"Venezuela"},
    {codigo:"8583",nome:"Vietna"},
    {codigo:"8664",nome:"Virgens,Ilhas(E.U.A.)"},
    {codigo:"8702",nome:"Fiji"},
    {codigo:"8737",nome:"Wake,Ilha"},
    {codigo:"8885",nome:"Congo"},
    {codigo:"8907",nome:"Zambia"},
    {codigo:"9903",nome:"Provisaodenavioseaeronaves"},
    {codigo:"9950",nome:"BancosCentrais"},
    {codigo:"9970",nome:"OrganizaçoesInternacionais"},
]

export default pais;