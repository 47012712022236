import React from 'react';

function ObterMesAtual() {
  // Obtém o mês atual
  const obterMesAtual = () => {
    const meses = [
      'Janeiro', 'Fevereiro', 'Março', 'Abril',
      'Maio', 'Junho', 'Julho', 'Agosto',
      'Setembro', 'Outubro', 'Novembro', 'Dezembro'
    ];
    
    const dataAtual = new Date();
    const mesAtual = dataAtual.getMonth(); // Retorna um número de 0 a 11

    return meses[mesAtual];
  }

  const mesAtual = obterMesAtual();

  return (
    `${mesAtual}`
  );
}

export default ObterMesAtual;
