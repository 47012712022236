import React from "react";
import imgVazia from '../../../images/imgVazia.jpg';
import "./buscaVazia.css";

function BuscaVazaia(){

   return <div className="parent"> 
           <p><i className="bi bi-search"> </i></p>          
          
          <h1>Sem Resultado</h1>
    
    
    </div>
   } 

export default BuscaVazaia;