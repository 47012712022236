import React,{useState} from "react";
import Navbar from "../../navbar/navbar";
import { api } from "../../../services/api";
import { Link,Navigate, useParams } from 'react-router-dom'
import { useEffect } from "react";
import { vhostV1 } from "../../funcoes";
import SweetAlert from "react-bootstrap-sweetalert";


function NovaFinalizadora() {

    const {tag} =useParams();
    const {id} =useParams();


    const [descricaoV, setDescricao] =useState('');
    const [finalizadoraV, setFinalizadora] = useState([]);
   const [tefV, setTef] = useState(0);
   const [conta_bancariaV, setConta_bancaria] = useState(0);
   const [prazoV, setPrazo] = useState(0);
   const [descontoV, setDesconto] = useState(0);
   const [nr_parcelasV, setNr_parcelas] = useState(0);
   const [tab_precoV,setTab_preco] = useState(0);
   const [jurosV, setJuros] = useState(0);
   const [vlr_minimo_opV, setValor_minimo_op] = useState(0);
   const [duplicataV, setDuplicata] = useState("F");
   const [cartaoV, setCartao] = useState(0);
   const [sinteticoV, setSintetico] = useState('');
   const [analiticoV, setAnalitico] = useState('');
   const [desc_impV, setDesc_imp] = useState('dinheiro');
   const [permit_trocoV, setPermit_troco] = useState('N');
   const [cod_cedenteV, setCod_cedente]= useState(0);
   const [cadastrado_por,setCadastrado_Por] = useState(99);
   const [alterado_por,setAlterado_Por] = useState(99);
   const [mobile, setMobile] = useState(false);
   const [planoAnalitico, setPlanoAnalitico] = useState([]);
   const [planoSintetico, setPlanoSintetico] = useState([]);

   const [sucesso,setSucesso] = useState('N')
   const [mensagem, setMensagem] = useState('');
   const [sResult, setSresult] = useState(0);
   const [erroValidacao, setErrovalidacao] = useState(false);
   const [msgErro, setmsgErro] = useState('');
   const [busca, setBusca] = useState('');

   var data = new Date();

   const dataAtual = data.toSRIFormatString();
  
   console.log('data formatada:', dataAtual); //2022

   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  console.log('id: ' + id);
  

  useEffect(function(){

    api.get(vhostV1('webcash/finalizadora/RetornaFinalizadora/'+id),config)
    .then(function(response){
      if (response && response.data && response.data.dados){
        console.log(response.data.dados)
        setFinalizadora(response.data.dados.COD_FINALIZADORA);
        setDescricao(response.data.dados.DESCRICAO);
        setTef(response.data.dados.TEF);
        setConta_bancaria(response.data.dados.CONTA_BANCARIA);
        setPrazo(response.data.dados.PRAZO);
        setDesconto(response.data.dados.DESCONTO);
        setNr_parcelas(response.data.dados.NR_PARCELAS);
        setTab_preco(response.data.dados.TAB_PRECO);
        setJuros(response.data.dados.JUROS);
        setValor_minimo_op(response.data.dados.VLR_MINIMO_OP);
        setDuplicata(response.data.dados.DUPLICATA);
        setCartao(response.data.dados.CARTAS);
        setSintetico(response.data.dados.SINTETICO);
        setAnalitico(response.data.dados.ANALITICO);
        setDesc_imp(response.data.dados.DESC_IMP);
        setPermit_troco(response.data.dados.PERMIT_TROCO);
        setCod_cedente(response.data.dados.COD_CEDENTE);
      }
    })
  },[])

  
  


 
 
  function SalvaFinalizadora(){

    {if(descricaoV.length >30 )
      { setmsgErro("Descrição do banco maior que permitido");
       setErrovalidacao(true)}
     else if(desc_impV.length >50)
     { setmsgErro("desconto imp maior que permitido");
     setErrovalidacao(true)}
    
     
      else {      
    
     // fim do valida tamanhos

         //   e.preventDefault(e);
      api.post(vhostV1('webcash/finalizadora/inserirFinalizadora'),{
                loja: localStorage.getItem('loja'),
                finalizadora:-1,
                descricao:descricaoV.toUpperCase(),
                tef:tefV,
                conta_bancaria:conta_bancariaV,
                prazo:prazoV,
                desconto:descontoV,
                nr_parcelas:nr_parcelasV,
                tab_preco:tab_precoV,
                juros:jurosV,
                vlr_minimo_op:vlr_minimo_opV,
                duplicata:duplicataV,
                cartao:cartaoV,
                sintetico:Number(sinteticoV),
                analitico:Number(analiticoV),
                desc_imp:desc_impV,
                permit_troco:permit_trocoV,
                cod_cedente:cod_cedenteV
      },config)

      .then(function(response){
          if(response && response.data && response.data.dados ){
              setMensagem('');
              setSucesso('S');
              }else{
               setmsgErro(response)
              }
          }).catch((erro) =>{
              setMensagem(erro);
              setSucesso('N');
              console.log('ERRO');
          });
      }}
      };

      function buscaAnalitico(e){
    
        api.post(vhostV1('webcash/planoAnalitico'), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            sintetico:e
          },config)
          .then(function (response) {
           
            
            setPlanoAnalitico(response.data.dados);  
            setSintetico(e);
                                 
          })
          .catch(function (error) {       
            console.log(error);
          });
        }


     
        
        useEffect(function(){
            api.post(vhostV1('webcash/planoSintetico'), {
                loja: 11,
                empresa: 0  
              },config)
              .then(function (response) {
                
                
                 setPlanoSintetico(response.data.dados);           
                }                               
              )
              .catch(function (error) {
                console.log(error);
                        
              });
         },[])

      return <div className="conteiner-fluid titulo ">
      
       <Navbar/>
       <div className="offset-lg-3 col-lg-6">
             <h2>Cadastro de Finalizadora</h2>      
              <hr />

              {/* <div className="col-2">
                          <label htmlFor="inputAddress" className="form-label">Código</label>
                          <input type="text" onChange={(e)=> setFinalizadora(e.target.value)} value={finalizadoraV}className="form-control" id="inputAddress" />
                      </div> */}

              <div className="col-10">
                      <label htmlFor="inputAddress" className="form-label">Descrição</label>
                      <input type="text" onChange={(e)=> setDescricao(e.target.value)} value={descricaoV} className="form-control" id="inputAddress" />
                  </div>

              <div className="conteiner-fluid">
                  <form className="row tela">
                  
             
                  <div className="col-md-6">
                      <label htmlFor="inputState" className="form-label">Operadora de Cartão?</label>
                      <select id="inputState" onChange={(e)=> setTef(parseInt(e.target.value.substring(0,3)))} className="form-select">
                          <option>000 - Não Possui TEF</option>
                          <option>001 - Tef Discado/PayGo/Autta</option>                                
                          <option>002 - POS sem integração</option>        
                          <option>003 - Reservado</option>            
                          <option>004 - SITEF</option>   
                          <option>005 - GiftCard</option>    
                          <option>006 - POS(Moderninhas PRO) </option>   
                          <option>007 - Reservado</option>       
                          <option>008 - Reservado </option>           
                          <option>009 - Crediário </option>           
                          <option>010 - Vale Troca/Presente </option>                  
                          <option>011 - Documento Interno </option>                      
                            
                      </select>
                  </div>

                  
                  
                  <div className="col-4">
                          <label htmlFor="inputAddress" className="form-label">Conta Bancária</label>
                          <input type="text" onChange={(e)=> setConta_bancaria(e.target.value)} value={conta_bancariaV} className="form-control" id="inputAddress" />
                      </div>

                      

                      <div className="col-2">
                          <label htmlFor="inputAddress" className="form-label">Prazo</label>
                          <input type="text" onChange={(e)=> setPrazo(e.target.value)} value={prazoV} className="form-control" id="inputAddress" />
                      </div>

                      <div className="col-2">
                          <label htmlFor="inputAddress" className="form-label">Desconto</label>
                          <input type="text" onChange={(e)=> setDesconto(e.target.value)} value={descontoV}className="form-control" id="inputAddress" />
                      </div>

                      <div className="col-3">
                      <label htmlFor="inputAddress" className="form-label">Nº de Parcelas</label>
                      <input type="text" onChange={(e)=> setNr_parcelas(e.target.value)} value={nr_parcelasV} className="form-control" id="inputAddress" />
                  </div>

                  {/* <div className="col-6">
                      <label htmlFor="inputAddress" className="form-label">Data Último Movimento</label>
                      <input type="text" onChange={(e)=> ''} className="form-control" id="inputAddress" />
                  </div> */}
                  <h1></h1>
                  <h6>dados complementares</h6>
                  <hr />
                      
                      
                      
                  
                     
                      
                        <div className="row tela">
                              <div className="col-4">
                                  <label htmlFor="inputAddress" className="form-label">Tabela Preço</label>
                                  <input type="text" onChange={(e)=> setTab_preco(e.target.value)} value={tab_precoV} className="form-control" id="inputAddress" />
                              </div>


                              <div className="col-4">
                                      <label htmlFor="inputAddress" className="form-label">Cod. Cedente</label>
                                      <input type="text" onChange={(e)=> setCod_cedente(e.target.value)} value={cod_cedenteV} className="form-control" id="inputAddress" />
                              </div>

                        {planoAnalitico.length > 0?
                        <div className="col-6">
                        <label htmlFor="inputState" className="form-label">Analítico</label>
                        <select id="inputGrupo"  onChange={(e)=> setAnalitico(e.target.value)} className="form-select" tabIndex="4">
                        { planoAnalitico.map((panalitico)=> {
                            return <option value={panalitico.ANALITICO}> {panalitico.DESCRICAO}</option>          
                            } )}
                            
                            </select>
                        </div>                 
                        :<div className="col-6">
                        <label htmlFor="inputState" className="form-label">Analítico</label>
                        <select id="inputUnidade"  onChange={(e)=> setAnalitico(e.target.value)} className="form-select" tabIndex="3" >
                         <option value={0}>selecione analitico</option>
                            
                        </select>
                        </div>}
                          

                              <div className="col-4">
                                  <label htmlFor="inputAddress" className="form-label">Valor Minimo</label>
                                  <input type="text" onChange={(e)=> setValor_minimo_op(e.target.value)} value={vlr_minimo_opV} className="form-control" id="inputAddress" />
                              </div>

                              <div className="col-4">
                                  <label htmlFor="inputAddress" className="form-label">Duplicata</label>
                                  <input type="text" onChange={(e)=> setDuplicata(e.target.value)} value={duplicataV} className="form-control" id="inputAddress" />
                              </div>

                              <div className="col-6">
                       <label htmlFor="inputState" className="form-label">Sintético</label>
                       <select id="inputGrupo" value={sinteticoV} onChange={(e)=> buscaAnalitico(e.target.value)} className="form-select" tabIndex="4">
                       { planoSintetico.map((psintetico)=> {
                        return <option key={psintetico.SINTETICO} value={psintetico.SINTETICO}> {psintetico.DESCRICAO}</option>      
                               
                        } )}
                        
                       </select>
                   </div> 




                              <h1></h1>
                              
                              <div className="col-6">

                                  <div className="col-6">
                                      <label htmlFor="inputAddress" className="form-label">Juros</label>                       
                                  </div>

                                  <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                      <label className="form-check-label" for="inlineRadio1">Nenhum</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                      <label className="form-check-label" for="inlineRadio2">Valor</label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                      <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3"/>
                                      <label className="form-check-label" for="inlineRadio3">Percentual</label>
                                  </div> 


                                  <div className="col-6">
                                      <input type="text" onChange={(e)=> setJuros(e.target.value)} value={jurosV} className="form-control" id="inputAddress" />
                                  </div>

                                  

                              </div>    
                              






                          </div>                     
                      
                         
                      

                      

                      <div className="col-12">
                          <Link to="/app/finalizadora" type="submit" className="btn btn-outline-primary butao">Desistir</Link>
                        {tag != '0'? ( 
                        <button type="button" onClick={SalvaFinalizadora} className="btn btn-primary butao">Salvar</button>):null}
                      </div>                   
                      
                      {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                      {sucesso === 'S' ? <Navigate to='/app/finalizadora' /> : null}
      
                  </form> 

                  <hr />
              </div>

              {
         erroValidacao ? 
         <SweetAlert
            danger
            confirmBtnText="OK"
            confirmBtnBsStyle="danger"
            title="Erro"
            onConfirm={() => setErrovalidacao(false)}                            
          >
            {msgErro}
          </SweetAlert>:null
         
        }
      </div>  
 </div>

}

export default NovaFinalizadora;