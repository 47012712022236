import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

function CupomFiscal (cupom) {

   const venda = cupom.venda;
   const qrCodeLink = 'https://pdfmake.github.io/docs/0.1/document-definition-object/qr/'
    
    const docDefinition = {
      pageSize: {
        width: 230,
        height: 'auto' // Valor aproximado em pontos (1mm = 2.83465 pontos)
      },
      content: [        
        { text: 'RAZÃO SOCIAL DA EMPRESA', style: 'header' },
        { text: 'CNPJ 60.600.600-10 IE 000000000', style: 'header' },
        { text: 'ENDERECO DA EMPRESA', style: 'header' },
        { text: '------------------------------------------------------', style: 'header' },
        { text: 'DANFE NFC-e Documento Auxiliar da Nota Fiscal', style: 'header' },
        { text: 'Eletronica para Consummidor Final', style: 'header' },
        { text: '------------------------------------------------------', style: 'header' },
        {
          style: 'table',
          table: {
            widths: [15, 40, 10, 25,28,28],
            body: [
              [{ text: 'Item',alignment:'left'}, { text: 'Código'}, { text: 'Descircao',colSpan: 4}, '','',''],
              ['','', { text: 'Un'},{ text: 'Qtde.'}, { text: 'Vlr Unit.'},{ text: 'Vlr Total'}]
              
            ]
          },layout:'noBorders'
           ,fontSize: 7
           ,bold: true
           ,margin:[-35,0,-10,0]
        },
        {
          style: 'table',
          table: {
            widths: [15, 40, 10, 25,28,28],
            body: [
                ...venda.itens.flatMap(item => [
                [ { text: item.item,style: 'produtos'}, { text: item.codigo,style: 'produtos'}, {colSpan: 4,text:item.produto,style: 'produtos'},'','',''],
                ['','',{ text: item.unidade,style: 'produtos'},{ text: Number(item.quantidade).toFixed(3)+'x',style: 'produtos'}, { text: Number(item.precoUnitario).toFixed(2),style: 'produtos',alignment:'right'}, {text:Number(item.total).toFixed(2),style: 'produtos',alignment:'right'} ]
              ])
            ]
          },layout:'noBorders'
        },
       

        { text: ``, style: 'subheader' },
        { text: `QTDE TOTAL DE ITENS : 2`, style: 'subheader' },
        { text: `VALOR TOTAL : R$ ${venda.total}`, style: 'subheader' },
        { text: `FORMA PAGAMENTO`, style: 'subheader' },
        { text: `dinheiro ${venda.total}`, style: 'subheader' },
        { text: '------------------------------------------------------', style: 'header' },
        { text: `NFF-e n ${venda.numero} Série 001 Emissão ${venda.data}`, style: 'rodape' },
        { text: `via do consumidor`, style: 'rodape' },
        { text: `Consulta pela chave de acesso em www.nfce.sefaz.uf.com.br`, style: 'rodape' },
        { text: 'CHAVE DE ACESSO', style: 'rodape' },
        { text: '1234 1234 1234 1234 1234 1234 1234 1234 1234 1234', style: 'rodape' },
        { text: '------------------------------------------------------', style: 'header' },
        { text: 'consulta via QR code', style: 'rodape' },
        { qr: qrCodeLink, fit: 100, alignment: 'center', margin: [0,10,0,0] }, // Adiciona o QR code ao documento



      ],
      styles: {
        header: {
          fontSize: 10,
          bold: true,
          alignment: 'center',
          margin: [-35, 0, 0, 0],
          
        },
        subheader: {
          fontSize: 8,
          bold: true,
          alignment:'right',
          margin: [-35,0 , 0, 0]
        },
        produtos: {
          fontSize: 6,
          bold: false,
          
        },
        rodape: {
          fontSize: 7,
          bold: false,
          alignment:'center',
          margin: [-35,0 , 0, 0]
        },
        table: {
          margin: [-35, 5, 0, 15]
        }
      }
    };

    pdfMake.createPdf(docDefinition).open();
  }

  

export default CupomFiscal;