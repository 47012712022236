import React from 'react';
import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';
import JsBarcode from 'jsbarcode';


pdfMake.vfs = pdfFonts.pdfMake.vfs;

class EtiquetaTriPDF extends React.Component {
  
  generatePDF = () => {
    const { produto } = this.props;
    const barcodeOptions = {
      format: 'CODE128',
      displayValue: true,
      fontSize: 10,
      lineColor: '#000',
      width: 1,
      height: 20
    };
    function abreviarString(str, maxLength) {
      if (str.length > maxLength) {
          // Cortar a string e adicionar '...'
          return str.slice(0, maxLength - 1) + '.';
      }
      return str; // Retornar a string original se não exceder o comprimento máximo
  }

  const descricao = abreviarString(produto.DESCRICAO, 26)

    const canvas = document.createElement('canvas');
    JsBarcode(canvas, produto.COD_PRODUTO, barcodeOptions);
    const barcodeDataURL = canvas.toDataURL('image/png');

    const documentDefinition = {
      content: [
        {
          columns: [
            
            [
              { text: `${descricao}`, style: 'item',  margin: [-30, -30, 0, 0], fontSize: 5 },
              {
                image: barcodeDataURL,
                width: 80,
                height: 40,
                margin: [-35, 0, 20, 0]
              }
            ],
            [
              { text: `${descricao}`, style: 'item', margin: [-100, -30, 0, 0], fontSize: 5 },
              {
                image: barcodeDataURL,
                width: 80,
                height: 40,
                margin: [-105, 0, 20, 0]
              }
            ],
            [
              { text: `${descricao}`, style: 'item', margin: [-175, -30, 0, 0], fontSize: 5 },
              {
                image: barcodeDataURL,
                width: 80,
                height: 40,
                margin: [-180, 0, 20, 0]
              }
            ]
          ]
        }
      ],
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          alignment: 'center',
          margin: [0, 0, 0, 20]
        },
        item: {
          margin: [0, 5],
          width: 20
         
        }
      }
    };

    pdfMake.createPdf(documentDefinition).open();
  };

  render() {
    return (
      <div>
        <button onClick={this.generatePDF}  className='dropdown-item ' ><i className="fas fa-barcode etiquetaIcon"></i>TAG</button>
      </div>
    );
  }
}

export default EtiquetaTriPDF;