import React,{useState} from "react";
import Navbar from "../navbar/navbar";
import { api } from "../../services/api";
import { Link,Navigate,useParams } from 'react-router-dom'
import { useEffect } from "react";
import './empresa.css'
import { vhostV1 } from "../funcoes";





function NovaEmpresa(props){

  document.addEventListener("keydown", function(e) {

    if(e.keyCode === 13) {
      
      e.preventDefault();
      
    }
  
  });

  
  const {id} = useParams();

   {/*dados da tela */}
   const [razao,setRazao] = useState('');
   const [cnpj,setCnpj] = useState('');
   const [fantasia,setFantasia] = useState('');
   const [inscEstadual,setInscEstadual] = useState('');
   const [endereco,setEndereco] = useState('');
   const [nr,setNr]= useState('');
   const [uf,setUf] = useState('');
   const [cep,setCep] =useState('');
   const [bairro,setBairro] = useState('');
   const [cidade,setCidade] = useState('');
   const [telefone,setTelefone] = useState('');
   const [complemento,setComplemento] = useState('');
   const [matriz,setMatriz] = useState('');
   const [indPerfil,setIndPerfil] =useState('');
   const [iest,setIest] = useState('');
   const [im,setIm] = useState('');
   const [suframa,setSuframa] = useState('');
   const [custoFixo,setCustoFixo] = useState('');
   const [indAtividade,setIndAtividade] = useState('');
   const [regimeTributario,setRegimeTibutario] = useState('');
   const [enquadramentoSimples,setEnquadramentoSimples] = useState(0);
   const [aliquota,setAliquota] = useState('');
   const [irpj,setIrpj] = useState('');
   const [csll,setCsll] = useState('');
   const [cofins,setCofins] = useState('');
   const [pisPasep,setPisPasep] = useState('');
   const [inss,setInss] = useState('')
   const [icms,setIcms] = useState('');
   const [contato,setContato] = useState('');
   const [nomeProprietario,setNomeProprietario] = useState('');
   const [cpfPrietario,setCpfProprietario] = useState('');
   const [emailProprietario,setEmailProprietario] = useState('');
   const [telefoneProprietario,setTelefoneProprietario] = useState('');
   const [nomeContador,setNomeContador] = useState('');
   const [cnpjContador,setcnpjContador] = useState('');
   const [crc,setCrc] = useState('');
   const [enderecoContator,setEnderecoContador]= useState('');
   const [nrContador,setNrContador] = useState('');
   const [telefoneContador,setTelefoneContador]= useState('');
   const [cepContador,setCepContador] = useState('');
   const [bairroContador,setBairroContador] = useState('');
   const [cidadeContador,setCidadeContador] = useState('');
   const [complementoContador,setComplementoContador] = useState('');
   const [foneUm,setFoneUm] = useState('');
   const [foneDois,setFoneDois] = useState('');
   const [emailContador,setEmailContador] = useState('');
   const [homePage,setHomePage] = useState('');
   const [indicadorIncidencia,setIndicadorIncidencia] = useState(0);
   const [indicadorMetoApuracao,setIndicadorMetodoApuracao] = useState(0);
   const [indicadorRegimeCumulativo,setIndicadorRegimeCumulativo] = useState(0);
   const [indicadorTipoContribuicao,setIndicadorTipoContribuicao] = useState(0);
   const [indicadorAtividade,setIndicadorAtividade] = useState(0);
   const [emailEmpresa,setEmailEmpresa] = useState('');
   const [senhaEmail,setSEnhaEmail] = useState('');
   const [smtp,setSmtp] = useState('')
   const [porta,setPorta] = useState('')






   const [cor,setCor] = useState('');
   const [carga,setCarga] = useState('');
   const [descricao,setDescricao] = useState('');




   const [sucesso,setSucesso]      = useState('N')
   const [mensagem, setMensagem]   = useState('');
   
    
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }










   //buscar grupo se for edicao
  useEffect(function(){
    
    api.post(vhostV1('webcash/empresa/buscaResumida'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        },config)
      .then(function (response) {
        console.log(response.data)
                         
      })
      .catch(function (error) {
       
        console.log(error);

                
      });

      
  },[])



  
   function salvaGrupo(e){
    e.preventDefault();
        api.post(vhostV1('webcash/grupo/incluir'),{
            loja:11,
            COD_GRUPO:id,
            EMPRESA:0,
            DESCRICAO:descricao,
            COR:cor,
            CARGA:carga,
          },config)
        .then(function(response){
            setMensagem('');
            setSucesso('S');
            console.log()
        }).catch((erro) =>{
            setMensagem(erro);
            setSucesso('N');
        });
     }


     return <div className="conteiner-fluid titulo ">
         <Navbar/>
         <div className="offset-lg-2 col-lg-8">
               <h2>Cadastro de Empresas</h2>      
               <hr />
                <div className="conteiner-fluid">
                <nav>
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Empresa</button>
                        <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-profile" aria-selected="false">Proprietário</button>
                        <button className="nav-link" id="nav-contact-tab" data-bs-toggle="tab" data-bs-target="#nav-contact" type="button" role="tab" aria-controls="nav-contact" aria-selected="false">Contador</button>
                        <button className="nav-link" id="nav-disabled-tab" data-bs-toggle="tab" data-bs-target="#nav-disabled" type="button" role="tab" aria-controls="nav-disabled" aria-selected="false">Apuração da Contribuição Social</button>
                        <button className="nav-link" id="nav-email-tab" data-bs-toggle="tab" data-bs-target="#nav-email" type="button" role="tab" aria-controls="nav-email" aria-selected="false">E-mail/ Inf. NFe</button>
                    </div>
                    </nav>
                    <div className="tab-content" id="nav-tabContent">
                    <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabindex="0">
                    
                    <div className="row">
                        <div className="col-1">
                            <label htmlFor="inputAddress" className="form-label">ID</label>
                            <input type="text"  value={id}  className="form-control" id="inputDescricao" tabIndex="1" required autoComplete="off"/>
                        </div>
                        
                        <div className="col-8">
                            <label htmlFor="inputAddress" className="form-label">RAZÃO</label>
                            <input type="text"  value={razao}  onChange={(e)=> setRazao(e.target.value)} className="form-control" id="inputDescricao" tabIndex="1" required autoComplete="off"/>
                        </div>
                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">CNPJ</label>
                            <input type="text" value={cnpj} onChange={(e)=> setCnpj(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div> 
                    </div>
                    <div className="row">
                        <div className="col-9">
                            <label htmlFor="inputAddress" className="form-label">FANTASIA</label>
                            <input type="text" value={fantasia} onChange={(e)=> setFantasia(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div> 
                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">INS. ESTADUAL </label>
                            <input type="text" value={inscEstadual} onChange={(e)=> setInscEstadual(e.target.value)} className="form-control" id="inputCompra" tabIndex="3" required autoComplete="off"/>
                        </div> 
                    </div>
                    <div className="row">
                            <div className="col-8">
                            <label htmlFor="inputAddress" className="form-label">ENDERECO</label>
                            <input type="text" value={endereco} onChange={(e)=> setEndereco(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>  
                        <div className="col-1">
                            <label htmlFor="inputAddress" className="form-label">NR </label>
                            <input type="text" value={nr} onChange={(e)=> setNr(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>  
                        <div className="col-1">
                            <label htmlFor="inputAddress" className="form-label">UF </label>
                            <input type="text" value={uf} onChange={(e)=> setUf(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>  
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">CEP </label>
                            <input type="text" value={cep} onChange={(e)=> setCep(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>       
                    </div>
                    <div className="row">
                            <div className="col-4">
                            <label htmlFor="inputAddress" className="form-label">BAIRRO</label>
                            <input type="text" value={bairro} onChange={(e)=> setBairro(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>       
                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">CIDADE</label>
                            <input type="text" value={cidade} onChange={(e)=> setCidade(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>       
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                            <input type="text" value={telefone} onChange={(e)=> setTelefone(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>       
                        <div className="col-3">
                            <label htmlFor="inputAddress" className="form-label">CONTATO</label>
                            <input type="text" value={contato} onChange={(e)=> setContato(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>        
                    </div>
                    <div className="col-12">
                       <label htmlFor="inputAddress" className="form-label">COMPLEMENTO</label>
                       <input type="text" value={complemento} onChange={(e)=>  setComplemento(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                    </div>  
                    
                    <div className="row">
                        <div className="col-md-2">
                            <label htmlFor="inputState" className="form-label">MATRIZ</label>
                            <select id="inputUnidade" value={matriz} onChange={(e)=> setMatriz(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                            </select>
                        </div>

                        <div className="col-md-2">
                            <label htmlFor="inputState" className="form-label">IND PERFIL</label>
                            <select id="inputUnidade" value={indPerfil} onChange={(e)=> setIndPerfil(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                            </select>
                        </div>
                        
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">IEST</label>
                            <input type="text" value={iest} onChange={(e)=> setIest(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>        
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">IM</label>
                            <input type="text" value={im} onChange={(e)=> setIm(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>        
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">SUFRAMA</label>
                            <input type="text" value={suframa} onChange={(e)=> setSuframa(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>

                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">CUSTO FIXO</label>
                            <input type="text" value={custoFixo} onChange={(e)=> setCustoFixo(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>      
                        
                    </div>

                    <div className="row">
                            

                            <div className="col-md-4">
                            <label htmlFor="inputState" className="form-label">IND ATIVIDADE</label>
                            <select id="inputUnidade" value={indAtividade} onChange={(e)=> setIndAtividade(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                            </select>
                        </div>

                            <div className="col-md-8">
                            <label htmlFor="inputState" className="form-label">REGIME TRIBUTARIO</label>
                            <select id="inputUnidade" value={regimeTributario} onChange={(e)=> setRegimeTibutario(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                            </select>
                        </div>

                    </div>

                        <div className="simples-nacional">
                          <div className="col-md-12">
                            <label htmlFor="inputState" className="form-label">ENQUADRAMENTO SIMPLES NACIONAL</label>
                            <select id="inputUnidade" value={enquadramentoSimples} onChange={(e)=> setEnquadramentoSimples(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                            </select>
                          </div>
                      </div>  

                      <div className="row">
                      
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">ALIQUOTA</label>
                            <input type="text" value={aliquota} onChange={(e)=> setAliquota(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>    
                        <div className="col-1">
                            <label htmlFor="inputAddress" className="form-label">IRPJ</label>
                            <input type="text" value={irpj} onChange={(e)=> setIrpj(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>    
                        <div className="col-1">
                            <label htmlFor="inputAddress" className="form-label">CSLL</label>
                            <input type="text" value={csll} onChange={(e)=> setCsll(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>    
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">COFINS</label>
                            <input type="text" value={cofins} onChange={(e)=> setCofins(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>    
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">PIS/PASEP</label>
                            <input type="text" value={pisPasep} onChange={(e)=> setPisPasep(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>    
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">INSS</label>
                            <input type="text" value={inss} onChange={(e)=> setInss(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div>    
                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">ICMS</label>
                            <input type="text" value={icms} onChange={(e)=> setIcms(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                        </div> 
                        
                      </div>

                    
                    </div>{/*fim da div aba1*/}
                    <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab" tabindex="0">
                        <div className="row">
                            <div className="col-9">
                                <label htmlFor="inputAddress" className="form-label">NOME COMPLETO</label>
                                <input type="text" value={nomeProprietario} onChange={(e)=> setNomeProprietario(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-3">
                                <label htmlFor="inputAddress" className="form-label">CPF</label>
                                <input type="text" value={cpfPrietario} onChange={(e)=> setCpfProprietario(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  
                        </div>
                        <div className="row">
                            <div className="col-9">
                                <label htmlFor="inputAddress" className="form-label">E-MAIL</label>
                                <input type="text" value={emailProprietario} onChange={(e)=> setEmailProprietario(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-3">
                                <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                                <input type="text" value={telefoneProprietario} onChange={(e)=> setTelefoneProprietario(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  
                        </div>
                    </div>{/*fim da Aba proprietario*/}
                    <div className="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab" tabindex="0">
                           <div className="row">
                           <div className="col-6">
                                <label htmlFor="inputAddress" className="form-label">NOME COMPLETO</label>
                                <input type="text" value={nomeContador} onChange={(e)=> setNomeContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  
                            <div className="col-3">
                                <label htmlFor="inputAddress" className="form-label">CPF/CNPJ</label>
                                <input type="text" value={cnpjContador} onChange={(e)=> setcnpjContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  
                            <div className="col-3">
                                <label htmlFor="inputAddress" className="form-label">CRC</label>
                                <input type="text" value={crc} onChange={(e)=> setCrc(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                        </div> 

                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="inputAddress" className="form-label">ENDEREÇO</label>
                                <input type="text" value={enderecoContator} onChange={(e)=> setEnderecoContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-1">
                                <label htmlFor="inputAddress" className="form-label">NR</label>
                                <input type="text" value={nr} onChange={(e)=> setNr(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-3">
                                <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                                <input type="text" value={telefoneContador} onChange={(e)=> setTelefoneContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-2">
                                <label htmlFor="inputAddress" className="form-label">CEP</label>
                                <input type="text" value={cepContador} onChange={(e)=> setCepContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  
                        </div>

                        <div className="row">
                            <div className="col-6">
                                <label htmlFor="inputAddress" className="form-label">BAIRRO</label>
                                <input type="text" value={bairro} onChange={(e)=> setBairroContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-6">
                                <label htmlFor="inputAddress" className="form-label">CIDADE</label>
                                <input type="text" value={cidadeContador} onChange={(e)=> setCidadeContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-12">
                                <label htmlFor="inputAddress" className="form-label">COMPLEMENTO</label>
                                <input type="text" value={complementoContador} onChange={(e)=> setComplementoContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            
                        </div>

                        <div className="row">
                           <div className="col-2">
                                <label htmlFor="inputAddress" className="form-label">FONE 1</label>
                                <input type="text" value={foneUm} onChange={(e)=> setFoneUm(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-2">
                                <label htmlFor="inputAddress" className="form-label">FONE 2</label>
                                <input type="text" value={foneDois} onChange={(e)=> setFoneDois(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-8">
                                <label htmlFor="inputAddress" className="form-label">E-MAIL</label>
                                <input type="text" value={emailContador} onChange={(e)=> setEmailContador(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  

                            <div className="col-12">
                                <label htmlFor="inputAddress" className="form-label">HOMEPAGE</label>
                                <input type="text" value={homePage} onChange={(e)=> setHomePage(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                            </div>  
                        </div>

                     </div>{/*fom da aba contador */}
                     <div className="tab-pane fade" id="nav-disabled" role="tabpanel" aria-labelledby="nav-disabled-tab" tabindex="0">
                        
                          <div className="col-md-12">
                              <label htmlFor="inputState" className="form-label">CODIGO INDICADOR DA INCIDÊNCIA</label>
                              <select id="inputUnidade" value={carga} onChange={(e)=> setCarga(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                              </select>
                          </div>

                          <div className="col-md-12">
                              <label htmlFor="inputState" className="form-label">INDICADOR DE METODO DE APURAÇÃO</label>
                              <select id="inputUnidade" value={carga} onChange={(e)=> setCarga(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                              </select>
                          </div>

                          <div className="col-md-12">
                              <label htmlFor="inputState" className="form-label">INDICADOR DE REGIME CUMULATIVO</label>
                              <select id="inputUnidade" value={carga} onChange={(e)=> setCarga(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                              </select>
                          </div>

                          <div className="col-md-12">
                              <label htmlFor="inputState" className="form-label">INDICADOR DO TIPO DE CONTRIBUIÇÃO</label>
                              <select id="inputUnidade" value={carga} onChange={(e)=> setCarga(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                              </select>
                          </div>

                          <div className="col-md-6">
                              <label htmlFor="inputState" className="form-label">INDICADOR DE ATIVIDADE</label>
                              <select id="inputUnidade" value={carga} onChange={(e)=> setCarga(e.target.value)} className="form-select" tabIndex="3" >
                                <option VALUE={1}>SIM</option>
                                <option VALUE={0}>NÃO</option>
                                
                              </select>
                          </div>
                      
                     </div>{/*fim da aba apuracao */}
                     <div className="tab-pane fade" id="nav-email" role="tabpanel" aria-labelledby="nav-disabled-tab" tabindex="0">
                        
                          <div className="row">
                                <div className="col-6">
                                    <label htmlFor="inputAddress" className="form-label">E-MAIL</label>
                                    <input type="text" value={emailEmpresa} onChange={(e)=> setEmailEmpresa(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                                </div>
                                <div className="col-2">
                                    <label htmlFor="inputAddress" className="form-label">SENHA</label>
                                    <input type="password" value={senhaEmail} onChange={(e)=> setSEnhaEmail(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                                </div>
                                <div className="col-2">
                                    <label htmlFor="inputAddress" className="form-label">SMTP</label>
                                    <input type="text" value={smtp} onChange={(e)=> setSmtp(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                                </div>
                                <div className="col-2">
                                    <label htmlFor="inputAddress" className="form-label">PORTA</label>
                                    <input type="text" value={porta} onChange={(e)=> setPorta(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                                </div>
                          </div>
                      
                     </div>{/*fim da aba apuracao */}
                    </div>   
                <form className="row tela" onsubmit="return false;">
                        
                       
                   
                   <div className="col-12">
                   <hr />
                       <Link to="/app/empresa" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                       <button type="submit" onClick={salvaGrupo} className="btn btn-primary butao" tabIndex="13">Salvar</button>
                   </div>
       
                   {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                   {sucesso === 'S' ? <Navigate to='/app/empresa' /> : null}
       
                   </form> 
                         
                 
            </div>
        </div>  
   </div>

}



export default NovaEmpresa;