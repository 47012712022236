import React,{useState,useEffect} from "react";
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import Navbar from "../navbar/navbar";
import { Link, Navigate } from "react-router-dom";
import './nfe.css';
import SweetAlert from 'react-bootstrap-sweetalert';
import { useNavigate } from "react-router-dom";
import Loading from "../components/loading/Loading";
import WidgetNfe from "../components/widgetNfe/widgetNfe";
import customizeScrollbar from "customsb";



function NfeDashboard(){

    const [texto,setTexto] = useState('');
    const [busca,setBusca] = useState("");
    const [notas,setNotas] = useState([]); 
    const [sResult,setSresult] = useState(0);
    const [erroEmissao,setErroEmissao] = useState(false);
    const [msgErro,setmsgErro] = useState('')
    const [confirmaEmissao,setConfirmaEmissao] = useState(false);
    const [load,setLoad] = useState(false);
    const [base64Data, setBase64Data] = useState('');
    const [dataInical,setDataInicial] = useState(new Date); 
    const [dataFinal,setDataFinal] = useState(new Date);

    // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
    // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
    customizeScrollbar({
      scrollbarWidth: "10px",
      trackBackground: "#333",
      thumbBackground:
        "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
      thumbBorderRadius: "10px",
    });
  }, []);

    let config = {
      headers: {
         'Authorization': localStorage.getItem("token")
         }
      }

      const navigate = useNavigate();



      return <div>
        <Navbar/>
       <div className="container-fluid titulo offset-lg-1 col-lg-10">
       
          <div className="d-flex justify-content-between">
             <h1 className="d-inline">NFe - Dashboard</h1>
             <div>
                <button className="btn btn-secondary  btn-cli" type="button" data-bs-toggle="modal" data-bs-target="#staticBackdrop"><i className="bi bi-funnel-fill"></i>Filtros</button>
             </div>

            </div>
             
        <hr />
        <div className="row">
          <WidgetNfe/>
        </div>

       </div>
      </div>

}

export default NfeDashboard;