import { useEffect, useState } from 'react';
import React from 'react'
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';
import ObterMesAtual from './month';


function WidgetCompra(){

    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }


     const [totalMes,setTotalMes] = useState(0);   
     const [totalDia,setTotalDia] = useState(0);   
     const [qtdVenda,setQtdVenda] = useState(0);   
     const [tiket,setTiket] = useState(0);  
     const mesAtual = ObterMesAtual(); 

     





    useEffect(function(){
        api.post(vhostV1('webcash/dashboard/widget'), {
          loja: localStorage.getItem('loja'),
          empresa: 0
          },config)
        .then(function (response) {        
          
          setTotalMes(response.data.dados.total);           
          setTotalDia(response.data.dados.venda);           
          setQtdVenda(response.data.dados.qtd);           
          setTiket(response.data.dados.ticket);           
      
        })
        .catch(function (error) {
          console.log(error);
                  
        });
                
    },[])


  return <div className="row widgets" id='bloco-widget'>
                  <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-primary shadow h-80 py-2">
                          <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                  <div className="col mr-2 text-xs font-weight-bold text-primary text-uppercase mb-1" > Mês Corrente: {mesAtual}
                                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          Total de Compras </div>
                                      <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(0).toFixed(2)}</div>
                                  </div>
                                  <div className="col-auto">
                                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>

  
  
                  <div className="col-xl-3 col-md-6 mb-4" >
                      <div className="card border-left-warning shadow h-80 py-2">
                          <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                  <div className="col mr-2 text-xs font-weight-bold text-warning text-uppercase mb-1" > Mês Corrente: {mesAtual}
                                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1"> 
                                          Nº Notas</div>
                                      <div className="h5 mb-0 font-weight-bold text-gray-800">{qtdVenda}</div>
                                  </div>
                                  <div className="col-auto">
                                      <i className="fas fa-comments fa-2x text-gray-300"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>                       
   
                  
              </div>
}

export default WidgetCompra ;