import React from 'react'
import Modal from 'react-modal/lib/components/Modal';
import { useState, useRef } from 'react';
import { vhostV1 } from '../funcoes';
import api from '../../services/api';
import { NumericFormat } from 'react-number-format';
import ModalProduto from '../components/modalProduto/ModalProduto';
import { useEffect } from 'react';
import SweetAlert from 'react-bootstrap-sweetalert';


function ModalAdicaoItem(props){

    const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex =  currentIndex + 1;
      
        
          if (nextIndex < inputs.length) 
            {
              inputs[nextIndex].focus(); 
            } 
     
        } 
      }) ;
    }); 

    


    

    let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
    }

    function calculaPreco(){

      setTotal(vendaDefault * qtd)  
     }

    function calculaDesconto(){
      setTotal(qtd * vendaDefault - desconto)

    } 

    var meuInput = document.getElementById('inputCod');

   
   const [total,setTotal] = useState('');
   const [totalComDesconto, setTotalComDesconto] = useState(0)
   const [codProduto,setCodProduto] = useState('');
   const [descricao,setDescricao] = useState('')
   const [venda,setVenda] = useState('');
   const [vendaDefault,setVendaDefault] = useState('');
   const [desconto,setDesconto] = useState(0);
   const [descontoDefault,setDescontoDefault] = useState('');
   const [qtd,setQtd] = useState(1); 
   const [qtdDefault,setQtdDefault] = useState(1); 
   const [msgErro, setmsgErro] = useState('');
   const [erroValidacao,setErrovalidacao] = useState(false);
   const inputRef = useRef(null);
   
   
   useEffect(() => {
    setTotalComDesconto(Number(total-desconto))
   }, [total, desconto])
   
  


    // funcao para buscar produto
 function buscaIten(){
  api.post(vhostV1('webcash/produto/retornaproduto/'+codProduto), {
    loja: localStorage.getItem('loja'),
    empresa: 0         
  },config)
  .then(function (response) { 
    
   if(response && response.data && response.data.dados){
    console.log(response.data.dados);
    
    setDescricao(response.data.dados.DESCRICAO);
    setVendaDefault(Number(response.data.dados.VENDA));
    setVenda(Number(response.data.dados.VENDA));
     }
   else{
     console.log("não busquei")
   }  
    
   })
  .catch(function (error) {
   
    console.log(error);
            
  });
   };
  

  const [isProdutoOpen,setIsProdutoOpen] = useState(false);
  
   

   function openModalProduto(){
      setIsProdutoOpen(true);
      
   }

   function closeModalProduto(){
        setIsProdutoOpen(false);
      

     
   }

   const customValidation = (values) => {
    const { total } = values;
    // Verifica se o valor é maior que zero
    return parseFloat(total) > 0;
  };



   function mostrarCampo() {
    const campoOculto = document.getElementById("campoOculto");
    campoOculto.style.display = "block";
    
}  
  

   

   function AdicionarItem(){
      {if (total <= 0){
        setmsgErro('Total não pode ser 0!');
        setErrovalidacao(true);
      } else if(qtd <= 0 ){
        setmsgErro('Quantidade deve ser diferente de 0!')
        setErrovalidacao(true);
      }  else {
    api.post(vhostV1('webcash/vendas/VenderItem'), {
       pedido: props.idVenda,
       responsavel: 0,
       codProduto: codProduto,
       obs: "",
       desconto: Number(desconto),
       qtd: Number(qtd),
       valor: Number(vendaDefault),
       total: Number(total),
       caixa: "001",
       vendedor: 0
     },config)
     .then(function (response) {
      console.log(response);      
      setCodProduto('');
      setDesconto('');
      setDescricao('');
      setVenda('');
      setQtd('');
      setTotal('');
      setVendaDefault('');
      setQtdDefault('');

                
      props.onRequestClose();                                   
                
     })
     .catch(function (error) {
      
       console.log(error);

               
     });


 }}}
 

   

  return (<Modal isOpen={props.isOpen}
                 onRequestClose={props.onRequestClose}
                 overlayClassName="react-modal-overlay"
                 ariaHideApp={false}
                 className="adicao-modal-content">
          <ModalProduto isOpen={isProdutoOpen}
                 onRequestClose={closeModalProduto}
                 setCodProduto={setCodProduto}
                 setDescricao={setDescricao}
                 setVendaDefault={setVendaDefault}
                 titulo="pesquisa de Produto"
                 />  
                 
    
      <div className="modal-content">
        <div className="modal-header">
          <h1 className="modal-title fs-5" id="staticBackdropLabel">Adição de item</h1>
          <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div className="modal-body">
          <div className="row">
            
            <div className="col-4">
            <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                <div className="input-group mb-3">
                <input name='inputCod' id='inputCod' value={codProduto} onChange={(e) => setCodProduto(e.target.value)} onBlur={(e) => buscaIten(e)} type="text" className="form-control"  aria-describedby="button-addon2" />
                <button  className="btn btn-primary" onClick={(e) => openModalProduto()} type="button" id="button-addon2"><i style={{fontSize: "15px"}} className="bi bi-search "></i></button>
            </div>
            </div>
                  
          
          <div className="col-7" >
          <label htmlFor="inputAddress" className="form-label">DESCRICAO</label>   
          <input  type="text" value={descricao} onBlur={calculaPreco} onChange={(e) => setDescricao(e.target.value)} className="form-control" id="inputDescricao" readOnly/>
          </div>
          </div>
        
          <div className="row mt-3">

          <div className="col-3">
          <label htmlFor="inputAddress" className="form-label" >VENDA</label>   
               <NumericFormat className="form-control"
                              id="inputVenda" 
                              value={vendaDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              onBlur={calculaPreco}
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              readOnly
                                                      
               />
               </div>

             
          
          <div className="col-3" >
          <label htmlFor="inputAddress"  className="form-label" ref={inputRef}>QTD</label>   
               <NumericFormat className="form-control"
                              id="inputQtd" 
                              onBlur={calculaPreco} 
                              value={qtdDefault}  
                              defaultValue={qtdDefault}
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={3}
                              onValueChange={(values,suorceInfo)=>{
                                setQtd(values.value);
                              }} 
                              
                                                        
                /> 
          </div>

          <div className="col-3"  id='campoOculto' >
          <label htmlFor="inputAddress" className="form-label"  >DESCONTO</label>   
                <NumericFormat className="form-control"
                              id="inputVenda" 
                              onBlur={calculaPreco} 
                              value={descontoDefault.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              
                              allowLeadingZeros
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              onValueChange={(values,suorceInfo)=>{
                                setDesconto(values.value);
                              }}
                                                       
               />   
          </div>

          

          <div className="col-3" >
          <label htmlFor="inputAddress" className="form-label" >TOTAL</label>   
                <NumericFormat className="form-control"
                              id="inputVenda" 
                              defaultValue={total.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              allowLeadingZeros
                              allowNegative={false}
                              decimalSeparator=','
                              thousandSeparator='.'
                              decimalScale={2}
                              value={totalComDesconto.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                              readOnly
                                                     
              />
          </div>

         

          </div>
          
        </div>
    
        <div className="modal-footer">
          <button type='button' className='btn btn-danger' data-bs-dismiss='modal' onClick={mostrarCampo}>$</button>
         <button type="button" className="btn btn-primary" data-bs-dismiss="modal" onClick={AdicionarItem}>Confirmar</button>
         <button type="button" className="btn btn-secondary" data-bs-dismiss="modal" onClick={props.onRequestClose} >Fechar</button>
         </div>

         {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
      </div>
    
  </Modal>)
}

export default ModalAdicaoItem;