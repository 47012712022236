import React from 'react'
import Navbar from '../navbar/navbar';
import Carousel from './Carousel';

function ResumoPedido(){
    const buttons = ['AGUA', 'CARNES', 'CERVEJAS'];
    
  return (<>
      <Navbar/>
      <div className="container">
       
       <div className="controle d-flex">
           
           <div className="produtos-venda">
            <div className="carrossel-produto">
                <Carousel buttons={buttons} />
            </div>
                produtos
           </div>
           <div className="itens-venda">
             <div className="dado-pedido">
                <div className="row dados-mesa">
                    <h4 className='col-6'>MESA:</h4>
                    <h4 className='col-6'>1</h4>
                </div>
                <div className="row dados-mesa">
                    <h4 className='col-6'>N pedido:</h4>
                    <h4 className='col-6'>1</h4>
                </div>
                <div className="row dados-mesa">
                    <h4 className='col-6'>Sub Total:</h4>
                    <h4 className='col-6'>R$ 1,15</h4>
                </div>
             </div>
             
             <div className="itens-vendido">

              <div className="botoes-acao">
                <button className='btn btn-warning col-6'>
                   PRÉ-CONTA
                </button>
                <button className='btn btn-success col-6'>
                  FECHAR
                </button>
              </div>  
             </div>



           </div>
       
       </div>
       
       
          
      







      </div>

  </>
    
  )
}

export default ResumoPedido;
