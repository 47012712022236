import React,{useState,useEffect} from "react";
import Navbar from "../../navbar/navbar";
import { api } from "../../../services/api";
import { Link,Navigate,useParams } from 'react-router-dom'
import './contas.css'
import { vhostV1 } from "../../funcoes";
import "react-datepicker/dist/react-datepicker.css";
import BuscaVazaia from "../../components/buscavazia/buscaVazia";
import moment from "moment/moment";


function NovaConta(props){

    const {tpc} = useParams();
    const {id} = useParams();

   
   const [sucesso,setSucesso] = useState('N')
   const [mensagem, setMensagem] = useState('');
   const [responsavel,setResponsavel] = useState(0);
   const [razao,setRazao] = useState('');
   const [descricao,setDescricao] = useState('');
   const [valor,setValor] = useState(0);
   const [juros,setJuros] = useState(0);
   const [multa,setMulta] = useState(0);
   const [desconto,setDesconto] = useState(0);
   const [documento,setDocumento] = useState('');
   const [tipo_cobranca,setTipoCobranca] = useState(0);
   const [forma_pagamento,setFormaPagamento] = useState(0);
   const [cadastro_por,setCadastroPor] = useState(99);
   const [alterado_por,setAlteradoPor] = useState(99);
   const [tipo_conta,setTipoConta] = useState('');
   const [cod_colaborador,setCodColaborado] = useState(99);
   const [sintetico,setSintetico] = useState(0);
   const [analitico,setAnalitico] = useState(0);
   const [centrocusto,setCentro_custo] = useState(0);
   const [seq_banco,setSeqBanco] = useState(0);
   const [cod_finalizadora,setCodFinalizado] = useState(0);
   const [nosso_numero,setNossoNumero] = useState('');
   const [pessoas,setPessoas] = useState([])
   const [buscaPessoa,setBuscaPessoa] = useState('');
   const [resultPessoa,setResultPessoa] = useState(0);
   const [dataVenc,setDataVenc] = useState('');
   const [planoSintetico,setPlanoSintetico] = useState([]); 
   const [planoAnalitico,setPlanoAnalitico] = useState([]);    
   const [linha_digitavel,setLinhaDigitavel] = useState('');
   const [banco,setbanco] = useState([]);
   const [parcelas,setParcelas] = useState(1);
   const [dataEmissao,setDataEmissao] = useState(new Date());
   const [dataSEmissao,setSDataEmissao] = useState('');
   const [dataSVenc,setDataSVenc] = useState('');

   
   
  
   var dataAtual = moment().format('YYYY-MM-DD');
  


  function setEmissao(sData){

    
   setSDataEmissao(dataAtual)
   setDataEmissao(sData);
   

  }

  function setVencimento(sData){

    setDataSVenc(sData+'T00:00:00.000Z');
    setDataVenc(sData)
  }

  function validarData(dataString) {
    // Separa a string em dia, mês e ano
    var partes = dataString.split('/');
    var dia = parseInt(partes[0]);
    var mes = parseInt(partes[1]) - 1; // Mês começa do zero (janeiro é 0)
    var ano = parseInt(partes[2]);

    // Cria um objeto de data
    var data = new Date(ano, mes, dia);

    // Verifica se a data é válida
    if (data.getDate() == dia && data.getMonth() == mes && data.getFullYear() == ano) {
        // Formata a data no formato "yyyy-mm-dd"
        var dataFormatada = data.getFullYear() + '-' + ('0' + (data.getMonth() + 1)).slice(-2) + '-' + ('0' + data.getDate()).slice(-2);
        return dataFormatada;
    } else {
        return null; // Retorna null se a data for inválida
    }
}


   



   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
       }
    }

   function SalvaConta(e){
    e.preventDefault();

    
    

    const sJson = {};
    sJson.SEQ=Number(id);
    sJson.loja= localStorage.getItem('loja');
    sJson.EMPRESA=0;
    sJson.RESPONSAVEL=Number(responsavel);
    sJson.PARCELA= parcelas;
    sJson.DATA_EMISSAO=dataEmissao;
    sJson.DATA_VENC=dataSVenc;
    sJson.DESCRICAO=descricao;
    sJson.VALOR=Number(valor);
    sJson.JUROS=0;
    sJson.MULTA=0;
    sJson.DESCONTO=0;
    sJson.INTEGRAL="F";
    sJson.DOCUMENTO=documento;
    sJson.CFOP="0000";
    sJson.TIPO_MOVIMENTO= 1;
    sJson.CANCELADO="F";
    sJson.PLANO_CONTA=0;
    sJson.TIPO_COBRANCA=tipo_cobranca;
    sJson.FORMA_PAGAMENTO=0;
    sJson.COMISSAO= 0;
    sJson.VALOR_PAGO=0;
    sJson.TOTAL_PAGAR=Number(valor);
    sJson.CADASTRADO_POR=Number(localStorage.getItem('colaborador'));
    sJson.CADASTRADO_EM=dataEmissao;
    sJson.ALTERADO_POR=Number(localStorage.getItem('colaborador'));
    sJson.ALTERADO_EM=dataEmissao;
    sJson.TIPO_CONTA=tpc;
    sJson.COD_COLABORADOR=localStorage.getItem('colaborador');
    sJson.SINTETICO=Number(sintetico);
    sJson.ANALITICO=Number(analitico);
    sJson.CENTRO_CUSTO=0;
    sJson.SEQ_BANCO=seq_banco;
    sJson.NR_CHEQUE= 0;
    sJson.COD_FINALIZADORA=0;
    sJson.CAIXA="00";
    sJson.NOSSO_NUMERO="00";
    sJson.LOTE_REMESSA="";
    sJson.LINHA_DIGITAVEL="";
    sJson.CONFIRMADO="T";
    api.post(vhostV1('webcash/contas/incluirConta'),
        sJson                        
    ,config)
    .then(function(response){
        console.log(sJson);
        console.log(response+'estou aqui');
        setMensagem('');
        setSucesso('S');
        console.log('passei' )
    }).catch((erro) =>{
        setMensagem(erro);
        setSucesso('N');
        console.log(erro)
    });   
    
   }


   function listaPessoa(e){
    e.preventDefault();
    api.post(vhostV1('webcash/cadastro'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        tipo:"%",
        busca: buscaPessoa
      },config)
      .then(function (response) {
        setPessoas(response.data.dados);  
        setResultPessoa(response.data.result);
        console.log(response.data.dados);                                  
                 
      })
      .catch(function (error) {
       
        console.log(error);

                
      });
    }


    function pesquisaPessoa(e){
        console.log(e.substring(1,6));
        setResponsavel(e.substring(1,6));
        setRazao(e.substring(9,50));
        
    }

    
   function buscaAnalitico(e){
    
    api.post(vhostV1('webcash/planoAnalitico'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        sintetico:e
      },config)
      .then(function (response) {
        setPlanoAnalitico(response.data.dados);  
        setSintetico(e);
                             
      })
      .catch(function (error) {       
        console.log(error);
      });
    }


   //se editar conta
   useEffect(function(){
    api.post(vhostV1('webcash/contas/'+id), {
        loja: localStorage.getItem('loja'),
        empresa: 0        
      },config)
      .then(function (response) {
          console.log('busquei');
          if(response && response.data && response.data.dados){
          console.log(response.data.dados);
          setResponsavel(response.data.dados.RESPONSAVEL);
          setRazao(response.data.dados.RAZAO);
          setSintetico(response.data.dados.SINTETICO);
          setValor(response.data.dados.VALOR);
          setSeqBanco(response.data.dados.SEQ_BANCO);
          setTipoCobranca(response.data.dados.TIPO_COBRANCA);
          setDocumento(response.data.dados.DOCUMENTO);
          setNossoNumero(response.data.dados.NOSSO_NUMERO);
          setDescricao(response.data.dados.DESCRICAO);
          setDataEmissao(validarData(response.data.dados.DATA_EMISSAO));
          setDataVenc(validarData(response.data.dados.DATA_VENC));
          setDataSVenc(validarData(response.data.dados.DATA_VENC))
          setTipoConta(response.data.dados.TIPO_CONTA)
          }
          else 
          {
            setDataEmissao(dataAtual);
            
          } 
                    

        }                               
      )
      .catch(function (error) {
        console.log(error);
                
      });
           
  },[])



  //buscar Sintetico
  useEffect(function(){
    api.post(vhostV1('webcash/planoSintetico'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
      },config)
      .then(function (response) {
        console.log(response.data.dados);
        setPlanoSintetico(response.data.dados)
        
      }                             
      )
      .catch(function (error) {
        console.log(error);
                
      });
 },[])


 // busca bancos
 useEffect(function(){
  api.post(vhostV1('webcash/banco'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      busca: ''  
    },config)
    .then(function (response) {
      if(response && response.data && response.data.dados){
       setbanco(response.data.dados);
       console.log(response.data.dados);           
      }}                               
    )
    .catch(function (error) {
      console.log(error);
              
    });
},[])



return (
  <div className="container-fluid">
    <Navbar />
    <div className="offset-3 col-lg-6">
      <h2>{tpc === 'R' ? 'Cadastro de Contas Receber' : 'Cadastro de Contas Pagar'}</h2>
      <div className="container-fluid">
        <form className="row tela">
          {/* Código */}
          {id !== 0 && (
            <div className="col-12 mb-3">
              <label htmlFor="inputID" className="form-label">Código</label>
              <input type="text" value={id} className="form-control" id="inputID" disabled />
            </div>
          )}

          <div className="col-5 mb-3">
            <label htmlFor="inputDocumento" className="form-label">Documento</label>
            <input type="text" value={documento} onChange={(e) => setDocumento(e.target.value)} className="form-control" id="inputDocumento" />
          </div>

          <div className="col-5 mb-3">
            <label htmlFor="inputNossoNumero" className="form-label">Nosso Número</label>
            <input type="text" value={nosso_numero} onChange={(e) => setNossoNumero(e.target.value)} className="form-control" id="inputNossoNumero" />
          </div>

          <div className="col-2 mb-3">
            <label htmlFor="inputParcelas" className="form-label">Parcelas</label>
            <input type="text" value={parcelas} onChange={(e) => setParcelas(e.target.value)} className="form-control" id="inputParcelas" />
          </div>

          <div className="col-md-12 mb-3">
            <label htmlFor="inputSacado" className="form-label">SACADO</label>
            <div className="input-group">
              <input type="text" value={razao} onChange={(e) => setRazao(e.target.value)} className="form-control" id="inputSacado" disabled />
              <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#modalPesquisa">Pesquisar</button>
            </div>

            <div className="modal fade" id="modalPesquisa" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="modalPesquisaLabel" aria-hidden="true">
              <div className="modal-dialog modal-lg">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="modalPesquisaLabel">Pesquisa Cadastro</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="input-group mb-3">
                      <input onChange={(e) => setBuscaPessoa(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
                      <button onClick={listaPessoa} className="btn btn-primary" type="button" id="button-addon2">
                        <i className="fas fa-search"></i> Pesquisar
                      </button>
                    </div>
                    {resultPessoa === 1 ? (
                      <table className="table table-hover table-striped">
                        <thead>
                          <tr>
                            <th scope="col">Código</th>
                            <th scope="col">Razão</th>
                            <th scope="col">Ação</th>
                          </tr>
                        </thead>
                        <tbody>
                          {pessoas.map(pessoa => (
                            <tr key={pessoa.COD_CADASTRO}>
                              <td>{pessoa.CNPJ}</td>
                              <td>{pessoa.RAZAO}</td>
                              <td>
                                <a href="#" onClick={() => pesquisaPessoa(`000000${pessoa.COD_CADASTRO}`.slice(-6) + ' - ' + pessoa.RAZAO)} data-bs-dismiss="modal">
                                  <i className="fas fa-edit icone-acao red"></i>
                                </a>
                              </td>
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    ) : (
                      <BuscaVazaia />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="col-12 mb-3">
            <label htmlFor="inputDescricao" className="form-label">Descrição</label>
            <textarea className="form-control" value={descricao} onChange={(e) => setDescricao(e.target.value)} id="inputDescricao" rows="3"></textarea>
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="inputTipoCobranca" className="form-label">Tipo Cobrança</label>
            <select id="inputTipoCobranca" value={tipo_cobranca} onChange={(e) => setTipoCobranca(e.target.value)} className="form-select">
              <option value={1}>CARTEIRA</option>
              <option value={2}>DUPLICATA</option>
              <option value={3}>CHEQUE</option>
            </select>
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="inputBanco" className="form-label">Banco</label>
            <select id="inputBanco" value={banco.SEQ_BANCO} onChange={(e) => setSeqBanco(e.target.value)} className="form-select">
              {banco.map(b => (
                <option key={b.SEQ_BANCO} value={b.SEQ_BANCO}>{b.DESCRICAO}</option>
              ))}
            </select>
          </div>

          <div className="col-6 mb-3">
            <label htmlFor="inputSintetico" className="form-label">Sintético</label>
            <select id="inputSintetico" value={sintetico} onChange={(e) => buscaAnalitico(e.target.value)} className="form-select">
              {planoSintetico.map(ps => (
                <option key={ps.SINTETICO} value={ps.SINTETICO}>{ps.DESCRICAO}</option>
              ))}
            </select>
          </div>

          {planoSintetico.length > 0 && (
            <div className="col-6 mb-3">
              <label htmlFor="inputAnalitico" className="form-label">Analítico</label>
              <select id="inputAnalitico" value={analitico} onChange={(e) => setAnalitico(e.target.value)} className="form-select">
                {planoAnalitico.map(pa => (
                  <option key={pa.ANALITICO} value={pa.ANALITICO}>{pa.DESCRICAO}</option>
                ))}
              </select>
            </div>
          )}

          <div className="col-12 mb-3">
            <label htmlFor="inputDataEmissao" className="form-label">Data Título</label>
            <div className="row">
              <div className="col-4 mb-3">
                <label htmlFor="inputDataEmissao" className="form-label">Emissão</label>
                <input type="date" value={dataEmissao} className="form-control" disabled />
              </div>
              <div className="col-4 mb-3">
                <label htmlFor="inputDataVencimento" className="form-label">Vencimento</label>
                <input type="date" value={dataVenc} onChange={(e) => setVencimento(e.target.value)} className="form-control" />
              </div>
              <div className="col-4 mb-3">
                <label htmlFor="inputSaldo" className="form-label">Saldo</label>
                <input type="text" value={Number(valor)} onChange={(e) => setValor(e.target.value)} className="form-control" id="inputSaldo" />
              </div>
            </div>
          </div>

          <div className="col-12">
            <Link to="/app/contas" className="btn btn-outline-primary">Desistir</Link>
            <button type="button" onClick={SalvaConta} className="btn btn-primary ms-2">Salvar</button>
          </div>

          {mensagem && <div className="alert alert-danger mt-2" role="alert">{mensagem}</div>}
          {sucesso === 'S' && <Navigate to='/app/contas' />}
        </form>
      </div>
    </div>
  </div>
);
}


export default NovaConta;