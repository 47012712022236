import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from 'pdfmake/build/vfs_fonts'

function bancosPDF(banco){
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: "Bancos",
            fontSize: 15,
            bold: true,
            margin: [15, 20, 0, 45]
        }
    ];

    const dados = banco.map((banco) => {
        return [
            {text: banco.SEQ_BANCO, fontSize: 9, margin: [0, 2, 0, 2]},
            {text: banco.BANCO, fontSize: 9, margin: [0, 2, 0, 2]},
            {text: banco.DESCRICAO, fontSize: 9, margin: [0, 2, 0, 2]}
        ]
    });
    
    const details = [
        {
            table:{
                headerRows: 1,
                widths: ['*', '*', '*'],
                body: [
                    [
                        {text: 'Seq', style: 'tableHeader', fontSize: 10},
                        {text: 'Cod.Banco', style: 'tableHeader', fontSize: 10},
                        {text: 'Descrição', style: 'tableHeader', fontSize: 10}
                    ],
                    ...dados
                ]
            },
            layout: 'headerLineOnly'
        }
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0]
            }
        ]
    }

    const docDefinitions = {
        pageSize: "A4",
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitions).open();
}

export default bancosPDF;