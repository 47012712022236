import pdfMake from 'pdfmake/build/pdfmake'
import pdfFonts from 'pdfmake/build/vfs_fonts'

function ProdutoPdf(produtos){


    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    const reportTitle = [
        {
            text: 'Relatorio de Produtos',
            fontSize: 15,
            alignment:'center',
            bold: true,
            margin: [15, 20, 0, 45] // left, top, right, bottom
        }
    ];

    const dados = produtos.map((produto) => {
        return [
            {text: produto.COD_PRODUTO, fontSize: 7, margin: [0, 2, 0, 2]},
            {text: produto.DESCRICAO, fontSize: 7, margin: [0, 2, 0, 2]},
            {text: produto.UNIDADE, fontSize: 7, margin: [0, 2, 0, 2],alignment:'center'},
            {text: Number(produto.ESTOQUE).toFixed(3), fontSize: 7, margin: [0, 2, 2, 2],alignment:'right'},
            {text: 'R$ '+Number(produto.COMPRA).toFixed(2), fontSize: 7, margin: [0, 2, 2, 2],alignment:'right'},
            {text: 'R$ '+Number(produto.VENDA).toFixed(2), fontSize: 7, margin: [0, 2, 3, 2],alignment:'right'}
           
        ] 
    });
    

    const details = [
        {
            table:{
                headerRows: 1,
                widths: [55, 250, 40, 40,40,35],
                body: [
                    [
                        {text: 'Código', style: 'tableHeader', fontSize: 10},
                        {text: 'Nome', style: 'tableHeader', fontSize: 10},
                        {text: 'Unidade', style: 'tableHeader', fontSize: 10},
                        {text: 'Estoque', style: 'tableHeader', fontSize: 10},
                        {text: 'Compra', style: 'tableHeader', fontSize: 10},
                        {text: 'venda', style: 'tableHeader', fontSize: 10}
                       
                    ],
                    ...dados
                ]
            },
            layout: 'lightHorizontalLines' // headerLineOnly
        }
    ];

    function Rodape(currentPage, pageCount){
        return [
            {
                text: currentPage + ' / ' + pageCount,
                alignment: 'right',
                fontSize: 9,
                margin: [0, 10, 20, 0] // left, top, right, bottom
            }
        ]
    }

    const docDefinitios = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],

        header: [reportTitle],
        content: [details],
        footer: Rodape
    }

    pdfMake.createPdf(docDefinitios).open();
}

export default ProdutoPdf;