import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function VendasPorFinalizadora(salesData) {  
  // Carregue as fontes do pdfmake
  pdfMake.vfs = pdfFonts.pdfMake.vfs;

  // Verifique se salesData é um array válido
  salesData = Array.isArray(salesData) ? salesData : [];
  console.log(salesData);
  
  // Agrupe os dados por FINALIZADORA usando reduce
  const groupedSalesData = salesData.reduce((acc, sale) => {
    if (!sale || !sale.FINALIZADORA) return acc; // Ignore dados inválidos

    const finalizadora = sale.FINALIZADORA;
    if (!acc[finalizadora]) {
      acc[finalizadora] = {
        itens: [],
        total: 0,
      };
    }

    acc[finalizadora].itens.push(sale);
    acc[finalizadora].total += sale.RECEBIDO ?? 0;
    return acc;
  }, {});

  // Calcule o total geral usando reduce
  const total = Object.values(groupedSalesData).reduce((sum, finalizadora) => sum + finalizadora.total, 0);

  // Defina as configurações do documento
  const docDefinition = {
    content: [
      { text: 'Relatório de Vendas por Finalizadora', style: 'header' },
      { text: 'Relatório detalhado das vendas agrupadas por finalizadora.', style: 'subheader' },
      { text: '\n' }, // Adiciona uma linha em branco para espaçamento
    ],
    styles: {
      header: {
        fontSize: 20,
        bold: true,
        alignment: 'center',
        margin: [0, 20, 0, 10],
      },
      subheader: {
        fontSize: 12,
        italics: true,
        alignment: 'center',
        margin: [0, 0, 0, 20],
      },
      sectionTitle: {
        fontSize: 14,
        bold: true,
        margin: [0, 10, 0, 5],
      },
      tableHeader: {
        bold: true,
        fontSize: 10,
        fillColor: '#f0f0f0',
      },
      tableBody: {
        fontSize: 9,
      },
      footer: {
        fontSize: 12,
        bold: true,
        alignment: 'right',
        margin: [0, 15, 0, 0],
      },
    },
  };

  // Adicione os dados agrupados ao documento
  const vendasContent = Object.entries(groupedSalesData).map(([finalizadora, finalizadoraData]) => {
    return [
      { text: 'Finalizadora: ' + (finalizadora ?? 'N/A'), style: 'sectionTitle' },
      {
        table: {
          headerRows: 1,
          widths: [120, 120, 120, 80, 60],
          body: [
            [
              { text: 'Documento', style: 'tableHeader' },
              { text: 'Caixa', style: 'tableHeader' },
              { text: 'Data', style: 'tableHeader' },
              { text: 'Valor', style: 'tableHeader' },
            ],
            ...finalizadoraData.itens.map((sale) => [
              { text: (sale.DOCUMENTO ?? 'N/A'), alignment: 'left' },
              { text: (sale.CAIXA ?? 'N/A'), alignment: 'left' },
              { text: (sale.DATA ?? 'N/A'), alignment: 'left' },
              { text: 'R$ ' + (Number(sale.RECEBIDO ?? 0)).toFixed(2), alignment: 'right' },
            ]),
          ],
        },
        layout: 'lightHorizontalLines',
        margin: [0, 0, 0, 10],
      },
      {
        text: 'Total: R$ ' + finalizadoraData.total.toFixed(2),
        style: 'tableBody',
        alignment: 'right',
        margin: [0, 0, 0, 10],
      },
    ];
  });

  // Adiciona o conteúdo das vendas ao documento
  docDefinition.content.push(...vendasContent.flat());

  // Adicione o rodapé com o total geral
  docDefinition.content.push({
    text: 'Total Geral: R$ ' + Number(total).toFixed(2),
    style: 'footer',
  });

  // Gere o documento PDF
  const pdfDocGenerator = pdfMake.createPdf(docDefinition);
  pdfDocGenerator.open();
}

export default VendasPorFinalizadora;






