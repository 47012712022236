import Modal from 'react-modal/lib/components/Modal';
import './nfe.css';
import { useEffect } from 'react';
import api from '../../services/api';
import { vhostV1 } from '../funcoes';
import { useState } from 'react';
import { NumericFormat } from 'react-number-format';


function ModalPagamento(props){

    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }
      
    
      const [finalizadoras,setFinalizadoras] = useState([]);
      const [defaultVlrPago,setDefaultVlrPago] = useState(0);
      const [vlrPago,setVlrPago] = useState(0);
      const [finalizadora,setFinalizadora] = useState();
    
    useEffect(()=>{
      api.post(vhostV1('webcash/finalizadora/ListaFinalizadoraResumida'), {
        empresa:0
      },config)
      .then(function (response) {
         setFinalizadoras(response.data.dados);                              
      })
      .catch(function (error) {
         console.log(error);
      });
      
    },[]);



    function gravaRecebimento(){
                        
      api.post(vhostV1('webcash/vendas/gravarecebimento'),{
        nota:props.nota,
        valor:vlrPago,
        finalizadora:finalizadora,
        responsavel:0,
        colaborador:99
       },config)
       .then(function (response) {
         console.log(response); 
          
         props.onRequestClose();
        })
       .catch(function (error) {
        console.log(error);
      });
    }
    
  return (
    <div>
        <Modal isOpen={props.isOpen}
        onRequestClose={props.onRequestClose}
        overlayClassName="react-modal-overlay"
        ariaHideApp={false}
        className="pagamento-modal-content">
        <div className="container-fluid">
        <div className="row">
        <h4>Finalizadora</h4> 
    
        <hr />        
        </div> 
        <h4>Valor a pagar</h4> 
        <h1>{props.vlrPagar.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}</h1> 

         <div className=" mb-3">
          
            <div className="col-md-12">
                <label htmlFor="inputState" className="form-label">Finalizadora</label>
                <select id="inputGrupo" onChange={(e) => setFinalizadora(e.target.value)} className="form-select" tabIndex="3">
                <option key={-1} >SEL. FINALIZADORA</option>
                { finalizadoras.map((pag)=> {
                    return <option key={pag.COD_FINALIZADORA} value={pag.COD_FINALIZADORA}> { pag.DESCRICAO}</option>          
                    } )}                    
                </select>
            </div>
            <div className="col-12">
            <label htmlFor="inputAddress" className="form-label">VALOR</label>
            <NumericFormat className="form-control"
                        id="inputVenda" 
                        value={defaultVlrPago.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                        tabIndex="9"
                        allowLeadingZeros
                        decimalSeparator=','
                        thousandSeparator='.'
                        decimalScale={2}
                        prefix='R$ '
                        onValueChange={(values,suorceInfo)=>{
                          setVlrPago(values.value);
                        }} 
                        
                        />
            </div>       
            
            </div>
        </div>

        <button className='btn btn-danger botao-fechar'onClick={props.onRequestClose}>Cancelar</button>
        <button className='btn btn-primary botao-fechar' onClick={gravaRecebimento}>Adicionar</button>

        </Modal>
            </div>
        )
}

export default ModalPagamento