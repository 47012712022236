import React, { useState } from 'react'
import Modal from 'react-modal/lib/components/Modal';
import './ModalPesquisa.css'
import BuscaVazaia from '../buscavazia/buscaVazia';


function ModalPesquisa(props){



  return <Modal isOpen={props.isOpen}
                onRequestClose={props.onRequestClose}
                overlayClassName="react-modal-overlay"
                ariaHideApp={false}
                className="react-modal-content">
          <div className="container-fluid pesquisa-modal">
            <div className="row">
                <h4>{props.titulo}</h4> 
                <hr />
                
                <div className="col-12">
                <div className="input-group mb-3">
                <input onChange={(e) => props.setBusca(e.target.value)} type="text" className="form-control" placeholder="Pesquisar por nome" aria-describedby="button-addon2" />
                <button onClick={props.listarPesquisa} className="btn btn-primary" type="button" id="button-addon2"><i className="bi bi-search"></i> Pesquisar</button>
               
               
                </div>
                </div>       
            </div> 

           <div className="grid-pesquisa">
           { props.itens.length > 0?
                                <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Razão</th>
                                    <th scope="col">Ação</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">
                                { props.itens.map((pessoa)=> {
                                    return <tr key={pessoa.COD_CADASTRO}>
                                        <td>{pessoa.CNPJ}</td>          
                                        <td>{pessoa.RAZAO}</td>
                                        <td>
                                          <button className='btn btn-success btn-sm ' onClick={(e) =>props.pesquisaPessoa(pessoa.COD_CADASTRO)}>
                                          <i class="bi bi-check"></i>
                                          </button>                                        
                                        </td>
                                    </tr>
                                } )}  
                                </tbody>
                            </table>:<BuscaVazaia/>}

           </div>
            
          
            <button className='btn btn-danger botao-fechar'onClick={props.onRequestClose}>Cancelar</button>
          </div>
    

    
  </Modal>
}

export default ModalPesquisa;