import pdfMake from 'pdfmake/build/pdfmake';
import pdfFonts from 'pdfmake/build/vfs_fonts';

function visualizaPdf({ dadosVenda, itens, cod, total_pedido }) {
    pdfMake.vfs = pdfFonts.pdfMake.vfs;

    // Define styles for different parts of the PDF
    const styles = {
        header: {
            fontSize: 14,
            alignment: 'center',
            bold: true,
            margin: [0, 20, 0, 10]
        },
        subheader: {
            fontSize: 11,
            bold: true,
            margin: [0, 5, 0, 5]
        },
        tableHeader: {
            bold: true,
            fontSize: 10,
            color: 'black'
        },
        tableCell: {
            fontSize: 9,
            margin: [0, 5, 0, 5]
        },
        footer: {
            fontSize: 9,
            alignment: 'right',
            margin: [0, 10, 20, 0]
        },
        dadosVendaContainer: {
            border: [true, true, true, true],
            borderColor: 'black',
            padding: [8, 8],
            margin: [0, 10, 0, 20],
            width: '*'
        },
        dadosVendaContent: {
            margin: [0, 5],
            fontSize: 9
        },
        dadosVendaTableHeader: {
            bold: true,
            fontSize: 8,
            color: 'black',
            alignment: 'left',
            margin: [0, 5, 0, 5]
        },
        dadosVendaTableCell: {
            fontSize: 8,
            margin: [0, 5],
            alignment: 'left',
        }
    };

    // Title of the PDF
    const reportTitle = {
        text: 'Dados do Pedido',
        style: 'header'
    };

    // Format rows for items
    const itemRows = itens.length ?
        itens.map(item => [
            { text: item.COD_PRODUTO || 'N/A', style: 'tableCell' },
            { text: item.DESCRICAO || 'N/A', style: 'tableCell' },
            { text: item.ITEM || 'N/A', style: 'tableCell' },
            { text: item.QTD || 'N/A', style: 'tableCell', alignment: 'right' },
            { text: `R$ ${item.VENDA}` || 'N/A', style: 'tableCell', alignment: 'right' },
            { text: `R$ ${item.TOTAL}` || 'N/A', style: 'tableCell', alignment: 'right' }
        ]) :
        [[
            { text: 'Nenhum item encontrado', colSpan: 6, style: 'tableCell' },
            {}, {}, {}, {}, {}
        ]];

    // Define content of the PDF
    const details = [
        { text: 'Dados do Pedido', style: 'subheader' },
        {
            table: {
                widths: [90, '*', 50, '*', 90, '*'],
                body: [
                    [
                        { text: 'N Pedido:', style: 'dadosVendaTableHeader' },
                        { text: cod || 'N/A', style: 'dadosVendaTableCell' },
                        { text: 'Cliente:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.RAZAO || 'N/A', style: 'dadosVendaTableCell' },
                        { text: 'Forma de Pagamento:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.FORMA_PAGAMENTO || 'N/A', style: 'dadosVendaTableCell' }
                    ],
                    [
                        { text: 'Endereço:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.ENDERECO || 'N/A', style: 'dadosVendaTableCell' },
                        { text: 'Bairro:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.BAIRRO || 'N/A', style: 'dadosVendaTableCell' },
                        { text: 'Cidade:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.CIDADE || 'N/A', style: 'dadosVendaTableCell' }
                    ],
                    [
                        { text: 'CEP:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.CEP || 'N/A', style: 'dadosVendaTableCell' },
                        { text: 'UF:', style: 'dadosVendaTableHeader' },
                        { text: dadosVenda?.ESTADO || 'N/A', style: 'dadosVendaTableCell' },
                        {}, // Empty cells to align with column count
                        {}
                    ]
                ]
            },
            style: 'dadosVendaContainer', layout: 'lightHorizontalLines'
        },

        { text: 'Itens', style: 'subheader' },
        {
            table: {
                headerRows: 1,
                widths: [40, '*', 30, 40, 40, 40],
                body: [
                    [
                        { text: 'Código', style: 'tableHeader' },
                        { text: 'Descrição', style: 'tableHeader' },
                        { text: 'Item', style: 'tableHeader' },
                        { text: 'Qtd', style: 'tableHeader', alignment: 'right' },
                        { text: 'Valor', style: 'tableHeader', alignment: 'right' },
                        { text: 'Total', style: 'tableHeader', alignment: 'right' }
                    ],
                    ...itemRows
                ]
            },
            layout: 'lightHorizontalLines'
        },
        { text: `Total Pedido: R$ ${total_pedido || 'N/A'}`, style: 'subheader', alignment: 'right' }
    ];

    // Define footer function
    function Rodape(currentPage, pageCount) {
        return [
            {
                text: `${currentPage} / ${pageCount}`,
                style: 'footer'
            }
        ];
    }

    // Document definition
    const docDefinitions = {
        pageSize: 'A4',
        pageMargins: [15, 50, 15, 40],
        header: [reportTitle],
        content: details,
        footer: Rodape,
        styles
    };

    pdfMake.createPdf(docDefinitions).open();
}

export default visualizaPdf;