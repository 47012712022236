import { useEffect, useState } from 'react';
import React from 'react'
import api from '../../../services/api';
import { vhostV1 } from '../../funcoes';


function WidgetNfe(){

    let config = {
        headers: {
           'Authorization': localStorage.getItem("token")
           }
        }


     const [totalMes,setTotalMes] = useState(0);   
     const [totalDia,setTotalDia] = useState(0);   
     const [qtdVenda,setQtdVenda] = useState(0);   
     const [tiket,setTiket] = useState(0);   



    useEffect(function(){
        api.post(vhostV1('webcash/dashboard/widget'), {
          loja: localStorage.getItem('loja'),
          empresa: 0
          },config)
        .then(function (response) {        
          
          setTotalMes(response.data.dados.total);           
          setTotalDia(response.data.dados.venda);           
          setQtdVenda(response.data.dados.qtd);           
          setTiket(response.data.dados.ticket);           
      
        })
        .catch(function (error) {
          console.log(error);
                  
        });
                
    },[])


  return <div className="row widgets" id='widget-nfe'>
                  <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-primary shadow h-100 py-2">
                          <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                  <div className="col mr-2">
                                      <div className="text-xs font-weight-bold text-primary text-uppercase mb-1">
                                          Autorizadas no Mês</div>
                                      <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(totalMes).toFixed(2)}</div>
                                  </div>
                                  <div className="col-auto">
                                      <i className="fas fa-calendar fa-2x text-gray-300"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
  
                  <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-success shadow h-100 py-2">
                          <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                  <div className="col mr-2">
                                      <div className="text-xs font-weight-bold text-success text-uppercase mb-1">
                                          Autorizadas Hoje </div>
                                      <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {Number(totalDia).toFixed(2)}</div>
                                  </div>
                                  <div className="col-auto">
                                      <i className="fas fa-dollar-sign fa-2x text-gray-300"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
  
  
                  <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-info shadow h-100 py-2">
                          <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                  <div className="col mr-2">
                                      <div className="text-xs font-weight-bold text-info text-uppercase mb-1">Ticket Médio
                                      </div>
                                      <div className="row no-gutters align-items-center">
                                          <div className="col-auto">
                                              <div className="h5 mb-0 mr-3 font-weight-bold text-gray-800">R$ {Number(tiket).toFixed(2)}</div>
                                          </div>
                                          
                                      </div>
                                  </div>
                                  <div className="col-auto">
                                      <i className="fas fa-clipboard-list fa-2x text-gray-300"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>
  
  
                  <div className="col-xl-3 col-md-6 mb-4">
                      <div className="card border-left-danger shadow h-100 py-2">
                          <div className="card-body">
                              <div className="row no-gutters align-items-center">
                                  <div className="col mr-2">
                                      <div className="text-xs font-weight-bold text-warning text-uppercase mb-1">
                                          Canceladas (Mês)</div>
                                      <div className="h5 mb-0 font-weight-bold text-gray-800">{qtdVenda}</div>
                                  </div>
                                  <div className="col-auto">
                                      <i className="fas fa-comments fa-2x text-gray-300"></i>
                                  </div>
                              </div>
                          </div>
                      </div>
                  </div>                       
   
                  
              </div>
}

export default WidgetNfe;