import React from 'react'
import "./card.css"

const handleTableClick = (tableNumber) => {
  window.alert(`Mesa ${tableNumber} clicada!`);
};

function Card({id,nome,valor}){
  return (
    <div class="card content" onClick={() => handleTableClick(id)}>
      <div class="card-body">
        <span>{id}</span>
        
      </div>
    </div>    
  )
}

export default Card;