import Navbar from "../navbar/navbar"
import { useState,useEffect } from "react";
import { useParams,Navigate } from "react-router-dom";
import { Link } from "react-router-dom";
import './nfe.css';
import api from "../../services/api";
import { vhostV1 } from "../funcoes";
import ModalPesquisa from "../components/modalPesquisa/ModalPesquisa";
import ModalProduto from "../components/modalProduto/ModalProduto";
import ModalAdicaoItem from "../components/modalAdicaoItem/ModalAdicaoItem";
import cadastros from "./cadastro";
import BuscaVazaia from "../components/buscavazia/buscaVazia";
import { NumericFormat } from "react-number-format";
import ModalPagamento from "./modalPagamento";
import Loading from "../components/loading/Loading";





function NovaNfe(){

    const inputs = document.querySelectorAll("input, select");
    // Adicione um manipulador de eventos para o evento "keydown" em cada entrada ou select
    inputs.forEach(input => {
      input.addEventListener("keydown", e => {
        // Se a tecla pressionada for Enter
        if (e.key === "Enter") {
          e.preventDefault(); // evita que a tecla "Enter" envie um formulário, se aplicável

          // Obtenha o índice do elemento atual
          const currentIndex = Array.from(inputs).indexOf(e.target);
  
          // Mova o foco para o próximo elemento de entrada ou select
          const nextIndex = currentIndex + 1;
          if (nextIndex < inputs.length) {
            inputs[nextIndex].focus();
          }
        }
      });
    });


    const {cod} = useParams();
    const {tp} = useParams();
    
   
   let config = {
      headers: {
        'Authorization': localStorage.getItem("token")
      }
    }
    
    var data = new Date();
    var dia = String(data.getDate()).padStart(2, '0');
    var mes = String(data.getMonth() + 1).padStart(2, '0');
    var ano = data.getFullYear();
    const dataAtual = dia + '/' + mes + '/' + ano;    
        
    
       {/*dados da tela */}
       const [sequencial,setSequencial] = useState();
       const [razaoEmpresa,setRazaoEmpresa] = useState('');
       const [cnpjEmpresa,setCnpjEmpresa] = useState('');
       const [fantasiaEmpresa,setFantasiaEmpresa] = useState('');
       const [inscEstadualEmpresa,setInscEstadualEmpresa] = useState('');
       const [enderecoEmpresa,setEnderecoEmpresa] = useState('');
       const [nrEmpresa,setNrEmpresa]= useState('');
       const [ufEmpresa,setUfEmpresa] = useState('');
       const [cepEmpresa,setCepEmpresa] =useState('');
       const [bairroEmpresa,setBairroEmpresa] = useState('');
       const [cidadeEmpresa,setCidadeEmpresa] = useState('');
       const [telefoneEmpresa,setTelefoneEmpresa] = useState('');
       const [load,setLoad] = useState(false);

       const [razao,setRazao] = useState('');
       const [cnpj,setCnpj]   = useState('');
       const [fantasia,setFantasia] = useState('');
       const [inscEstadual,setInscEstadual]   = useState('');
       const [inscMunicipal,setInscMunicipal] = useState('');
       const [endereco,setEndereco] = useState('');
       const [nr,setNr]         = useState('');
       const [uf,setUf]         = useState('');
       const [cep,setCep]       = useState('');
       const [bairro,setBairro] = useState('');
       const [cidade,setCidade] = useState('');
       const [telefone,setTelefone] = useState('');
       const [complemento,setComplemento] = useState('');       
       const [matriz,setMatriz]           = useState('');
       const [indPerfil,setIndPerfil]     = useState('');
       const [contato,setContato]         = useState('');       
       const [sucesso,setSucesso]         = useState('N');
       const [mensagem, setMensagem]      = useState('');

       const [pessoas,setPessoas] = useState([]);
      
       const [isPesquisaOpen,setIsPesquisaOpen] = useState(false);
       const [isFinalizadoraOpen,setIsFinalizadoraOpen] = useState(false);
       const [totalBaseCalcIcms,setTotalBaseCalcIcms] = useState(0);
       const [totalBcIcmsSub,setTotalBcIcmsSub] = useState(0);
       const [totalDespesas,setTotalDespesas] = useState(0);
       const [totalFrete,setTotalFrete] = useState(0);
       const [totalIcms,setTotalIcms] = useState(0);
       const [totalIPI,setTotalIPI] = useState(0);
       const [totalNota,setTotalNota] = useState(0);
       const [totalProduto,setTotalProduto] = useState(0);
       const [totalSeguro,setTotalSeguro] = useState(0);
       const [id,setId] = useState();
       const [respItens,setRespItens] = useState([]);
       const [pagamento,setPagamento] = useState([]);
       

       function openModalPesquisa(){
          setIsPesquisaOpen(true)
       }

       function closeModalPesquisa(){
          setIsPesquisaOpen(false)
       }


       const [isProdutoOpen,setIsProdutoOpen] = useState(false);

       function openModalProduto(){
          setIsProdutoOpen(true)
       }

       function closeModalProduto(){
          setIsProdutoOpen(false)
       }

       const [isAdicaoOpen,setIsAdicaoOpen] = useState(false);

       function openModalAdicao(){
          setIsAdicaoOpen(true)
       }

      async function closeModalAdicao(){
         setIsAdicaoOpen(false)          
         retornaItens(cod);   
         retornaNfe(cod);
       }

     
      

       function closeModalFinalizadora(){
        setIsFinalizadoraOpen(false);
        retornaRecebimento(cod);
        }

      function openModalFinalizadora(){
        setIsFinalizadoraOpen(true)
      }

       
       const [busca,setBusca] = useState('');
       const [codBusca,setcodBusca] = useState(0);
       const [codProduto,setcodProduto] = useState('');
       const [retornoItem,setRetornoItem] = useState([]);
       const [buscaProdut,setBuscaProduto] = useState();
       const [produtos,setProdutos] = useState([]);
       const [pesquisaProduto,setPesquisaProduto] = useState('');
       const [diferenteZero,setDiferenteZero] = useState(false);
       const [estoqueNegativo,setEstoqueNegativo] = useState(false);
       const [vendaZerada,setVendaZerada] = useState(false);
       const [prodInativo,setProdInativo] = useState(false);
       const [serieNFE,setSerieNFE] = useState('');
       const [nNfe ,setNNfe ] = useState('');

       function adicionaCliente(e){
                         
        api.post(vhostV1('webcash/vendas/adicionacliente '), {
          pedido:sequencial,
          cliente:e,
          tipo:"N"
         },config)
         .then(function (response) {
           console.log(response.data);                               
                    
         })
         .catch(function (error) {
         
           console.log(error);   
                   
         });        
     }     
       
  

  async function  retornaItens(id){        
        try {
        const respProdutos = await api.get(vhostV1('webcash/nfe/retornaitem/'+id),config)
        
        if(respProdutos && respProdutos.data && respProdutos.data.dados ){
            console.log(respProdutos.data.dados) 
            setRetornoItem(respProdutos.data.dados);
        }else{
            setRetornoItem([]);  
        }
       } catch (error) {
        console.log(error.message)
        setRetornoItem([])
       }       
        
        

   }


   function  retornaEmpresa(){
    api.post(vhostV1('webcash/empresa/retornaEmpresa'), {
        empresa:0                       
    },config)
    .then(function (response) {        
        setRazaoEmpresa(response.data.dados.RAZAO);
        setCnpjEmpresa(response.data.dados.CNPJ);
        setFantasiaEmpresa(response.data.dados.FANTASIA);
        setEnderecoEmpresa(response.data.dados.ENDERECO);
        setNrEmpresa(response.data.dados.NR);
        setUfEmpresa(response.data.dados.UF);
        setCepEmpresa(response.data.dados.CEP);
        setBairroEmpresa(response.data.dados.BAIRRO);
        setCidadeEmpresa(response.data.dados.CIDADE);
        setTelefoneEmpresa(response.data.dados.TELEFONE);
        }                               
    )
    .catch(function (error) {
        console.log(error);
                
    }); 

}

   function filtraProdutos(e){
    console.log(pesquisaProduto)
    e.preventDefault();
    const sJson = {}
    sJson.loja=localStorage.getItem('loja'); 
    sJson.empresa=0;
    sJson.grupo=0;
    sJson.subgrupo=0;
    sJson.linha=0;
    sJson.estoque_diferente=diferenteZero
    sJson.estoque_negativo=estoqueNegativo;
    sJson.preco_zerado=vendaZerada;
    sJson.inativo=prodInativo;
    sJson.pagina= 1;
    sJson.regPag=10;
    sJson.busca= pesquisaProduto;       
    
      api.post(vhostV1('webcash/produto/listar'),sJson,config)
        .then(function (response) {
            if(response && response.data && response.data.dados ){
                console.log(response.data.dados)         
                setProdutos(response.data.dados);  
            }else{ 
            setProdutos([]);  
        }
                                     
        })
        .catch(function (error) {
          console.log(error);                  
        });
         
  }


   function listaPessoa(e){
    e.preventDefault();
    api.post(vhostV1('webcash/cadastro'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        tipo:"%",
        busca: busca
      },config)
      .then(function (response) {
        setPessoas(response.data.dados);  
        console.log(response.data.dados);                        
      })
      .catch(function (error) {       
        console.log(error);                
      });
    }

   function pesquisaPessoa(id){
    //adicionaCliente(e);
      // console.log(id);
            api.post(vhostV1('webcash/cadastro/'+id), {
            loja: localStorage.getItem('loja'),
            empresa: 0,
            },config)
            .then(function (response) {
            setRazao(response.data.dados.RAZAO); 
            setCnpj(response.data.dados.CNPJ);
            setFantasia(response.data.dados.FANTASIA); 
            setBairro(response.data.dados.BAIRRO);
            setNr(response.data.dados.NREND1);
            setCidade(response.data.dados.CIDADE);
            setUf(response.data.dados.ESTADO);
            setTelefone(response.data.dados.TELEFONE); 
            setCep(response.data.dados.CEP);
            setInscMunicipal(response.data.dados.MUNICIPAL);
            setInscEstadual(response.data.dados.ESTADUAL);
            setEndereco(response.data.dados.ENDERECO);
            adicionaCliente(id)
                 
            })
            .catch(function (error) {
            
            console.log(error);

                    
            });

            setIsPesquisaOpen(false) 

  }



  function retornaNfe(id){
  
        api.get(vhostV1('webcash/nfe/buscanfe/'+id),config)
          .then(function (response) {        
            console.log(response.data);
           setTotalBaseCalcIcms(response.data.dados.NFE_W_VBC);
            setTotalBcIcmsSub(response.data.dados.NFE_W_VBCST);
            setTotalDespesas(response.data.dados.NFE_W_VOUTRO);
            setTotalFrete(response.data.dados.NFE_W_VFRETE);
            setTotalIcms(response.data.dados.NFE_W_VICMS);
            setTotalIPI(response.data.dados.NFE_W_VIPI);
            setTotalNota(response.data.dados.NFE_W_VNF);
            setTotalProduto(response.data.dados.NFE_W_VPROD);
            setTotalSeguro(response.data.dados.NFE_W_VSEG);
            }                               
          )
          .catch(function (error) {
            console.log(error);
                    
          });    
    }

    function retornaRecebimento(id){

        api.get(vhostV1('webcash/vendas/retornarec/'+id),config)
          .then(function (response) {        
            console.log(response.data.dados.ITENS);
            setPagamento(response.data.dados.ITENS);             
            }                               
          )
          .catch(function (error) {
            console.log(error);                    
          });    
    }   

    useEffect(() =>{ 
        setLoad(true);
        let response;       
       
            (async () => {
            try{
                response = await api.get(vhostV1('webcash/nfe/buscanfe/'+cod),config);
                if(response && response.data && response.data.dados ){
                    setSequencial(response.data.dados.NFE_B_CNF)
                    setTotalBaseCalcIcms(response.data.dados.NFE_W_VBC);
                    setTotalBcIcmsSub(response.data.dados.NFE_W_VBCST);
                    setTotalDespesas(response.data.dados.NFE_W_VOUTRO);
                    setTotalFrete(response.data.dados.NFE_W_VFRETE);
                    setTotalIcms(response.data.dados.NFE_W_VICMS);
                    setTotalIPI(response.data.dados.NFE_W_VIPI);
                    setTotalNota(response.data.dados.NFE_W_VNF);
                    setTotalProduto(response.data.dados.NFE_W_VPROD);
                    setTotalSeguro(response.data.dados.NFE_W_VSEG);                    
                    pesquisaPessoa(response.data.dados.NFE_C_CODDESTINATARIO);
                    retornaItens(cod);
                    retornaRecebimento(cod);
                    
                    
                }
            }catch(err){
               
                console.log(err.mensagem);
            }
        })()
  

        setLoad(false);

            
        },[]) 


  function abreLancaItem(cod){
    setcodProduto(cod);  
    setIsProdutoOpen(false)
    setIsAdicaoOpen(true)
  }   
      
       
    
  return <div className="conteiner-fluid titulo ">
  <Navbar/>

  <ModalPesquisa isOpen={isPesquisaOpen}
                 onRequestClose={closeModalPesquisa}
                 pesquisaPessoa={pesquisaPessoa}
                 listarPesquisa={listaPessoa}
                 setBusca={setBusca}
                 busca={busca}
                 itens={pessoas}
                 titulo="pesquisa de Cadastro"
                 />

   <ModalProduto isOpen={isProdutoOpen}
                 onRequestClose={closeModalProduto}
                 listarPesquisa={filtraProdutos}
                 setPesquisaProduto={setPesquisaProduto}
                 pesquisaProduto={pesquisaProduto}
                 abreLancaItem={abreLancaItem}
                 itens={produtos}
                 titulo="pesquisa de Produto"
                 />               
     <ModalAdicaoItem isOpen={isAdicaoOpen}
                 onRequestClose={closeModalAdicao}
                 idVenda={sequencial}
                 codProduto={codProduto}
                 setIsAdicaoOpen={setIsAdicaoOpen}
                 titulo="Adicionar Item NFE"
                 />      
    <ModalPagamento isOpen={isFinalizadoraOpen}
                 onRequestClose={closeModalFinalizadora}
                 idVenda={sequencial}
                 itens={produtos} 
                 nota={sequencial}
                 vlrPagar={totalNota}              
                 
                 />           



  <div className="offset-lg-2 col-lg-8">
        { load === true?<Loading/>:null}  
         <hr />
         <div className="conteiner-fluid">
            <nav>
             <div className="nav nav-tabs" id="nav-tab" role="tablist">
                 <button className="nav-link active" id="nav-home-tab" data-bs-toggle="tab" data-bs-target="#nav-home" type="button" role="tab" aria-controls="nav-home" aria-selected="true">Dados Nota</button>
                 <button className="nav-link" id="nav-profile-tab" data-bs-toggle="tab" data-bs-target="#nav-profile" type="button" role="tab" aria-controls="nav-itens" aria-selected="false">Itens da Nota</button>
                 <button className="nav-link" id="nav-payment-tab" data-bs-toggle="tab" data-bs-target="#nav-payment" type="button" role="tab" aria-controls="nav-payment" aria-selected="false">Forma de Pagamento</button>
                 
             </div>
            </nav>

             <div className="tab-content" id="nav-tabContent">
             <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab" tabIndex="0">
             
             <div className="row">
                 <div className="col-8">
                     <label htmlFor="inputAddress" className="form-label">ID NFE</label>
                     <input type="text"  value={id}  className="form-control" id="inputDescricao" tabIndex="1" required autoComplete="off"/>
                 </div>
                 
                 <div className="col-4">
                     <label htmlFor="inputAddress" className="form-label">AUTORIZAÇÃO</label>
                     <input type="text"   className="form-control" id="inputDescricao" tabIndex="1" required autoComplete="off"/>
                 </div>
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">SEQUENCIAL</label>
                     <input type="text" value={sequencial} onChange={(e)=> setSequencial(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">N SÉRIE</label>
                     <input type="text" value={serieNFE} onChange={(e)=> setSerieNFE(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">N NFE-e</label>
                     <input type="text" value={nNfe} onChange={(e)=> setNNfe(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
                 <div className="col-md-2">
                     <label htmlFor="inputState" className="form-label">IND PERFIL</label>
                     <select id="inputUnidade" value={indPerfil} onChange={(e)=> setIndPerfil(e.target.value)} className="form-select" tabIndex="3" >
                         <option value={1}>SIM</option>
                         <option value={0}>NÃO</option>
                         
                     </select>
                 </div>
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">EMISSÃO</label>
                     <input type="text" value={dataAtual} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
             </div>
             {/*<hr />
             <div className="row">
                 <div className="col-9">
                     <label htmlFor="inputAddress" className="form-label">RAZÃO</label>
                     <input type="text" value={razaoEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CNPJ</label>
                     <input type="text" value={cnpjEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>
                 <div className="col-8">
                     <label htmlFor="inputAddress" className="form-label">FANTASIA</label>
                     <input type="text" value={fantasiaEmpresa} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">INS. ESTADUAL </label>
                     <input type="text" value={inscEstadual} onChange={(e)=> setInscEstadual(e.target.value)} className="form-control" id="inputCompra" tabIndex="3" required autoComplete="off"/>
                 </div> 
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">INS. MUNICIPAL </label>
                     <input type="text" value={inscEstadual} onChange={(e)=> setInscEstadual(e.target.value)} className="form-control" id="inputCompra" tabIndex="3" required autoComplete="off"/>
                 </div>
             </div>
             
             <div className="row">
                     <div className="col-8">
                     <label htmlFor="inputAddress" className="form-label">ENDERECO</label>
                     <input type="text" value={enderecoEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>  
                 <div className="col-1">
                     <label htmlFor="inputAddress" className="form-label">NR </label>
                     <input type="text" value={nrEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>  
                 <div className="col-1">
                     <label htmlFor="inputAddress" className="form-label">UF </label>
                     <input type="text" value={ufEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>  
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">CEP </label>
                     <input type="text" value={cepEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
             </div>
             <div className="row">
                     <div className="col-4">
                     <label htmlFor="inputAddress" className="form-label">BAIRRO</label>
                     <input type="text" value={bairroEmpresa} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CIDADE</label>
                     <input type="text" value={cidadeEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                     <input type="text" value={telefoneEmpresa}  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CONTATO</label>
                     <input type="text"  className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>        
                </div>*/}
                    
             
                <button type="submit"  className="btn btn-primary butao " tabIndex="13">lista de Ref</button>
                        
              <hr />
             <div className="row">
                 <div className="col-9">
                       <label htmlFor="inputCity" className="form-label">CLIENTE</label>
                        <div className="input-group mb-3">
                           <input type="text"  value={razao} className="form-control" id="inputNcm" tabIndex="7" disabled/>
                            <button type="button" className="btn btn-secondary" onClick={(e)=>setIsPesquisaOpen(true)}>
                            pesquisar
                            </button>
                        </div>
                 </div> 
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CNPJ</label>
                     <input type="text" value={cnpj} onChange={(e)=> setFantasia(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>
                 <div className="col-8">
                     <label htmlFor="inputAddress" className="form-label">FANTASIA</label>
                     <input type="text" value={fantasia} onChange={(e)=> setFantasia(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div> 
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">INS. ESTADUAL </label>
                     <input type="text" value={inscEstadual} onChange={(e)=> setInscEstadual(e.target.value)} className="form-control" id="inputCompra" tabIndex="3" required autoComplete="off"/>
                 </div> 
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">INS. MUNICIPAL </label>
                     <input type="text" value={inscMunicipal} onChange={(e)=> setInscMunicipal(e.target.value)} className="form-control" id="inputCompra" tabIndex="3" required autoComplete="off"/>
                 </div>
             </div>
             
             <div className="row">
                     <div className="col-8">
                     <label htmlFor="inputAddress" className="form-label">ENDERECO</label>
                     <input type="text" value={endereco} onChange={(e)=> setEndereco(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>  
                 <div className="col-1">
                     <label htmlFor="inputAddress" className="form-label">NR </label>
                     <input type="text" value={nr} onChange={(e)=> setNr(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>  
                 <div className="col-1">
                     <label htmlFor="inputAddress" className="form-label">UF </label>
                     <input type="text" value={uf} onChange={(e)=> setUf(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>  
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">CEP </label>
                     <input type="text" value={cep} onChange={(e)=> setCep(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
             </div>
             <div className="row">
                     <div className="col-4">
                     <label htmlFor="inputAddress" className="form-label">BAIRRO</label>
                     <input type="text" value={bairro} onChange={(e)=> setBairro(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CIDADE</label>
                     <input type="text" value={cidade} onChange={(e)=> setCidade(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">TELEFONE</label>
                     <input type="text" value={telefone} onChange={(e)=> setTelefone(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>       
                 <div className="col-3">
                     <label htmlFor="inputAddress" className="form-label">CONTATO</label>
                     <input type="text" value={contato} onChange={(e)=> setContato(e.target.value)} className="form-control" id="inputCompra" tabIndex="2" required autoComplete="off"/>
                 </div>        
             </div>

             
             </div>{/*fim da div aba1*/}
             <div className="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-itens-tab" tabIndex="0">
                 <div className="conainer-fluid h-100 conteudo">
                 <div className="row produtos">             
                 
                                <table className="table table-hover table-striped">
                                <thead>
                                <tr>
                                    <th scope="col">Codigo</th>
                                    <th scope="col">Descrição</th>
                                    <th scope="col">Item</th>
                                    <th scope="col">Qtd</th>
                                    <th scope="col">Venda</th>
                                    <th scope="col">Total</th>
                                    </tr>
                                </thead>
                                <tbody className="table-group-divider">   
                 { retornoItem.map((itens)=> {
                                    return <tr key={itens.ITEM}>
                                        <td>{itens.COD_PRODUTO}</td>          
                                        <td>{itens.DESCRICAO}</td>
                                        <td>{itens.ITEM}</td>
                                        <td>{itens.QTD}</td>
                                        <td>{itens.VENDA}</td>
                                        <td>{itens.TOTAL}</td>
                                        
                                    </tr>
                                } )}  
                                </tbody>
                            </table>
                    
                 </div>
                 <hr />
                 <div className="row">
                 
                          <button onClick={(e)=>setIsProdutoOpen(true)} className="btn btn-primary butao">incluir</button>                
                    

                 <div className="row d-flex">
                  
                     <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">BASE CALC ICMS</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalBaseCalcIcms.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>       
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">VALOR ICMS</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalIcms.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>       
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">BC ICMS SUB</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalBcIcmsSub.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>       
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">FRETE</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalFrete.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div> 

                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">SEGURO</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalSeguro.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>
                 
                         
                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">OUT DESPESAS</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalDespesas.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>   

                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">VALOR IPI</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalIPI.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>  

                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">TOTAL PRODUTOS</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalProduto.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>  

                 <div className="col-2">
                     <label htmlFor="inputAddress" className="form-label">TOTAL NOTA</label>
                     <NumericFormat className="form-control"
                                      id="inputVenda" 
                                      value={totalNota.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}
                                      tabIndex="9"
                                      allowLeadingZeros
                                      decimalSeparator=','
                                      thousandSeparator='.'
                                      decimalScale={2}
                                      prefix="R$ "
                                      />
                 </div>        
                 
                 </div>  
                 </div>
                 </div>
             </div>{/*fim da Aba produtos*/}
             {/*inicio da Aba Pagamentos*/}
             <div className="tab-pane fade" id="nav-payment" role="tabpanel" aria-labelledby="nav-payment-tab" tabIndex="0">
                 <div className="conainer-fluid h-100 conteudo">
                 <h1>Forma de Pagamentos</h1>
        
              <hr />

            <div className="row">
            <div className="col-4">
                <button onClick={openModalFinalizadora} className="btn btn-primary btn-cli" type="button"><i className="bi bi-plus-circle-fill"></i> Adicionar</button>
            </div>
            </div>
                
                  <hr />
                  <table className="table table-hover table-striped">
            <thead>
            <tr>
                <th scope="col">cod</th>
                <th scope="col">descricao</th>
                <th scope="col">Valor</th>
                <th scope="col">Ação</th>
                </tr>
            </thead>
            <tbody className="table-group-divider">
              { 
                 pagamento.map((pag)=> {
                  return <tr key={pag.SEQ}>                      
                      <td>{pag.COD_FINALIZADORA}</td>
                      <td>{pag.FINALIZADORA}</td>
                      <td>{pag.VALOR.toLocaleString("pt-br", {style:"currency", currency:"BRL"})}</td>
                      <td>
                      <i className="bi bi-trash-fill red"></i>
                      </td>
                  </tr>
              } )}  
            </tbody>
          </table>

                 
                 </div>
             </div>       


             
              
             </div>   
         <form className="row tela" onSubmit="return false;">
                 
                
            
            <div className="col-12 ">
            <hr />
                <Link to="/app/nfe" type="submit" className="btn btn-outline-primary butao"  tabIndex="14">Desistir</Link>
                <Link to="/app/nfe" type="submit"  className="btn btn-primary butao" tabIndex="13">Salvar</Link>
            </div>

            {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
            {sucesso === 'S' ? <Navigate to='/app/nfe' /> : null}

            </form> 
                  
          
     </div>
 </div>  
</div>







}

export default NovaNfe