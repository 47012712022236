import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Navbar from '../../navbar/navbar'; 
import { api } from '../../../services/api';
import './contas.css';
import { vhostV1 } from '../../funcoes';
import BuscaVazaia from '../../components/buscavazia/buscaVazia';
import ContasPdf from '../../reports/contas/listacontas';
import customizeScrollbar from "customsb";
import SweetAlert from 'react-bootstrap-sweetalert';



function Contas() {


     const [texto,setTexto] = useState('');
     const [busca,setBusca] = useState("");
     const [contas,setContas] = useState([]);
     const [tipoConta,setTipoConta] = useState('%');
     const [sResult,setSresult] = useState(0);
     const [marcado,setMarcado] = useState(false);
     const [bancos,setBancos] = useState([]);
     const [seq_banco,setSeqBanco] = useState([]);
     const [dataInicial, setDataInicial] = useState('');
     const [dataFinal, setDataFinal] = useState('');
     const [filteredData, setFilteredData] = useState([]);
     const [dataEmissaoInicial, setDataEmissaoInicial] = useState('');
     const [dataEmissaoFinal, setDataEmissaoFinal] = useState('');
     const [dataVencimentoInicial, setDataVencimentoInicial] = useState('');
     const [dataVencimentoFinal, setDataVencimentoFinal] = useState('');
     const [dataPagamentoInicial, setDataPagamentoInicial] = useState('');
     const [dataPagamentoFinal, setDataPagamentoFinal] = useState('');
     const [orderCriteria, setOrderCriteria] = useState("SEQ");
     const [contasOriginais, setContasOriginais] = useState([]);
     const [pessoas,setPessoas] = useState([])
     const [buscaPessoa,setBuscaPessoa] = useState('');
     const [resultPessoa,setResultPessoa] = useState(0);
     const [responsavel,setResponsavel] = useState(0);
     const [razao,setRazao] = useState('');
     const [msgErro, setmsgErro] = useState('');
     const [erroValidacao, setErroValidacao] = useState(false);
     const [filtroStatus, setFiltroStatus] = useState({
      todos: true,  // "Todos" é o padrão e não deve ser desmarcado
      emAberto: false,
      parcial: false,
      pagos: false,
      vencidos: false,
      cancelados: false,
    });




     let contasQuitar = [];
     let valorQuitar = 0;

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  // buscar pessoa

  function pesquisaPessoa(e){
    console.log(e);
    
    
    console.log(e.substring(1,6));
    setResponsavel(e.substring(1,6));
    setRazao(e.substring(9,50));
    
}

const clearFilters = () => {
  // Redefinir os filtros de data
  setDataEmissaoInicial('');
  setDataEmissaoFinal('');
  setDataVencimentoInicial('');
  setDataVencimentoFinal('');
  setDataPagamentoInicial('');
  setDataPagamentoFinal('');
  setRazao('');

  // Redefinir os filtros de status
  setFiltroStatus({
    todos: true,
    emAberto: false,
    parcial: false,
    pagos: false,
    vencidos: false,
    cancelados: false
  });

  // Redefinir a ordem de filtragem
  setOrderCriteria('SEQ'); // Ou o valor padrão desejado

  // Restaurar a lista completa de contas
  setContas(contasOriginais); // Presumindo que `contas` é a lista original de contas
  setFilteredData(contasOriginais); // Atualiza o estado dos dados filtrados
};
 

function listaPessoa(e){
  e.preventDefault();
  api.post(vhostV1('webcash/cadastro'), {
      loja: localStorage.getItem('loja'),
      empresa: 0,
      tipo:"%",
      busca: buscaPessoa
    },config)
    .then(function (response) {
      setPessoas(response.data.dados);  
      setResultPessoa(response.data.result);
      console.log(response.data.dados);                                  
               
    })
    .catch(function (error) {
     
      console.log(error);

              
    });
  }
// function closeModal(modalId) {
//     var modalElement = document.getElementById(modalId);
//     var modalInstance = bootstrap.Modal.getInstance(modalElement);
//     if (modalInstance) {
//         modalInstance.hide();
//     } else {
//         console.error(`No Bootstrap modal instance found for ID '${modalId}'`);
//     }
// }

  

  // busca bancos
 useEffect(function(){
  api.post(vhostV1('webcash/banco'), {
      loja: 11,
      empresa: 0  
    },config)
    .then(function (response) {
      if(response && response.data && response.data.dados){
       setBancos(response.data.dados);
       console.log(response.data.dados);           
      }}                               
    )
    .catch(function (error) {
      console.log(error);
              
    });
},[])



       // Aplicando a personalização com opções diferentes
// Abaixo é somente um exemplo de personalização, adapte para o seu projeto
useEffect(() => {
  // O uso do useEffect garante que a função seja chamada apenas no lado do cliente
  customizeScrollbar({
    scrollbarWidth: "10px",
    trackBackground: "#333",
    thumbBackground:
      "linear-gradient(178.8deg, #ffa066 47.41%, #ff9666 65.18%, #ff783a 90.84%)",
    thumbBorderRadius: "10px",
  });
}, []);


   useEffect(function checkAll(){    
     var items = document.getElementsByName('brand');
       for (var i = 0; i < items.length; i++) {
           if (items[i].type == 'checkbox')
               items[i].checked = marcado;
       }
     },[marcado]);

      function marcaLinha(e){
        
        valorQuitar =  Number(valorQuitar) + Number(e.target.value);
          {e.target.checked===true?contasQuitar.push(e.target.id):contasQuitar.pop()}
        
        }
  
  
  

  //buscar Contas
  useEffect(function(){
      api.post(vhostV1('webcash/contas'), {
          loja: localStorage.getItem('loja'),
          empresa: 0,
          busca: busca,
          tipo:tipoConta,
          colaborador:0
        },config)
        .then(function (response) { 
          
           setSresult(response.data.result); 
           setContas(response.data.dados);
           setContasOriginais(response.data.dados);
           console.log(response.data.dados);
           setTipoConta(localStorage.getItem("tipoconta"))         
          }                               
        )
        .catch(function (error) {
          console.log(error);
                  
        });
  

        
    },[tipoConta])


    const hoje = new Date();
    
    

    const formatDateToDMY = (date) => {
      if (!(date instanceof Date) || isNaN(date)) return '';
      const day = date.getDate().toString().padStart(2, '0');
      const month = (date.getMonth() + 1).toString().padStart(2, '0');
      const year = date.getFullYear();
      return `${day}/${month}/${year}`;
    };

    // Função para converter a data no formato 'DD/MM/YYYY' para um objeto Date
    const parseDateFromDMY = (dateStr) => {
      if (!dateStr || dateStr.trim() === '') return null;
      const [day, month, year] = dateStr.split('/').map(Number);
      return new Date(Date.UTC(year, month - 1, day));
    };

    const parseDateFromISO = (dateStr) => {
      if (!dateStr || dateStr.trim() === '') return null;
      const [year, month, day] = dateStr.split('-').map(Number);
      return new Date(Date.UTC(year, month - 1, day));
    };
    
    

    const handleFilter = () => {
      const startDateEmissao = parseDateFromISO(dataEmissaoInicial);
  const endDateEmissao = parseDateFromISO(dataEmissaoFinal);
  const startDateVencimento = parseDateFromISO(dataVencimentoInicial);
  const endDateVencimento = parseDateFromISO(dataVencimentoFinal);
  const startDatePagamento = parseDateFromISO(dataPagamentoInicial);
  const endDatePagamento = parseDateFromISO(dataPagamentoFinal);
  
      const today = formatDateToDMY(hoje);
      console.log('Venc:' + endDateVencimento);
      console.log('data:' + dataEmissaoInicial);
      
     
     
      
    
      const result = contas.filter(conta => {
        const emissionDate = parseDateFromDMY(conta.DATA_EMISSAO);
        const dueDate = parseDateFromDMY(conta.DATA_VENC);
        const paymentDate = conta.DATA_PAGAMENTO ? parseDateFromDMY(conta.DATA_PAGAMENTO) : null;
        const valorPago = conta.VALOR_PAGO;
        const valor = conta.VALOR;
        const cancelado = conta.CANCELADO;
    
        const isEmAberto = valorPago === 0;
        const isParcial = valorPago > 0 && valorPago < valor;
        const isPagos = valorPago === valor;
        const isVencidos = dueDate < hoje;
        const isCancelados = cancelado === 'T';
        console.log('Item Dates: ', {
          emissionDate,
          dueDate,
          paymentDate,
        });
       if(startDateEmissao > endDateEmissao){
        setmsgErro('data inicial da emissão é maior que a data final da emissão')
        setErroValidacao(true)
       }else if(startDateVencimento > endDateVencimento){
        setmsgErro('data inicial do vencimento é maior que a data final do vencimento')
        setErroValidacao(true)
       }else if(startDatePagamento > endDatePagamento){
        setmsgErro('data inicial de pagamento é maior que a data final de pagamento')
        setErroValidacao(true)
       }else{
       
        
       
        
    
        return (
          (filtroStatus.todos || 
            (filtroStatus.emAberto && isEmAberto) ||
            (filtroStatus.parcial && isParcial) ||
            (filtroStatus.pagos && isPagos) ||
            (filtroStatus.vencidos && isVencidos) ||
            (filtroStatus.cancelados && isCancelados)) &&
            (!startDateEmissao || (emissionDate && emissionDate >= startDateEmissao)) &&
            (!endDateEmissao || (emissionDate && emissionDate <= endDateEmissao)) &&
            (!startDateVencimento || (dueDate && dueDate >= startDateVencimento)) &&
            (!endDateVencimento || (dueDate && dueDate <= endDateVencimento)) &&
            (!startDatePagamento || (paymentDate && paymentDate >= startDatePagamento)) &&
            (!endDatePagamento || (paymentDate && paymentDate <= endDatePagamento)) &&
          (!Number(responsavel) || (conta.RESPONSAVEL && conta.RESPONSAVEL === Number(responsavel)))
        );
      }});

      if(result.lenght === 0){
        console.log("fui");
        
        setmsgErro('Não foram encontradas contas para as filtragens especificas')
        setErroValidacao(true)
      }else{
        console.log(result.length);
      }
      console.log(result);
      
      
      

      

      const sortedResult = (result.length > 0 ? result : contas).sort((a, b) => {
        switch (orderCriteria) {
          case 'SEQ':
            return a.SEQ - b.SEQ;
          case 'DATA_VENC':
            return new Date(a.DATA_VENC.split('/').reverse().join('-')) - new Date(b.DATA_VENC.split('/').reverse().join('-'));
          case 'DATA_PAGAMENTO':
            return (a.DATA_PAGAMENTO ? new Date(a.DATA_PAGAMENTO.split('/').reverse().join('-')) : Infinity) -
                   (b.DATA_PAGAMENTO ? new Date(b.DATA_PAGAMENTO.split('/').reverse().join('-')) : Infinity);
          case 'VALOR':
            return a.VALOR - b.VALOR;
          case 'RAZAO':
            return a.RAZAO.localeCompare(b.RAZAO); 
          default:
            return 0;
        }
      });

      console.log('Filtered Results:', sortedResult);
      setContas(sortedResult);
      setFilteredData(sortedResult);
    };
    
    
    



    function salvatpc(e){
 
      setTipoConta(e);
      localStorage.setItem("tipoconta", e)  
  
    }

    
    return <div>
        <Navbar/>
        <div className="container-fluid titulo offset-lg-1 col-lg-10">
        <h1>Contas</h1>
        <hr />

        

        <div className="row">
          <div className="col-12 d-flex">
            {tipoConta === '%'?null:
            <Link to={'/app/novaConta/'+tipoConta+'/'+0} className="btn btn-primary btn-cli me-2 custom-btn"  type="button"><i className="fas fa-plus"></i> Conta</Link>
            }
           <button onClick={(e) => ContasPdf(contas)} className="btn btn-danger btn-cli me-2" type="button" id="button-addon2"><i className="bi bi-printer-fill"></i> Relatório</button>
           {/* <button type="button" className="btn btn-success" data-bs-toggle="modal" data-bs-target="#exampleModal">
           <i className="bi bi-coin"></i> quitar contas
            </button> */}
            <button type="button" className="btn btn-secondary btn-cli " id='button-addon2' data-bs-toggle="modal" data-bs-target="#moda-filtro"><i className="bi bi-funnel-fill"></i>Filtros</button>
            <div>

          <div>
            <div class="custom-select-container me-2">
             <h5 className='me-2'>Tipo </h5>   
             
            
             <select id="inputUnidade" value={tipoConta} onChange={(e)=> salvatpc(e.target.value)} className="form-select custom-select " tabIndex="3" >
                <option value={'R'}>RECEBER</option>
                <option value={'P'}>PAGAR</option>
                <option value={'%'}>TODOS</option>                    
               </select>
          
            
           
           </div>

           
                  
             </div>

             

</div>
             

            
            <div className="modal fade " id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
              <div className="modal-dialog">
                <div className="modal-content">
                  <div className="modal-header">
                    <h1 className="modal-title fs-5" id="exampleModalLabel">Modal title</h1>
                    <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div className="modal-body">
                    <div className="row">
                      <label htmlFor="">Tipo Movimento</label>
                      <select name="tipoMovimento" id="tpMov" className='form-control'></select>
                      <label htmlFor="">Data</label>
                      <input type="date" className='form-control' />
                      
                       <label htmlFor="inputState" className="form-label">Banco</label>
                       <select id="inputBanco" value={seq_banco}   onChange={(e)=> setSeqBanco(e.target.value)} className="form-select" tabIndex="4">
                       { bancos.map((banco)=> {
                        return <option key={banco.SEQ_BANCO} value={banco.SEQ_BANCO}> {banco.DESCRICAO}</option>      
                               
                        } )}                        
                       </select>                    
                    </div>
                  </div>
                  <div className="modal-footer">
                    <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                    <button type="button" className="btn btn-primary">Save changes</button>
                  </div>
                </div>
              </div>
            </div>
            </div>

          
          
          
        </div>     

        

     
        <div className="modal fade" id="moda-filtro" data-bs-backdrop="static" data-bs-keyboard="false" tabIndex="-1" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-dialog-centered">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Filtros</h1>
        <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div className="modal-body">
        <div className="content-modal">
        
          <div className="row mt-3">
            <h5>Período de Emissão</h5>
            <div className="col-md-6">
              <input 
                type="date" 
                value={dataEmissaoInicial}
                onChange={(e) => setDataEmissaoInicial(e.target.value)} 
                className="form-control" 
                placeholder="Data de Emissão Inicial" 
              />
            </div>
            <div className="col-md-6">
              <input 
                type="date"
                value={dataEmissaoFinal} 
                onChange={(e) => setDataEmissaoFinal(e.target.value)} 
                className="form-control" 
                placeholder="Data de Emissão Final" 
              />
            </div>
          </div>

          <div className="row mt-3">
            <h5>Período de Vencimento</h5>
            <div className="col-md-6">
              <input 
                type="date" 
                value={dataVencimentoInicial}
                onChange={(e) => setDataVencimentoInicial(e.target.value)} 
                className="form-control" 
                placeholder="Data de Vencimento Inicial" 
              />
            </div>
            <div className="col-md-6">
              <input 
                type="date"
                value={dataVencimentoFinal} 
                onChange={(e) => setDataVencimentoFinal(e.target.value)} 
                className="form-control" 
                placeholder="Data de Vencimento Final" 
              />
            </div>
          </div>

          <div className="row mt-3">
            <h5>Período de Pagamento</h5>
            <div className="col-md-6">
              <input 
                type="date" 
                value={dataPagamentoInicial}
                onChange={(e) => setDataPagamentoInicial(e.target.value)} 
                className="form-control" 
                placeholder="Data de Pagamento Inicial" 
              />
            </div>
            <div className="col-md-6">
              <input 
                type="date"
                value={dataPagamentoFinal} 
                onChange={(e) => setDataPagamentoFinal(e.target.value)} 
                className="form-control" 
                placeholder="Data de Pagamento Final" 
              />
            </div>
          </div>

        
          <div className="row mt-3">
            <div className="col-md-12">
              <label htmlFor="inputCity" className="form-label">SACADO</label>
              <div className="input-group mb-3">
                <input type="text" value={razao} onChange={(e) => setRazao(e.target.value)} className="form-control" id="inputNcm" tabIndex="7" disabled />
                <button type="button" className="btn btn-secondary" data-bs-toggle="modal" data-bs-target="#staticBackdrop">
                  pesquisar
                </button>
              </div>
            </div>
          </div>

          <div className="row mt-3">
            <h5>Status</h5>
            <div className="col-md-6">
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroTodos" 
                  checked={filtroStatus.todos}
                  onChange={(e) => setFiltroStatus(prev => ({ ...prev, todos: e.target.checked }))}
                />
                <label className="form-check-label" htmlFor="filtroTodos">
                  Todos
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroEmAberto" 
                  checked={filtroStatus.emAberto}
                  onChange={(e) => setFiltroStatus(prev => ({ ...prev, emAberto: e.target.checked }))}
                />
                <label className="form-check-label" htmlFor="filtroEmAberto">
                  Em Aberto
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroParcial" 
                  checked={filtroStatus.parcial}
                  onChange={(e) => setFiltroStatus(prev => ({ ...prev, parcial: e.target.checked }))}
                />
                <label className="form-check-label" htmlFor="filtroParcial">
                  Parcial
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroPagos" 
                  checked={filtroStatus.pagos}
                  onChange={(e) => setFiltroStatus(prev => ({ ...prev, pagos: e.target.checked }))}
                />
                <label className="form-check-label" htmlFor="filtroPagos">
                  Pagos
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroVencidos" 
                  checked={filtroStatus.vencidos}
                  onChange={(e) => setFiltroStatus(prev => ({ ...prev, vencidos: e.target.checked }))}
                />
                <label className="form-check-label" htmlFor="filtroVencidos">
                  Vencidos
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="checkbox" 
                  id="filtroCancelados" 
                  checked={filtroStatus.cancelados}
                  onChange={(e) => setFiltroStatus(prev => ({ ...prev, cancelados: e.target.checked }))}
                />
                <label className="form-check-label" htmlFor="filtroCancelados">
                  Cancelados
                </label>
              </div>
            </div>
          </div>


          <div className="row mt-3">
            <h5>Ordenar Por</h5>
            <div className="col-md-6">
              <div className="form-check">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="orderCriteria" 
                  id="orderSeq" 
                  value="SEQ" 
                  checked={orderCriteria === 'SEQ'}
                  onChange={(e) => setOrderCriteria(e.target.value)}
                />
                <label className="form-check-label" htmlFor="orderSeq">
                  Sequência
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="orderCriteria" 
                  id="orderDataVenc" 
                  value="DATA_VENC" 
                  checked={orderCriteria === 'DATA_VENC'}
                  onChange={(e) => setOrderCriteria(e.target.value)}
                />
                <label className="form-check-label" htmlFor="orderDataVenc">
                  Data de Vencimento
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="orderCriteria" 
                  id="orderDataPagamento" 
                  value="DATA_PAGAMENTO" 
                  checked={orderCriteria === 'DATA_PAGAMENTO'}
                  onChange={(e) => setOrderCriteria(e.target.value)}
                />
                <label className="form-check-label" htmlFor="orderDataPagamento">
                  Data de Pagamento
                </label>
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="orderCriteria" 
                  id="orderValor" 
                  value="VALOR" 
                  checked={orderCriteria === 'VALOR'}
                  onChange={(e) => setOrderCriteria(e.target.value)}
                />
                <label className="form-check-label" htmlFor="orderValor">
                  Valor
                </label>
              </div>
              <div className="form-check mt-2">
                <input 
                  className="form-check-input" 
                  type="radio" 
                  name="orderCriteria" 
                  id="orderRazao" 
                  value="RAZAO" 
                  checked={orderCriteria === 'RAZAO'}
                  onChange={(e) => setOrderCriteria(e.target.value)}
                />
                <label className="form-check-label" htmlFor="orderRazao">
                  Razão (Ordem Alfabética)
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="modal-footer">
        <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Fechar</button>
        <button type="button" onClick={clearFilters} className="btn btn-danger">Limpar Filtros</button>
        <button type="button" onClick={handleFilter} data-bs-dismiss='modal' className="btn btn-primary">Aplicar Filtros e Ordenação</button>
      </div>
    </div>
  </div>
</div>


<div className="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div className="modal-dialog modal-lg">
    <div className="modal-content">
      <div className="modal-header">
        <h1 className="modal-title fs-5" id="staticBackdropLabel">Pesquisa Cadastro</h1>
        <button type="button" className="btn-close" aria-label="Close" data-bs-dismiss='modal'></button>
      </div>
      <div className="modal-body">
        <div>
          <div className="input-group mb-3">
            <input onChange={(e) => setBuscaPessoa(e.target.value)} type="text" className="form-control" placeholder="Digite sua pesquisa" aria-describedby="button-addon2" />
            <button onClick={listaPessoa} className="btn btn-primary" type="button" id="button-addon2">
              <i className="fas fa-search"></i> Pesquisar
            </button>
          </div>

          {resultPessoa === 1 ?
            <table className="table table-hover table-striped">
              <thead>
                <tr>
                  <th scope="col">Codigo</th>
                  <th scope="col">Razão</th>
                </tr>
              </thead>
              <tbody className="table-group-divider">
                {pessoas.map((pessoa) => (
                  <tr key={pessoa.COD_CADASTRO}>
                    <td>{pessoa.CNPJ}</td>
                    <td>{pessoa.RAZAO}</td>
                    <td>
                      <a href="#" onClick={(e) => {
                        e.preventDefault();
                        pesquisaPessoa(("000000" + pessoa.COD_CADASTRO).slice(-6) + ' - ' + pessoa.RAZAO);
                        
                      }}>
                        <i className="fas fa-edit icone-acao red" data-bs-dismiss="modal"></i>
                      </a>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table> :
            <BuscaVazaia />
          }
        </div>
      </div>
      <div className="modal-footer">
      </div>
    </div>
  </div>
</div>





        <hr />

         {sResult === 1?
         <table id='table-contas' className="table table-hover table-striped">
            <thead>
            <tr>
                
                <th scope="col">Seq.</th>
                <th scope="col">Documento</th>
                <th scope="col">Razão</th>
                <th scope="col">tipo</th>
                <th scope='col'>Emissão</th>
                <th scope="col">Vencimento</th>
                <th scope="col">Valor Conta</th>
                <th scope="col">Juros</th>
                <th scope="col">Multa</th>
                <th scope="col">Valor pago</th>
                <th scope="col">Total Pagar</th>
                <th scope="col">Ações</th>
                
                </tr>
            </thead>
            <tbody className="table-group-divider">
            
             
            { contas.map((conta)=> {
                return <tr key={conta.SEQ}>
                    <td>{conta.SEQ}</td>                    
                    <td>{conta.DOCUMENTO}</td>                                   
                    <td>{conta.RAZAO}</td>                     
                    <td>{conta.TIPO_CONTA}</td>
                    <td>{conta.DATA_EMISSAO}</td>                     
                    <td>{conta.DATA_VENC}</td>
                    <td>{Number(conta.VALOR).toFixed(2)}</td>  
                    <td>{Number(0).toFixed(2)}</td> 
                    <td>{Number(0).toFixed(2)}</td> 
                    <td>{Number(conta.VALOR_PAGO).toFixed(2)}</td>
                    <td>{Number(conta.TOTAL_PAGAR).toFixed(2)}</td> 
                    
                    <td>
                    <div id="table-prod" className="dropdown">
                    <button id='table-prod' className="btn btn-outline-secondary dropdown-toggle" type="button" data-bs-toggle="dropdown" aria-expanded="false">
                       Opções
                    </button>
                      <ul className='dropdown-menu'>
                        <li>
                        <Link to={'/app/novaConta/'+tipoConta+'/'+conta.SEQ}><i className="bi bi-eye-fill green"></i>Vizualizar</Link>  
                        </li>
                        <li>
                        <Link to={'/app/novaConta/'+tipoConta+'/'+conta.SEQ}><i className="bi bi-pen-fill"></i>Editar</Link>
                        </li>
                      </ul>
                  </div>
                    
                    </td>


                </tr>
            } )}
          </tbody>
        </table>

        :<BuscaVazaia/> }        

{
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErroValidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
     
 
        

      </div>      
    </div>
}


export default Contas;