import React,{useState} from "react";
import Navbar from "../../navbar/navbar";
import { api } from "../../../services/api";
import { Link,Navigate, useParams } from 'react-router-dom'
import { useEffect } from "react";
import './banco.css'
import Banco from "./banco";
import { vhostV1 } from "../../funcoes";
import SweetAlert from "react-bootstrap-sweetalert";





function NovoBanco(){
 

  const {tag} =useParams();
  const {id} =useParams();

  

   const [erroValidacao, setErrovalidacao] = useState(false); 
   const [msgErro,setmsgErro] = useState('');
   const [banco,setBanco] = useState(0);
   const [descricao,setDescricao] = useState('');
   const [cedente_caixaEcon,setcedente_caixaEcon] = useState(0);
   const [conta_corrente,setConta_Corrente] = useState('0');
   const [cadastrado_por,setCadastrado_Por] = useState(99);
   const [alterado_por,setAlterado_Por] = useState(99)
   const [conc,setConc] = useState('S');
   const [num_carteira,setNum_carteira] = useState('0');
   const [desc_carteira,setDesc_Carteira] = useState('');
   const [num_conta,setNumConta] = useState(0);
   const [digito_conta,setDigito_Conta] = useState(0);
   const [num_agencia,setNum_Agencia] = useState(0);
   const [digito_agencia,setDigito_Agencia] = useState(0);
   const [tpJuros,setJuros] = useState(0);
   const [tpMulta,setMulta] = useState(0);
   const [inscob01,setINSCOB01] = useState('');
   const [insccob02,setINSCOB02] = useState('');
   const [multaCod,setMULTACOB] = useState(0);
   const [jurosCod,setJUROSCOB] = useState(0);
   const [localPagamento,setLOCALPAGAMENTO] = useState('');
   const [instrucao_cobranca,setINSTRUCAO_COBRANCA] = useState('');
   const [cedente_dv,setCEDENTE_DV] = useState('');
   const [contaReduzida,setCONTAREDUZIDA] = useState('');
   const [usaBoleto,setUsaBoleto] = useState(false);

   const [sucesso,setSucesso] = useState('N')
   const [mensagem, setMensagem] = useState('');
   const [load, setLoad] = useState(false);
   const [sResult, setSresult] = useState(0);
 
   var data = new Date();

   const dataAtual = data.toSRIFormatString();
  
   console.log('data formatada:', dataAtual); //2022

   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  

  useEffect(function(){   
      
    api.post(vhostV1('webcash/banco/'+id), {
        loja: localStorage.getItem('loja'),
        empresa: 0        
      },config)
      .then(function (response) {
    
         //buscaGrupos();
        if (response && response.data && response.data.dados){
            console.log(response.data.dados)
             setDescricao(response.data.dados.DESCRICAO);  
        setConta_Corrente(response.data.dados.CONTA_CORRENTE); 
         setcedente_caixaEcon(response.data.dados.CEDENTE_CAIXAECON); 
         //setdata(DATA_ULTIMO);
         //CADASTRADO_POR
         //CADASTRADO_EM
         //ALTERADO_POR
         //ALTERADO_EM
         setConc(response.data.dados.CONC);
         setNum_carteira(response.data.dados.NUM_CARTEIRA);
         setDesc_Carteira(response.data.dados.DESC_CARTEIRA);
         setNumConta(response.data.dados.NUM_CONTA);  
         setDigito_Conta(response.data.dados.DIGITO_CONTA); 
         setNum_Agencia(response.data.dados.NUM_AGENCIA);  
         setDigito_Agencia(response.data.dados.DIGITO_AGENCIA); 
         setJuros(response.data.dados.TPJUROS);
         setMulta(response.data.dados.TPMULTA);

         setINSCOB01(response.data.dados.INSCOB01);
         setINSCOB02(response.data.dados.INSCOB02);
         setMULTACOB(response.data.dados.MULTACOB);
         setLOCALPAGAMENTO(response.data.dados.LOCALPAGAMENTO);
         setINSTRUCAO_COBRANCA(response.data.dados.INSTRUCAO_COBRANCA);

         setCEDENTE_DV(response.data.dados.CEDENTE_DV);
         setCONTAREDUZIDA(response.data.dados.CONTAREDUZIDA);
    
   
        }           
        
                
      });

      
  },[])

  
   
   //buscar codigo banco

    function SalvaBanco(){

       // validar tamanhos 
      {if(descricao.length >30 )
        { setmsgErro("Descrição do banco maior que permitido");
         setErrovalidacao(true)}
       else if(cedente_caixaEcon.length >10)
       { setmsgErro("Cedente maior que permitido");
       setErrovalidacao(true)}
       else if (conta_corrente.length >10) 
       {setmsgErro("Conta corrente maior que permitido");
       setErrovalidacao(true)}
       else if (num_carteira.length >6)
       {setmsgErro("Numero da carteira maior que permitido");
       setErrovalidacao(true)}
       else if (num_agencia.length >4)
       {setmsgErro("Número da agência maior que permitido");
       setErrovalidacao(true)}
       else if(desc_carteira.length >28)
       {setmsgErro("Descrição da carteira maior que permitido");
       setErrovalidacao(true)}
       else if(num_conta.length >11)
       {setmsgErro("Numero da conta maior que permitido");
       setErrovalidacao(true) }
       else if(localPagamento.length >200)
       {setmsgErro("Local do Pagamento maior que permitido");
       setErrovalidacao(true)}
       
        else {      
      
       // fim do valida tamanhos

           //   e.preventDefault(e);
        api.post(vhostV1('webcash/banco/incluir'),{
        LOJA:0,
        EMPRESA:0,
        BANCO:banco,
        SEQ_BANCO:id,
        DESCRICAO:descricao,
        CEDENTE_CAIXAECON:cedente_caixaEcon,
        CONTA_CORRENTE:conta_corrente,
        DATA_ULTIMO:dataAtual,
        CADASTRADO_POR:cadastrado_por,
        CADASTRADO_EM:dataAtual,
        ALTERADO_POR:alterado_por,
        ALTERADO_EM:dataAtual,
        CONC:conc,
        NUM_CARTEIRA:num_carteira,
        DESC_CARTEIRA:desc_carteira,
        NUM_CONTA:num_conta,
        DIGITO_CONTA:digito_conta,
        NUM_AGENCIA:num_agencia,
        DIGITO_AGENCIA:digito_agencia,
        TPJUROS:tpJuros,
        TPMULTA:tpMulta,
        INSCOB01:inscob01,
        INSCOB02:insccob02,
        MULTACOB:multaCod,
        JUROSCOB:jurosCod,
        LOCALPAGAMENTO:localPagamento,
        INSTRUCAO_COBRANCA:instrucao_cobranca,
        CEDENTE_DV:cedente_dv,
        CONTAREDUZIDA:contaReduzida
        },config)
// >>>>>>> 30bf9c76b65afd409b3efacfda83d696d35387d5
        .then(function(response){
            if(response && response.data && response.data.dados ){
                setMensagem('');
                setSucesso('S');
                }else{
                 setmsgErro(response)
                }
            }).catch((erro) =>{
                setMensagem(erro);
                setSucesso('N');
                console.log('ERRO');
            });
        }}
        };

    //busca

  



     return <div className="conteiner-fluid titulo ">
        {/* {load ? <Loading />
         : <> */}
         <Navbar/>
         <div className="offset-lg-3 col-lg-6">
               <h2>Cadastro Banco</h2>      
                <hr />
                <div className="conteiner-fluid">
                    <form className="row tela">
                    
                    
                    
                    
                    <div className="col-md-6">
                        <label htmlFor="inputState" className="form-label">Banco</label>
                        <select id="inputState" onChange={(e)=> setBanco(parseInt(e.target.value.substring(0,3)))} className="form-select">
                            <option>000 - Outros</option>
                            <option>001 - Banco Brasil</option>                                
                            <option>004 - BANCO NORDESTE DO BRASIL SA</option>        
                            <option>008 - BANCO SANTANDER BANESPA</option>            
                            <option>029 - BANCO BANERJ SA </option>   
                            <option>033 - BANCO ESTADO SAO PAULO BANESPA</option>    
                            <option>038 - BANCO BANESTADO </option>   
                            <option>047 - BANCO ESTADO SERGIPE SA</option>       
                            <option>073 - BANCO POPULAR DO BRASIL </option>           
                            <option>104 - CAIXA ECONOMICA FEDERAL </option>           
                            <option>151 - BANCO NOSSACAIXA </option>                  
                            <option>175 - BANCO FINASA </option>                      
                            <option>215 - BANCO COMERCIAL SUDAMERIS SA</option>       
                            <option>237 - BANCO BRADESCO SA</option>                  
                            <option>252 - BANCO FINIVEST </option>                    
                            <option>263 - BANCO CACIQUE </option>                     
                            <option>265 - BANCO FATOR </option>                       
                            <option>318 - BANCO BMG SA </option>                      
                            <option>341 - BANCO ITAU </option>                        
                            <option>351 - BANCO SANTANDER</option>                    
                            <option>353 - BANCO SANTANDER BRASIL</option>             
                            <option>356 - BANCO ABN AMRO REAL SA </option>            
                            <option>394 - BANCO BMC SA</option>                       
                            <option>399 - HSBC BANK BRASIL</option>                   
                            <option>409 - UNIBANCO - UNIAO DE BANCOS BRA</option>     
                            <option>422 - BANCO SAFRA</option>                        
                            <option>453 - BANCO RURAL </option>                       
                            <option>477 - CITIBANK SA </option>                       
                            <option>623 - BANCO PANAMERICANO</option>                 
                            <option>744 - BANKBOSTON </option>                        
                            <option>745 - BANCO CITIBANK</option>      

                        </select>
                    </div>

                    <div className="col-6">
                        <label htmlFor="inputAddress" className="form-label">Descrição</label>
                        <input type="text" onChange={(e)=> setDescricao(e.target.value)} value={descricao} className="form-control" id="inputAddress" />
                    </div>
                    
                    <div className="col-4">
                            <label htmlFor="inputAddress" className="form-label">Nº Agência</label>
                            <input type="text" onChange={(e)=> setNum_Agencia(e.target.value)} value={num_agencia} className="form-control" id="inputAddress" />
                        </div>

                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">Digito</label>
                            <input type="text" onChange={(e)=> setDigito_Agencia(e.target.value)} value={digito_agencia}className="form-control" id="inputAddress" />
                        </div>

                        <div className="col-4">
                            <label htmlFor="inputAddress" className="form-label">Nº Conta</label>
                            <input type="text" onChange={(e)=> setNumConta(e.target.value)} value={num_conta} className="form-control" id="inputAddress" />
                        </div>

                        <div className="col-2">
                            <label htmlFor="inputAddress" className="form-label">Digito</label>
                            <input type="text" onChange={(e)=> setDigito_Conta(e.target.value)} value={digito_conta}className="form-control" id="inputAddress" />
                        </div>

                        <div className="col-4">
                        <label htmlFor="inputAddress" className="form-label">Conta Reduzida(Contabilidade)</label>
                        <input type="text" onChange={(e)=> setConta_Corrente(e.target.value)} value={conta_corrente} className="form-control" id="inputAddress" />
                    </div>

                    <div className="col-3">
                        <label htmlFor="inputAddress" className="form-label">Data Último Movimento</label>
                        <input type="text" onChange={(e)=> ''} className="form-control" id="inputAddress" />
                    </div>
                    <h1></h1>
                    <h6>dados complementares</h6>
                    <hr />
                        <div className="form-check">
                        <input className="form-check-input" type="checkbox" value={usaBoleto}  onChange={ (e)=> usaBoleto === false?setUsaBoleto(true):setUsaBoleto(false)} id="flexCheckDefault"/>
                            <label className="form-check-label" for="flexCheckDefault">
                            Usa Boleto
                            </label>
                        </div>
                        
                        
                    
                       
                        {usaBoleto === true?
                          <div className="boleto">
                                <div className="col-4">
                                    <label htmlFor="inputAddress" className="form-label">Número Carteira</label>
                                    <input type="text" onChange={(e)=> setNum_carteira(e.target.value)} value={num_carteira} className="form-control" id="inputAddress" />
                                </div>


                                <div className="col-4">
                                        <label htmlFor="inputAddress" className="form-label">Cod. Cedente</label>
                                        <input type="text" onChange={(e)=> setcedente_caixaEcon(e.target.value)} value={cedente_caixaEcon} className="form-control" id="inputAddress" />
                                </div>

                                <div className="col-2">
                                    <label htmlFor="inputAddress" className="form-label">DV</label>
                                    <input type="text" onChange={(e)=> setCEDENTE_DV(e.target.value)} value={cedente_dv} className="form-control" id="inputAddress" />
                                </div>
                            

                                <div className="col-10">
                                    <label htmlFor="inputAddress" className="form-label">Local Pagamento</label>
                                    <input type="text" onChange={(e)=> setLOCALPAGAMENTO(e.target.value)} value={localPagamento} className="form-control" id="inputAddress" />
                                </div>

                                <div className="col-10">
                                    <label htmlFor="inputAddress" className="form-label">Msg. 01</label>
                                    <input type="text" onChange={(e)=> setINSCOB01(e.target.value)} value={inscob01} className="form-control" id="inputAddress" />
                                </div>

                                <div className="col-10">
                                    <label htmlFor="inputAddress" className="form-label">Msg. 02</label>
                                    <input type="text" onChange={(e)=> setINSCOB02(e.target.value)} value={insccob02} className="form-control" id="inputAddress" />
                                </div>





                        
                                <div className="col-6">

                                    <div className="col-3">
                                        <label htmlFor="inputAddress" className="form-label">Juros</label>                       
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                        <label className="form-check-label" for="inlineRadio1">Nenhum</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                        <label className="form-check-label" for="inlineRadio2">Valor</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3"/>
                                        <label className="form-check-label" for="inlineRadio3">Percentual</label>
                                    </div> 


                                    <div className="col-3">
                                        <input type="text" onChange={(e)=> setJuros(e.target.value)} value={tpJuros} className="form-control" id="inputAddress" />
                                    </div>

                                    

                                </div>    
                                <div className="col-6">

                                    <div className="col-3">
                                        <label htmlFor="inputAddress" className="form-label">Multa</label>                       
                                    </div>

                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio1" value="option1"/>
                                        <label className="form-check-label" for="inlineRadio1">Nenhum</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio2" value="option2"/>
                                        <label className="form-check-label" for="inlineRadio2">Valor</label>
                                    </div>
                                    <div className="form-check form-check-inline">
                                        <input className="form-check-input" type="radio" name="inlineRadioOptions" id="inlineRadio3" value="option3"/>
                                        <label className="form-check-label" for="inlineRadio3">Percentual</label>
                                    </div> 

                                    <div className="col-3">
                                        <input type="text" onChange={(e)=> setMulta(e.target.value)} value={tpMulta} className="form-control" id="inputAddress" />
                                    </div>
                                </div>  
                            
                            <div className="col-12 espaco"></div>

                            <div className="col-md-10">
                            <label htmlFor="inputState" className="form-label">Instruções de Cobrança</label>
                            <select id="inputState" onChange={(e)=> setINSTRUCAO_COBRANCA(e.target.value)} value={instrucao_cobranca} className="form-select">
                                <option>00 - selecione a instrução que vai aparecer no boleto</option>
                                <option>19 - não receber após 05 dias do vencimento</option>
                                <option>20 - não receber após 10 dias do vencimento</option>
                                <option>21 - não receber após 15 dias do vencimento</option>
                                <option>22 - não receber após 20 dias do vencimento</option>
                                <option>23 - não receber após 25 dias do vencimento</option>
                                <option>24 - não receber após 30 dias do vencimento</option>
                                <option>25 - não receber após 35 dias do vencimento</option>
                                <option>26 - não receber após 40 dias do vencimento</option>
                                <option>27 - não receber após 45 dias do vencimento</option>
                                <option>28 - não receber após 50 dias do vencimento</option>
                                <option>29 - não receber após 55 dias do vencimento</option>                                                       
                            </select>
                        </div>






                            </div>                     
                        :null
                       }    
                        

                        

                        <div className="col-12">
                            <Link to="/app/banco" type="submit" className="btn btn-outline-primary butao">Desistir</Link>
                          {tag != '0'? ( 
                          <button type="button" onClick={SalvaBanco} className="btn btn-primary butao">Salvar</button>):null}
                        </div>                   
                        
                        {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
                        {sucesso === 'S' ? <Navigate to='/app/banco' /> : null}
        
                    </form> 

                    <hr />
                </div>

                {
           erroValidacao ? 
           <SweetAlert
              danger
              confirmBtnText="OK"
              confirmBtnBsStyle="danger"
              title="Erro"
              onConfirm={() => setErrovalidacao(false)}                            
            >
              {msgErro}
            </SweetAlert>:null
           
          }
        </div>  
   </div>

}



export default NovoBanco;