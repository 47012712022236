

function Footer(){
   return<>  

    <footer className="text-center fixed-bottom text-lg-start bg-light text-muted">
      <div className="text-center p-4">
        
      </div>

    </footer>

    </>


}



export default Footer;