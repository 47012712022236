import React, { useState, useEffect } from 'react';
import { Link,Navigate,useParams } from 'react-router-dom';
import Navbar from '../navbar/navbar';
import './pedidos.css'
import { api } from '../../services/api';
import { vhostV1 } from '../../../src/app/funcoes';
import SweetAlert from 'react-bootstrap-sweetalert';
import visualizaPdf from '../reports/pedidos/viewPdf';



function ItensPedido(prpos) {
    const {cod} = useParams();

     const [itens,setItens] = useState([]);

  let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

  const [total_pedido,setTotal_pedido] = useState(0);
  const [confirmacao,setConfirmacao] = useState(false);
  const [confirmacaoId,setConfirmacaoID] = useState('');
  const [dadosVenda,setDadosVenda] = useState({});


  function confirmadeleteUser(id){
    setConfirmacaoID(id);
    setConfirmacao(true);
    //setBusca('');
 }

 const handleClick = () => {
  visualizaPdf({
      dadosVenda,
      cod,
      itens,
      total_pedido,
  });
};
  

 

    useEffect(function(){
    
      api.get(vhostV1('webcash/vendas/retornaVenda/'+cod),config)
       .then(function (response) {
        console.log(response);
         setItens(response.data.dados.ITENS); 
         setTotal_pedido(response.data.dados.VALOR)  
         setDadosVenda(response.data.dados);
            
       })
       .catch(function (error) {
        
         console.log(error);

                 
       });
  
        
    },[])

    
    


    function deleteItem(id){
      api.post(vhostV1('webcash/vendas/cancelaItem'), {
        loja: localStorage.getItem('loja'),
        empresa: 0,
        pedido:cod,
        item:id        
      },config)
      .then(function (response) {
        setConfirmacao(false);
        window.location.reload();   
        
        
                
      })
      .catch(function (error) {
        console.log(error);
        setConfirmacao(false);
        
      });
  
  }
   

    return <div>
        <Navbar/>
        <div className="container-fluid titulo offset-lg-2 col-lg-8">
           <div className="row dados-venda">
           <h3>Dados do Pedido</h3> 
              <hr />
              <div>
                <strong>N Pedido:</strong> {cod}
              </div> 
              <div>
                <strong>Cliente: </strong> {dadosVenda.RAZAO}
              </div>
              <div>
                <strong>Forma de Pagamento:</strong> {dadosVenda.FORMA_PAGAMENTO}
              </div>
              <hr />
              <div className="row">
                <div className="col-9">
                <strong>ENDEREÇO</strong>
                  <p className="form-label">{dadosVenda.ENDERECO} N {dadosVenda.NREND1}</p>
                </div>

                <div className="col-3">
                  <strong>TELEFONE</strong>
                  <p className="form-label">{dadosVenda.TELEFONE}</p>
                </div>
                 <hr />
                <div className="col-3">
                  <strong>BAIRRO</strong>
                  <p className="form-label">{dadosVenda.BAIRRO}</p>
                </div>

                <div className="col-4">
                  <strong>CIDADE</strong>
                  <p className="form-label">{dadosVenda.CIDADE}</p>
                </div>

                <div className="col-4">
                  <strong>CEP</strong>
                  <p className="form-label">{dadosVenda.CEP}</p>
                </div>

                <div className="col-1">
                  <strong>UF</strong>
                  <p className="form-label">{dadosVenda.ESTADO}</p>
                </div>
              </div>
              <div className='mt-3' style={{textAlign:'right'}}>
                <h3>Total Pedido: {new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(total_pedido)} </h3>                
              </div>
              

           </div>
        
        <hr />

        <div className="row">
          <div className="col-8">
            
          </div>
        </div>   

         <table className="table table-hover table-striped">
            <thead>
              <tr>
                <th scope="col">CÓDIGO</th>
                <th scope="col">DESCIÇÃO</th>
                <th scope="col">ITEM</th>
                <th scope="col">QTD</th>
                <th scope="col">VALOR</th>
                <th scope="col">DESCONTO</th>
                <th scope="col">TOTAL</th>
                {/*<th scope="col">Ação</th>*/}

                
              </tr>
            </thead>
            <tbody className="table-group-divider">
            { itens.map((item,i)=> {
                return <tr key={item.i}>
                    <td>{item.COD_PRODUTO}</td>          
                    <td>{item.DESCRICAO}</td>
                    <td> {item.ITEM}</td>
                    <td>{Number(item.QTD).toFixed(3)}</td>
                    <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.VENDA)}</td>
                    <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.DESCONTO)}</td>
                    <td>{new Intl.NumberFormat('pt-BR', {style: 'currency', currency: 'BRL'}).format(item.TOTAL)}</td>
                   {/* <td><Link to ="#" onClick={() => confirmadeleteUser(item.ITEM)}><i className="bi bi-trash-fill red"></i></Link></td>*/}
                    
                </tr>
            } )}
            
  
          </tbody> 

         </table>
            
        
         <Link to={"/app/listapedidos"} type="button" className="btn btn-outline-primary butao"  ><i className="bi bi-backspace-fill"></i>Voltar</Link>
         <button type="button" onClick={handleClick} className="btn btn-danger btn-cli butao" data-bs-dismiss="modal"><i className='bi bi-printer-fill'></i>Imprimir</button>
        
         {
           confirmacao ? 
           <SweetAlert
              warning
              showCancel
              showCloseButton
              confirmBtnText="Sim"
              confirmBtnBsStyle="danger"
              cancelBtnText="Não"
              cancelBtnBsStyle='ligth'
              title="Atenção"
              onConfirm={() =>deleteItem(confirmacaoId)}
              onCancel={() => setConfirmacao(false)}
              focusCancelBtn
              reverseButtons
            >
              Deseja excluir este Item!
            </SweetAlert>:null
           
          }



      </div>      
    </div>
}


export default ItensPedido;