import React,{useState} from "react";
import Navbar from "../../navbar/navbar";
import { api } from "../../../services/api";
import { Link,Navigate,useParams,redirect} from 'react-router-dom'

import { useEffect } from "react";
import './contas.css'
import { vhostV1 } from "../../funcoes";

function EditaConta(props){

    const {cod} = useParams();
    const [sucesso,setSucesso] = useState('N')
    const [mensagem, setMensagem] = useState('');
   
   
   let config = {
    headers: {
      'Authorization': localStorage.getItem("token")
    }
  }

    return <div className="conteiner-fluid titulo ">
    <Navbar/>
    <div className="offset-lg-1 col-lg-10">
          <h2>Contas</h2>      
           
           <div className="conteiner-fluid">
           <form className="row tela">
              
              <div className="col-2">
                  <label htmlFor="inputAddress" className="form-label">CODIGO</label>
                  <input type="text"  className="form-control" id="inputAddress" disabled />
              </div>
              
              
              <div className="col-12">
                  <Link to="/app/produto" type="submit" className="btn btn-outline-primary butao">Desistir</Link>
                  <button type="submit" onClick={''} className="btn btn-primary butao">Salvar</button>
                  
              </div>
  
              {mensagem.length > 0 ? <div className="alert alert-danger mt-2" role="alert">{mensagem}</div> : null}
              {sucesso === 'S' ? document.location ='/app/produto' : null}
              
  
              </form> 
       </div>
   </div>  
</div>

}

export default EditaConta;