import { Navigate } from "react-router-dom";

function PrivateRoute(props){
    const logado = localStorage.getItem('token') ? true : false;
    
    return logado ? props.children : <Navigate to="/"/>

}


export default PrivateRoute;